@use "scss/variables";
@use "scss/bootstrap";
@use "scss/functions";

.button {
  line-height: 1 !important;
  border: 0;

  &:global {
    &.btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 48px;

      // icon left or right  with text
      :local {
        .icon {
          display: flex;
          font-size: functions.get-rem(20px);
        }
      }
      &.btn-sm {
        height: 32px;
        :local {
          .icon {
            font-size: functions.get-rem(16px);
          }
        }
      }

      &:local {
        &.icon-left {
          padding-left: functions.get-rem(12px);
        }

        &.icon-right {
          padding-right: functions.get-rem(12px);
        }
      }
    }
  }

  &.without-borders {
    border: none;
  }

  &:global {
    &.btn {
      border: 0 !important;

      // Primary button
      &.btn-primary {
        &:hover {
          background-color: variables.$additional-8-500;
        }

        &:active {
          background-color: variables.$additional-8-600;
        }
      }

      // Secondary button
      &.btn-secondary {
        color: variables.$foreground-on-secondary !important;

        &:hover {
          background-color: variables.$additional-7-200 !important;
        }

        &:active {
          background-color: variables.$additional-7-500 !important;
        }
      }

      // Tertiary button
      &.btn-light {
        color: variables.$primary-1;

        &:hover {
          background-color: variables.$secondary-1;
        }

        &:active {
          color: variables.$primary-1 !important;
          background-color: variables.$additional-7-200 !important;
        }
      }

      &.btn-success {
        color: variables.$foreground-on-primary;
      }

      &.btn-information {
        color: variables.$information-900;
        background-color: transparent;

        &:hover {
          background-color: variables.$information-300;
        }

        &:active,
        &:focus {
          background-color: variables.$information-500;
        }
      }

      &:hover {
        outline: 0 !important;
      }

      &:focus {
        outline-offset: 2px;
        outline: 2px solid variables.$information-700;
      }

      &:disabled {
        color: variables.$foreground-disabled !important;
      }

      &.btn-primaryInformation {
        color: variables.$foreground-on-primary;
        background: variables.$information-700;
        border-color: variables.$information-700;

        &:hover {
          background-color: variables.$information-900;
        }

        &:active,
        &:focus {
          color: variables.$foreground-on-primary;
          background-color: variables.$information-900;
        }
      }

      &.btn-primaryWarning {
        color: variables.$foreground-on-primary;
        background: variables.$warning-900;
        border-color: variables.$warning-900;

        &:hover {
          background-color: variables.$warning-500;
        }

        &:active,
        &:focus {
          color: variables.$foreground-on-primary;
          background-color: variables.$warning-500;
        }
      }
    }
  }

  &.round {
    font-size: 24px;
    padding: 0;
    width: 48px;

    &:global {
      &.btn-sm {
        width: 32px;
        font-size: 24px;
      }
    }

    &.without-borders {
      $padding-x: calc(#{bootstrap.$btn-padding-y} + #{bootstrap.$btn-border-width});

      padding-left: $padding-x;
      padding-right: $padding-x;

      &:global(.btn-sm),
      :global(.btn-group-sm) > &.btn {
        padding-left: $padding-x;
        padding-right: $padding-x;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  &.no-focus {
    &:focus,
    &:active {
      outline: none !important;
    }
  }
}

.tooltip {
  :global {
    .tooltip-inner {
      width: functions.get-rem(230px) !important;
      max-width: functions.get-rem(230px) !important;
    }
  }
}
