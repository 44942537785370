@use "scss/variables";
@use "scss/functions";

.container {
  position: relative;
  border-radius: 4px;
  background-color: variables.$surface-1;
  padding: functions.get-rem(24px) functions.get-rem(32px);

  .label {
    top: 50%;
    left: 6px;
    position: absolute;
    transform: rotate(-90deg);
  }
}

.tooltip {
  display: flex;
  border-radius: 4px;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  height: functions.get-rem(58px);
  width: functions.get-rem(110px);
  transform: translate(-50%, -170%);
  background-color: variables.$surface-1;
  padding: 0 16px;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);

  p {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .triangle {
    bottom: -22%;
    left: 40%;
    position: absolute;
  }
}

.legend {
  display: flex;

  div {
    display: flex;
    align-items: center;

    p {
      color: variables.$foreground-medium;
    }

    span {
      height: functions.get-rem(4px);
      width: functions.get-rem(20px);
    }
  }

  div:nth-child(1) {
    span {
      background-color: variables.$positive-050;
    }
  }

  div:nth-child(2) {
    span {
      background-color: variables.$positive-500;
    }
  }

  div:nth-child(3) {
    span {
      border-top: 4px dashed variables.$primary-1;
    }
  }

  &.is-losted {
    div:nth-child(3) {
      span {
        border-top: 4px dashed variables.$critical-700;
      }
    }
  }
}

.empty-image-container {
  width: 100%;
  position: relative;
  height: functions.get-rem(140px);

  img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
