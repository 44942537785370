@use "scss/variables";
@use "scss/bootstrap";

.field {
  flex-direction: column;
  color: variables.$foreground-high;

  &.disabled {
    color: bootstrap.$input-disabled-color;
    .input {
      &::placeholder {
        color: variables.$foreground-disabled!important;
      }
    }
    .icon {
      color: bootstrap.$input-disabled-color;
    }
  }
  .icon {
    color: variables.$foreground-low
  }
}

.input {
  color: variables.$foreground-high;

  &:disabled {
    cursor: not-allowed;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    border-color: variables.$stroke-high !important;
  }

  &:global {
    &.is-invalid {
      border-width: 2px;

      &:disabled {
        border: 1px solid variables.$stroke-high !important;
      }
    }
  }
}

.with-left-icon {
  padding-left: 44px !important;
}

.with-right-icon {
  padding-right: 44px !important;
}

.icon {
  color: currentColor;
  z-index: 4;
  pointer-events: none;
  // for some reason bootstrap input get z-index: 2, when error state is active.
  // and z-index: 3 when we focus on input with error state

  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    font-size: 24px;
  }
  &__clickable {
    cursor: pointer;
    pointer-events: visible;
  }
}

.icon-left {
  left: 12px;
}

.icon-right {
  right: 12px;
  cursor: pointer;
  //font-size: 1.5rem;
}

.info-container {
  top: 100%;
  width: 100%;
  position: absolute;
}

.inputLabel {
  white-space: nowrap;
}
