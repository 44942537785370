@use "scss/variables";
@use "scss/functions";

.tabs-head {
  display: flex;
  position: relative;
  align-items: center;
  height: functions.get-rem(56px);
  background-color: variables.$surface-1;
  border-bottom: 1px solid variables.$stroke-medium;
  transition: all 1s;
}

.tab-item {
  height: 100%;
  display: flex;
  cursor: pointer;
  padding: 0 32px;
  align-items: center;
  color: variables.$foreground-low;
  position: relative;

  &::after {
    transition: all 0.4s;

    content: "";
    height: 2px;
    background-color: variables.$primary-1;
    position: absolute;
    bottom: 0;
    width: 0;
    right: 0;
  }

  &.active {
    color: variables.$primary-1;
    &::after {
      width: 100%;
      left: 0;
    }
  }
}
