@use "scss/variables";
@use "scss/functions";

.single-section {
  flex: 1;
  display: flex; 
  flex-direction: column;
}

.divider {
  border-right: 1px solid variables.$surface-1;
}

.info {
  position: absolute;
  right: functions.get-rem(24px);
  bottom: functions.get-rem(24px);
}
