@use "scss/variables";

.wrap {
  padding: 24px;
  background-color: variables.$element-2;
  border-radius: 8px;


  .formula {
    border: 1px solid variables.$stroke-medium;
    border-radius: 8px;
    padding: 16px;
  }
}