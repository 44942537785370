@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.container {
  padding: 0 functions.get-rem(100px);

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.title {
  @include media-breakpoint-down(md) {
    margin-top: functions.get-rem(24px);
    margin-left: functions.get-rem(24px);
  }
}

.add-company-btn {
  width: 32%;
  display: flex;
  font-weight: 500;
  border-radius: 8px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: variables.$primary-1;
  height: functions.get-rem(75px);
  border-color: variables.$surface-1;
  background-color: rgba(variables.$surface-1, 0.25);
}

.slider-container {
  width: 66%;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}
