.toggle {
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  padding-top: 4px;
  padding-left: 8px;
  border-radius: 50%;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
