@use "sass:math";

@use "../../../../scss/variables";
@use "../../../../scss/functions";

@use "../../../../scss/bootstrap";

@value progressBarDasharray: 50;

.progress-circle {
  fill: none;
}

.placeholder {
  stroke: variables.$element-1;
}

.progress-bar {
  stroke: variables.$positive-500;
  stroke-dasharray: progressBarDasharray;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dashoffset .15s ease-out;

  &.loading {
    stroke-dashoffset: calc(#{progressBarDasharray} / 4 * 3);
    animation: rotate .75s linear infinite;

    @keyframes rotate {
      from {
        transform: rotate(-90deg);
      }
      to {
        transform: rotate(270deg);
      }
    }
  }
}
