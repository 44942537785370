@use "scss/variables";
@use "scss/functions";

.container {
  border-radius: 4px;

  svg {
    flex-shrink: 0;
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
  }

  &.information {
    background-color: variables.$information-050;
    color: variables.$information-900;

    .content {
      color: variables.$foreground-high;

      :last-child {
        color: variables.$information-900;
      }
    }
  }

  &.warning {
    background-color: variables.$warning-050;
    color: variables.$warning-900;

    .content {
      color: variables.$foreground-high;

      :last-child {
        color: variables.$warning-900;
      }
    }
  }

  &.error {
    background-color: variables.$critical-050;
    color: variables.$foreground-high;

    svg {
      color: variables.$critical-900;
    }
  }

  &.expired {
    background-color: variables.$element-3;
    color: variables.$foreground-high;

    svg {
      color: variables.$foreground-low;
    }
  }

  &.success {
    background-color: variables.$positive-050;
  }
}
