@use "sass:math";

// Fonts

$font-family-base: "Inter", sans-serif;
$font-family-secondary: "Roboto", sans-serif;
$font-family-headlines: "Canela Text";

$font-family: (
  base: $font-family-base,
  secondary: $font-family-secondary,
  headlines: $font-family-headlines,
);

$font-size-root: 16px;

//svg
$stroke-width: (
  "0.5": 0.5,
  "0.6": 0.6,
  "0.7": 0.7,
  "0.8": 0.8,
  "0.9": 0.9,
  1: 1,
  "1.5": 1.5,
  2: 2,
  "2.5": 2.5,
  3: 3,
  "3.5": 3.5,
  4: 4,
  "4.5": 4.5,
  5: 5,
);

// default for most UI
$line-height-base: 1.3;

$line-height-xl: 64px;
$line-height-l: 56px;
$line-height-m: 48px;
$line-height-s: 40px;
$line-height-xs: 32px;
$line-height-xxs: 32px;
$line-height-xxxs: 24px;
$line-height: (
        xl: $line-height-xl,
        l: $line-height-l,
        m: $line-height-m,
        s: $line-height-s,
        xs: $line-height-xs,
        xxs: $line-height-xxs,
        xxxs: $line-height-xxxs,
);

// Used in paragraphs that are heavily loaded with many lines of text
$line-height-lg: 1.6;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Typography

$head-xl: 48px;
$head-l: 42px;
$head-m: 36px;
$head-s: 32px;
$head-xs: 28px;
$head-xxs: 24px;
$head-xxxs: 20px;
$head: (
  xl: $head-xl,
  l: $head-l,
  m: $head-m,
  s: $head-s,
  xs: $head-xs,
  xxs: $head-xxs,
  xxxs: $head-xxxs,
);

$paragraph-xxl: 24px;
$paragraph-xl: 20px;
$paragraph-l: 18px;
$paragraph-m: 16px;
$paragraph-s: 14px;
$paragraph: (
  xxl: $paragraph-xxl,
  xl: $paragraph-xl,
  l: $paragraph-l,
  m: $paragraph-m,
  s: $paragraph-s,
);

$ui-xxxl: 28px;
$ui-xxl: 24px;
$ui-xl: 20px;
$ui-l: 18px;
$ui-m: 16px;
$ui-s: 14px;
$ui-xs: 12px;
$ui: (
  xxxl: $ui-xxxl,
  xxl: $ui-xxl,
  xl: $ui-xl,
  l: $ui-l,
  m: $ui-m,
  s: $ui-s,
  xs: $ui-xs,
);

// Colors

// this is brand colors for the app, might be updated in future, need to diplsay this somewhere in story with colors we're using within the app
// also this colors won't be used as variables, we create an additional variant for this
$brand-iris: #efe9fe;
$brand-lavender: #dfd8fd;
$brand-oak: #f8e5d2;
$brand-potato: #caa38f;
$brand-mushroom: #ae836d;
$brand-aubergine: #720053;
$brand-plum: #c799ba;
$brand-lilly-pilly: #f5edf3;
$brand-blackbird: #000000;
$brand-rhubarb: #f6505b;
$brand-lychee: #ffc6c3;
$brand-fig: #95808f;
$brand-linnen: #cccccc;
$brand-cotton: #e9e4e0;

$primary-1: #982979;
$primary: (
  1: $primary-1,
);

$secondary-1: #f5edf3;
$secondary: (
  1: $secondary-1,
);

$foreground-high: #2b011f;
$foreground-medium: #6b4d62;
$foreground-low: #806779;
$foreground-on-primary: #ffffff;
$foreground-on-secondary: #982979;
$foreground-disabled: #aa99a5;
$foreground: (
  "High": $foreground-high,
  "Medium": $foreground-medium,
  "Low": $foreground-low,
  "OnPrimary": $foreground-on-primary,
  "OnSecondary": $foreground-on-secondary,
  "Disabled": $foreground-disabled,
);

$stroke-high: #bfb3bc;
$stroke-medium: #ece8eb;

$stroke: (
  "High": $stroke-high,
  "Medium": $stroke-medium,
);

$element-1: #f0edef;
$element-2: #fbfaf9;
$element-3: #f5f1ee;
$element-disabled: #f6f4f3;
$element: (
  1: $element-1,
  2: $element-2,
  3: $element-3,
  "Disabled": $element-disabled,
);

$surface-1: #ffffff;
$surface: (
  1: $surface-1,
);

$background-1: #f4f1ef;
$background-2: #e9e4e0;
$background: (
  1: $background-1,
  2: $background-2,
);

$information-050: #d8ecf7;
$information-300: #89c5e8;
$information-500: #3a9fd9;
$information-700: #2c77a3;
$information-900: #174057;
$information: (
  "050": $information-050,
  300: $information-300,
  500: $information-500,
  700: $information-700,
  900: $information-900,
);

$positive-050: #e1f0ec;
$positive-300: #a6d2c5;
$positive-500: #6ab49e;
$positive-700: #4a7e6f;
$positive-900: #2a483f;
$positive: (
  "050": $positive-050,
  300: $positive-300,
  500: $positive-500,
  700: $positive-700,
  900: $positive-900,
);

$warning-050: #ffeacd;
$warning-500: #b98033;
$warning-700: #946629;
$warning-900: #975600;
$warning: (
  "050": $warning-050,
  500: $warning-500,
  700: $warning-700,
  900: $warning-900,
);

$critical-050: #f6e2e4;
$critical-300: #e29fa4;
$critical-500: #c54049;
$critical-700: #c54049;
$critical-900: #76262c;
$critical: (
  "050": $critical-050,
  300: $critical-300,
  500: $critical-500,
  700: $critical-700,
  900: $critical-900,
);

$additional-1-100: #fbfaf9;
$additional-1-200: #f6f4f3;
$additional-1-500: #e9e4e0;
$additional-1-550: #d2cdca;
$additional-1-600: #bab6b3;
$additional-1-650: #a3a09d;
$additional-1: (
  100: $additional-1-100,
  200: $additional-1-200,
  500: $additional-1-500,
  550: $additional-1-550,
  600: $additional-1-600,
);

$additional-2-100: #f5f5f5;
$additional-2-200: #ebebeb;
$additional-2-500: #cccccc;
$additional-2-550: #b8b8b8;
$additional-2-600: #a3a3a3;
$additional-2: (
  100: $additional-2-100,
  200: $additional-2-200,
  500: $additional-2-500,
  550: $additional-2-550,
  600: $additional-2-600,
);

$additional-3-100: #faf6f9;
$additional-3-200: #f7f1f5;
$additional-3-400: #f7f1f5;
$additional-3-500: #f5edf3;
$additional-3-550: #dcd5db;
$additional-3-600: #c4bec2;
$additional-3: (
  100: $additional-3-100,
  200: $additional-3-200,
  500: $additional-3-500,
  550: $additional-3-550,
  600: $additional-3-600,
);

$additional-4-100: #fcfbff;
$additional-4-200: #f9f6ff;
$additional-4-500: #efe9fe;
$additional-4-550: #d7d2e5;
$additional-4-600: #bfbacb;
$additional-4: (
  100: $additional-4-100,
  200: $additional-4-200,
  500: $additional-4-500,
  550: $additional-4-550,
  600: $additional-4-600,
);

$additional-5-100: #f9f7ff;
$additional-5-200: #f2effe;
$additional-5-500: #dfd8fd;
$additional-5-550: #c9c2e4;
$additional-5-600: #b2adca;
$additional-5: (
  100: $additional-5-100,
  200: $additional-5-200,
  500: $additional-5-500,
  550: $additional-5-550,
  600: $additional-5-600,
);

$additional-6-100: #d5ccd2;
$additional-6-200: #cabfc7;
$additional-6-500: #95808f;
$additional-6-550: #867381;
$additional-6-600: #776672;
$additional-6: (
  100: $additional-6-100,
  200: $additional-6-200,
  500: $additional-6-500,
  550: $additional-6-550,
  600: $additional-6-600,
);

$additional-7-100: #f4ebf1;
$additional-7-200: #e9d6e3;
$additional-7-500: #c799ba;
$additional-7-550: #b38aa7;
$additional-7-600: #9f7a95;
$additional-7: (
  100: $additional-7-100,
  200: $additional-7-200,
  500: $additional-7-500,
  550: $additional-7-550,
  600: $additional-7-600,
);

$additional-8-100: #e3ccdd;
$additional-8-200: #c799ba;
$additional-8-500: #720053;
$additional-8-550: #67004b;
$additional-8-600: #5b0042;
$additional-8: (
  100: $additional-8-100,
  200: $additional-8-200,
  500: $additional-8-500,
  550: $additional-8-550,
  600: $additional-8-600,
);

$additional-9-100: #fff4f3;
$additional-9-200: #ffe8e7;
$additional-9-500: #ffc6c3;
$additional-9-550: #e5b2af;
$additional-9-600: #cc9e9c;
$additional-9: (
  100: $additional-9-100,
  200: $additional-9-200,
  500: $additional-9-500,
  550: $additional-9-550,
  600: $additional-9-600,
);

$additional-10-100: #fddcde;
$additional-10-250: #fba7ad;
$additional-10-500: #f6505b;
$additional-10-550: #dd4852;
$additional-10-600: #c54049;
$additional-10: (
  100: $additional-10-100,
  250: $additional-10-250,
  500: $additional-10-500,
  550: $additional-10-550,
  600: $additional-10-600,
);

$additional-11-100: #fefaf6;
$additional-11-200: #fcf5ed;
$additional-11-500: #f8e5d2;
$additional-11-550: #dfcebd;
$additional-11-600: #c6b7a8;
$additional-11: (
  100: $additional-11-100,
  200: $additional-11-200,
  500: $additional-11-500,
  550: $additional-11-550,
  600: $additional-11-600,
);

$additional-12-050: #faf6f4;
$additional-12-100: #f4ede9;
$additional-12-500: #caa38f;
$additional-12-550: #b69381;
$additional-12-600: #a28272;
$additional-12: (
  "050": $additional-12-050,
  100: $additional-12-100,
  500: $additional-12-500,
  550: $additional-12-550,
  600: $additional-12-600,
);

$additional-13-100: #efe6e2;
$additional-13-200: #dfcdc5;
$additional-13-500: #ae836d;
$additional-13-550: #9d7662;
$additional-13-600: #8b6957;
$additional-13: (
  100: $additional-13-100,
  200: $additional-13-200,
  500: $additional-13-500,
  550: $additional-13-550,
  600: $additional-13-600,
);

// Shadows

$box-shadow-03: 0 2px 8px rgba($foreground-high, 0.05), 0 6px 12px rgba($foreground-high, 0.15);
$box-shadow-04: 0 5px 16px rgba(0, 0, 0, 0.25);
$box-shadow: (
  "03": $box-shadow-03,
  "04": $box-shadow-04,
);
$input-focus-shadow: 0 0 4px rgba($primary-1, 0.8);
$dropdown-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.15);

// Other

$background-color: #e5e5e5;
$light-color: #fff;
$dark-color: #333;
$purple: #ff6099;
$base-typography-color: #131313;

// Sizes

$border-radius: 4px;
$border-radius-sm: math.div($border-radius, 2); // 2px
$border-radius-lg: $border-radius * 2; // 8px
$border-radius-xl: $border-radius * 4; // 16px
$border-radius-xxl: $border-radius * 6; // 24px
$border-radius-xxxl: $border-radius * 8; // 32px
$border-radius-xxxxl: $border-radius * 10; // 40px

$header-height: 111px;
$footer-height: 117px;

$page-content-padding: 4rem 4rem 4rem;
// paddings

$page-padding: 40px;

.cursor-pointer {
  cursor: pointer;
}

;@import "sass-embedded-legacy-load-done:17";