@use "scss/variables";
@use "scss/functions";

.icon {
  font-size: 14px;
  color: variables.$foreground-low;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: variables.$element-3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tdImportant:first-child {
  > div:first-child::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: variables.$information-500;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;

  }
}

.blueCircle {
  width: 10px;
  height: 10px;
  background: variables.$information-500;
  border-radius: 50%;
  &.isTransparent{
    background: transparent;
  }
}

.redCircle {
  @extend .blueCircle;
  background: variables.$critical-500;
}
