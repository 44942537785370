@use "scss/variables";
@use "scss/functions";

.blankField{
  height: 100%;
  display: flex;
  align-items: center;
  color: variables.$foreground-medium;
}

.errorTooltip {
  :global{
    .tooltip-inner {
      border: 1px solid #000000!important;
      font-style: italic;
      border-radius: 4px;
    }
    .tooltip-arrow {
      &::before {
        border-top-color: #ffffff!important;
        z-index: 1;
      }
      &::after {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        top: -1px;
        border-width: 0.5rem 0.5rem 0;
        left: -0.14rem;
        border-top-color: #232222 !important;
      }
    }
  }
}
.errorIcon {
  position: relative;
  background-color: inherit;
  span {
    color: variables.$critical-500;
    cursor: pointer;
    position: absolute;
    right: -3px;
    top: -7px;
    z-index: 1;
    background-color: inherit;
  }
}

.textInput {
  input, textarea {
    min-height: 16px!important;
    padding: functions.get-rem(12px) functions.get-rem(6px);
    background: transparent;
    font-size: functions.get-rem(14px);
    border: 1px solid variables.$stroke-medium;
    border-radius: 8px;
    &::placeholder {
      font-style: italic;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid variables.$foreground-medium;
    }
    &:hover {
      border: 1px solid variables.$foreground-medium!important;
    }
    &:disabled {
      background: variables.$element-2;
      border: 1px solid variables.$stroke-medium;
      &:hover {
        border: 1px solid variables.$stroke-medium!important;
      }
    }
  }
}

.dateInput {
  input[value=""] {
    & + div {
      svg {
        path {
          stroke: variables.$foreground-medium!important;
        }
      }
    }
  }
  input {
    padding: functions.get-rem(12px) functions.get-rem(6px);
    border: 1px solid variables.$stroke-medium;
    background: transparent;
    font-size: functions.get-rem(14px);
    border-radius: 8px;
    &::placeholder {
      font-style: italic;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid variables.$foreground-medium;
    }
    &:hover {
      border: 1px solid variables.$foreground-medium!important;
    }
    &:disabled {
      background: variables.$element-2;
      border: 1px solid variables.$stroke-medium;
      &:hover {
        border: 1px solid variables.$stroke-medium!important;
      }
    }
  }

  :global {
    .react-datepicker-popper {
      z-index: 10;
    }

    svg.calendar-icon {
      path {
        stroke: variables.$foreground-high !important;
      }
    }

    .h-100 {
      width: 1rem !important;
    }
  }
}

.selectInput {
  input[value="Not selected"] {
    color: variables.$foreground-medium!important;
    font-style: italic;
    & + div {
      svg {
        path {
          fill: variables.$foreground-medium!important;
        }
      }
    }
  }
  input {
    padding: functions.get-rem(12px) functions.get-rem(0px) functions.get-rem(12px) functions.get-rem(6px);
    border: 1px solid variables.$stroke-medium!important;
    background: transparent;
    font-size: functions.get-rem(14px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid variables.$foreground-medium;
    }
    &:hover {
      border: 1px solid variables.$foreground-medium!important;
    }
  }

  :global {
    .h-100 {
      width: 1rem !important;
    }

    .dropdown {
      position: inherit;
    }

    .dropdown-menu {
      //position: absolute;
      max-width: 250px;
    }

    .dropdown-item {
      white-space: break-spaces;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  padding: functions.get-rem(10px) 0;

  .dropdown {
    position: inherit;

    :global {
      .dropdown-menu {
        max-width: 100px;
      }

      .dropdown-item {
        white-space: break-spaces;
      }
    }

    span {
      cursor: pointer;
    }

    .iconButton {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: functions.get-rem(24px);
      height: functions.get-rem(24px);
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      color: variables.$foreground-high;
      font-size: 24px;

      &:hover {
        background: variables.$element-1;
      }

      &:focus {
        background: variables.$element-1;
      }

      &:active {
        background: variables.$element-1;
      }
    }
  }
}
