@use "scss/variables";
@use "scss/functions";

.table {
  background-color: white;
  margin-bottom: 0 !important;

  thead {
    border-bottom: 1px solid variables.$element-1;

    tr {
      th {
        font-weight: 400 !important;
        font-size: 12px !important;
        color: variables.$foreground-low;
        padding: functions.get-rem(8px) functions.get-rem(14px);
      }
    }
  }

  tr {
    td {
      border: none;
      padding: functions.get-rem(14px);
      border-bottom: 1px solid variables.$element-1;
    }
  }
}

.table-container-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.table-progress-bar {
  &-bar {
    height: 100%;
    border-radius: functions.get-rem(variables.$border-radius-lg);
    background-color: variables.$positive-500;
  }
}
