@use "scss/variables";

.backgroundElement {
  background-color: variables.$element-2;
}

.backgroundInfo {
  background-color: variables.$information-050;
}

.backgroundPositive {
  background-color: variables.$positive-050;
}

.border {
  border: 1px solid variables.$stroke-medium;
}

.borderBottom {
  border-bottom: 1px solid variables.$stroke-medium;
}

.tableWrapper {
  max-width: 100%;
  overflow-x: scroll;
}
