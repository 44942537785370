@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.subtitle {
  color: variables.$foreground-medium;
}

.card {
  width: 340px;
  border: none;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  transition: all 0.3s;
  flex-direction: column;
  padding: functions.get-rem(30px);
  min-width: functions.get-rem(300px);
  min-height: functions.get-rem(450px);
  background-color: variables.$surface-1;

  button {
    max-width: 100%;
    margin-top: auto;
  }

  .introduction {
    max-height: 5em;
    overflow: hidden;
    white-space: normal;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-bottom: functions.get-rem(24px);
  }

  @include media-breakpoint-down(md) {
    margin: 0 auto;
    margin-bottom: functions.get-rem(24px);
    padding-left: functions.get-rem(24px);
    padding-right: functions.get-rem(24px);

    &.company {
      flex-shrink: 0;
    }
  }

  &:hover {
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  }

  &.company {
    img {
      height: functions.get-rem(120px);
      object-fit: contain;
      object-position: 0 50%;
    }

    svg {
      width: functions.get-rem(120px);
      height: functions.get-rem(120px);
    }

    p {
      color: variables.$foreground-medium;
    }
  }
}

.user-email {
  color: variables.$foreground-medium;
  font-size: variables.$paragraph-s;
}

.account-section {
  .link {
    display: flex;
    font-weight: 500;
    transition: all 0.6s;
    text-decoration: none;
    color: variables.$foreground-medium;
    padding: functions.get-rem(17px) functions.get-rem(18px);
    padding-left: functions.get-rem(7px);

    &:hover {
      background-color: variables.$surface-1;
    }

    & > p {
      margin-left: map-get(bootstrap.$spacers, 2) !important;
    }

    svg {
      min-width: functions.get-rem(20px);
      width: functions.get-rem(20px);
      height: functions.get-rem(20px);

      path {
        stroke: variables.$foreground-medium;
      }
    }
  }
}

.support-section {
  border-top: 1px solid #0000001a;
  margin-top: auto;
  color: variables.$foreground-medium;
  padding-top: 16px;

  .link {
    color: variables.$foreground-medium;
    text-decoration: underline;
  }
}

.block-icons {
  width: 120px;
  padding-bottom: functions.get-rem(20px);
  svg {
    width: 100%;
    height: 100%;
  }
}

.image {
  flex-shrink: 0;
  border-radius: 50%;
  width: functions.get-rem(64px) !important;
  height: functions.get-rem(64px) !important;

  &.isEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$secondary-1;

    svg {
      width: 36px;
      height: 36px;

      path {
        stroke-width: 0.5px;
        stroke: variables.$secondary-1;
      }
    }
  }
}

.empty-logo {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: functions.get-rem(120px);
  height: functions.get-rem(120px);
  background-color: variables.$surface-1;

  svg {
    width: functions.get-rem(36px) !important;
    height: functions.get-rem(36px) !important;

    path {
      stroke-width: 2.5px;
      stroke: variables.$foreground-medium;
    }
  }
}

.plan-details {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(34px);
    height: functions.get-rem(34px);
    border: 1px solid variables.$foreground-high;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
