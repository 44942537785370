@use "scss/variables";
@use "scss/functions";

.table {
  border-collapse: collapse;

  thead {
    background-color: variables.$element-1;
  }

  th {
    padding: 8px 32px !important;
    font-weight: normal !important;
  }

  td {
    padding: 8px 32px !important;
    vertical-align: middle;
  }

  thead,
  tbody,
  th {
    border: none !important;
  }

  tbody {
    tr:hover {
        background-color: variables.$element-1;
    }
  }
}
