.capTableItem {
  flex: 1;
  svg {
    path {
      stroke-width: 1.2px !important;
    }
  }
}

.chart {
  width: 82px;
}

.chartInfo {
  p {
    margin-bottom: 0;
  }
}