@use "scss/variables";
@use "scss/functions";

.wrap {
  background: variables.$element-3;
  padding: functions.get-rem(24px);
  border-radius: 8px;
  .icon {
    font-size: 24px;
    color: variables.$foreground-high;
    margin-right: 12px;
  }
}