@use "scss/variables";
@use "scss/functions";

.condition {
  border-radius: functions.get-rem(variables.$border-radius);
  background-color: variables.$surface-1;

  :global {
    .form-group {
      margin-bottom: functions.get-rem(24px);
    }

    .form-control {
      &.is-invalid {
        &[id$="percentageOfIndividualPlanShares"] {
          border-color: variables.$warning-500;
        }
      }
    }
  }
}

.date-picker {
  z-index: 10;
}
