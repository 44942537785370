.tab-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  border-bottom: 1px solid #000 !important;
  &-active {
    border-bottom: 3px solid #000 !important;
    margin-bottom: -2px;
  }
}
