@use "scss/variables";
@use "scss/functions";

.view-agreement {
  border-radius: functions.get-em(16px);
  background-color: variables.$additional-7-100;
  color: variables.$foreground-on-secondary;
}

.link-btn {
  text-decoration: underline;
  color: variables.$primary-1;

  &:hover {
    cursor: pointer;
  }
}
