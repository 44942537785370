@use "scss/variables";

.elementBackground {
  background-color: variables.$element-1;
}

.borderBottom {
  border-bottom: 1px solid variables.$stroke-high;
}

.imageContainer {
  //width: 222px;
}

.removeMinWidth {
  min-width: 0 !important;
}

.customScroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background: variables.$surface-1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: variables.$additional-1-550;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: variables.$additional-1-650;
  }
}
