@use "scss/variables";
@use "scss/functions";

.wrap {
  width: max-content!important;
  tbody > tr:nth-of-type(odd) > * {
    --bs-table-bg-type: white !important;
  }

  tbody > tr:nth-of-type(even) > * {

    background-color: variables.$element-2 !important;
  }

  border: 0;
  .resizer {
    display: inline-block;
    background: transparent;
    width: 6px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
    cursor: col-resize;
    user-select: none;
    &:hover {
      background: variables.$primary-1;
    }
    &.isResizing {
      background: variables.$primary-1;
      touch-action: none;
      cursor: col-resize;
    }
  }

  tr {
    border: 0;
  }

  thead {
    border: 0 !important;
    tr {
      border: 0 !important;
    }
    th {
      position: relative;
      vertical-align: middle;

      border-width: 1px 1px 1px 0;
      border-color: variables.$element-1;
      font-size: functions.get-rem(14px);
      font-family: variables.$font-family-base;
      color: variables.$foreground-high;
      font-weight: 500;
      line-height: 1.3;
      padding: 8px 8px;
      background: variables.$element-1;
      border-radius: 0;

      &:first-child {
        border-radius: 4px 0 0 0;
        border-width: 1px 1px 1px 1px;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
        border-width: 1px 1px 1px 0;
      }
    }
  }

  tbody {
    border: 0 !important;
    tr {
      border: 0 !important;
    }

    td {
      border-width: 0 1px 1px 0;
      border-color: #ECE8EB;
      padding: 8px 8px !important;
      vertical-align: middle;
      &:first-child {
        border-width: 0 1px 1px 1px;
      }
    }
  }

  tfoot {
    border: 0 !important;
    tr {
      border: 0 !important;
    }
    th {
      font-size: functions.get-rem(14px);
      font-family: variables.$font-family-base;
      color: variables.$foreground-high;
      font-weight: 500;
      line-height: 1.3;
      padding: 16px 16px;
      background: variables.$element-1;
      border-radius: 0;
      border-width: 0 0 1px 0;
      border-color: variables.$element-1;
      &:first-child {
        border-radius: 0 0 0 4px;
        border-width: 0 0 1px 1px;
      }
      &:last-child {
        border-radius: 0 0 4px 0;
        border-width: 0 1px 1px 0;
      }
    }
  }
}

.customScroll {
  // create custom  horizontal scroll with purple color and 10px width
  //show scrollbar;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background: variables.$surface-1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8c3ef;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: variables.$primary-1;
  }
}


  .errorNotification {
    margin-bottom: functions.get-rem(16px);
    padding: functions.get-rem(12px) functions.get-rem(16px);
    background: variables.$critical-050;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    .errorIcon {
      color: variables.$critical-500;
      margin-right: functions.get-rem(8px);
    }
    .errorText {
      font-size: functions.get-rem(14px);
      font-family: variables.$font-family-base;
      color: variables.$foreground-medium;
      font-weight: 500;
      line-height: 1.3;
      margin-right: functions.get-rem(16px);
    }
    &.errorValid {
      background: variables.$positive-050;
      .errorIcon {
        color: variables.$positive-900;
      }
      .errorText {
        color: variables.$foreground-medium;
      }
    }
  }
