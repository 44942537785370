@use "scss/variables";
@use "scss/functions";

$card-padding: functions.get-rem(24px);

.program-card {
  border-radius: 4px;
  height: 240px;
  background-color: variables.$surface-1;
  border: 1px solid variables.$additional-6-200;

  //&:hover {
  //  cursor: pointer;
  //  background-color: variables.$additional-3-200;
  //}
}

.progress {
  &:global {
    &.progress {
      height: 8px;
      background-color: variables.$element-1;
      width: 120px;

      .progress-bar {
        background-color: variables.$positive-500;
      }
    }
  }
}

.program-name-and-description-wrap {
  display: flex;
  flex-direction: column;

  .purpose {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.read-more {
  cursor: pointer;
  text-decoration: underline;
  margin-top: functions.get-rem(4px);
}

.bottom-section {
  display: flex;
  align-items: center;
  padding: 0 $card-padding;
  border-top: 1px solid variables.$additional-6-200;
}

.description-modal {
  :global {
    .modal-dialog {
      max-width: functions.get-rem(690px) !important;
    }
  }

  .content {
    height: 80vh;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background-color: variables.$surface-1;
    padding: functions.get-rem(40px);

    button {
      margin-top: auto;
      width: max-content;
    }
  }
}
