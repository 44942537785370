@use "scss/variables";

.container {
  display: flex;
  flex-direction: column;
  flex-basis: 31.7%;
  background-color: white;
  cursor: pointer;

  .email {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    color: variables.$foreground-low;
  }

  .divider {
    border-top: 1px solid variables.$element-3;
  }

  &:hover {
    background-color: variables.$additional-3-200;
  }
}


