@use "scss/variables";

.background {
  border-radius: 4px;
  background-color: variables.$element-3;
}

.copy {
  all: unset;
  background: none;
  cursor: pointer;

  svg {
    color: variables.$foreground-medium;
  }
}
