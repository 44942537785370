.authorized-input {
  div {
    div {
      bottom: -75% !important;
    }
  }
}

.approval-date-field {
  :global {
    .react-datepicker-wrapper {
      width: 60%;
    }
  }
}
