@use 'scss/bootstrap';
@use 'scss/variables';
@use 'scss/functions';

$sidebar-font-size: 14px;
$sidebar-transition-duration: .3s;
$sidebar-transition-timing-function: ease-out;

@mixin toggle-button {
  .toggle-button {
    &:global {
      &.btn {
        @content;
      }
    }
  }
}

@mixin toggle-button-hide {
  transform: translateX(calc(-100% + #{variables.$border-radius-xl}));
}

.sidebar {
  position: absolute;
  z-index: 1;
  top: functions.get-rem(40px);
  left: 100%;
  width: functions.get-rem(245px);
  font-size: functions.get-rem($sidebar-font-size);
  color: variables.$foreground-high;
  transform: translateX(-100%);
  transition: transform $sidebar-transition-duration $sidebar-transition-timing-function;

  &.open {
    transform: translateX(0);

    @include toggle-button {
      background-color: transparent;
      @include toggle-button-hide;
    }
  }
}

$content-background-color: variables.$element-1;
$content-border-radius: functions.get-em(variables.$border-radius-xl, $sidebar-font-size);

.content {
  border-radius: 0 $content-border-radius $content-border-radius 0;
  background-color: $content-background-color;
  padding:
    functions.get-em(28px, $sidebar-font-size)
    functions.get-em(20px, $sidebar-font-size)
    functions.get-em(22px, $sidebar-font-size);
}

@include toggle-button {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100%;
  font-size: inherit;
  color: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: $content-background-color;
  padding-left: calc(#{bootstrap.$btn-padding-y} + #{$content-border-radius});
  padding-right: bootstrap.$btn-padding-y;
  margin-left: -($content-border-radius);
  transition: all $sidebar-transition-duration $sidebar-transition-timing-function;

  &:focus {
    box-shadow: none;
  }

  &:local {
    &.hide {
      @include toggle-button-hide;
    }
  }

  svg {
    margin: 0 !important;
  }
}
