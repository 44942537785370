@use "scss/functions";
@use "scss/variables";

.header {
  :global {
    .card-header {
      div {
        border-radius: 4px;
        display: flex;
        align-items: center;
        height: functions.get-rem(80px) !important;
      }

      .collapsible-header-part {
        padding-left: 32px !important;
      }
    }
  }
}

.container {
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  background-color: variables.$surface-1;
  padding: functions.get-rem(20px) functions.get-rem(16px);
}

.history-single {
  display: flex;
  color: variables.$foreground-medium;
  padding: functions.get-rem(20px) functions.get-rem(64px);

  :first-child {
    flex-basis: 20%;
  }

  :last-child {
    color: variables.$foreground-high;
  }
}

.view-more-btn {
  width: max-content;
  margin-left: 21.5%;
}
