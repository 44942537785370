@use "scss/variables";
@use "scss/functions";


.wrap {
  display: inline-flex;
  padding: 2px 6px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  text-align: center;


  &.m {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    min-width: 20px;
    line-height: 16px;
    max-height: 20px;
  }
  &.s {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 16px;
    line-height: 12px;
    max-height: 16px;
  }
  &.neutral {
    background: variables.$element-3;
    color: variables.$foreground-low;
  }

  &.positive {
    background: variables.$positive-050;
    color: variables.$positive-900;
  }
}