@use "scss/functions";
@use "scss/variables";

.wrap {
  background: variables.$surface-1;
  width: functions.get-rem(1100px);
  padding: functions.get-rem(64px);
  border-radius: functions.get-rem(8px);
  margin: 0 auto;
  margin-bottom: 50px;
  &.short {
    width: functions.get-rem(984px)
  }

  .info {
    display: block;
    max-width: functions.get-rem(876px);
    background: variables.$information-050;
    padding: functions.get-rem(24px);
    border-radius: 4px;
    margin-bottom: functions.get-rem(64px);
  }
  .currency {
    color: variables.$foreground-medium;
  }
  .subheader {
    color: variables.$foreground-low;
  }
}

.card {
  margin: 0 auto;
  display: flex;
  padding: 24px 32px;
  border-radius: 8px;
  flex-direction: column;
  width: functions.get-rem(320px);
  min-height: functions.get-rem(485px);
  border: 1px solid variables.$primary-1;

  .tag {
    width: max-content;
    color: white !important;
    background-color: variables.$primary-1 !important;
  }

  .price {
    display: flex;
    align-items: center;
    font-size: 36px;
  }

  .quantity {
    display: flex;
    align-items: center;
    border-radius: 40px;
    padding: 0 16px;
    width: 80%;
    height: functions.get-rem(32px);
    border: 1px solid variables.$foreground-high;
  }
}

.ugly-button {
  margin-bottom: 16px;
  height: 120px;
  width: 300px;
  display: flex;
  align-items: center;
  border: 4px solid darkgreen;

  button {
    height: 30px !important;
  }
}
