@use "scss/variables";
@use "scss/functions";
.valuation {
  position: relative;

  .inputField {
    min-width: functions.get-rem(272px);
  }
  .buttonField {
    width: 32px;
    height: 32px;
    position: absolute;
    right: -40px;
    top: 4px;
    margin-left: functions.get-rem(8px);
  }
  p {
    max-width: functions.get-rem(554px);
  }
}

.input {
  min-width: functions.get-rem(300px);

  ::placeholder {
    font-style: normal !important;
  }

  svg {
    width: 24px;
    height: 24px;
    color: variables.$foreground-low;
  }

  input {
    height: 40px;
    text-indent: 10px;
    padding-right: 28px;
    border: 1px solid transparent;
    background-color: variables.$element-2 !important;

    &:hover {
      border-color: transparent !important;
    }

    &:focus {
      border: 1px solid variables.$primary-1 !important;
    }
  }
}

.chart-icon {
  path {
    stroke-width: 2 !important;
  }
}

.input-remove-icon {
  cursor: pointer;
  position: absolute;
  top: functions.get-rem(10px);
  width: functions.get-rem(20px);
  height: functions.get-rem(20px);
  right: functions.get-rem(8px) !important;
  color: variables.$foreground-low !important;
}

.mark {
  top: -10px;
  right: -36px;
  position: absolute;
  color: variables.$foreground-low !important;
}

.helper {
  z-index: 999;
  border-radius: 4px;
  position: absolute;
  width: functions.get-rem(335px);
  border: 1px solid variables.$information-500;
  background-color: variables.$information-050;
  margin-top: functions.get-rem(10px) !important;
  margin-left: functions.get-rem(-130px) !important;
}
