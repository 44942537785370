@use "scss/variables";
@use "scss/functions";

$main-font-size: 14px;

.wrap {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: functions.get-rem($main-font-size);
}

.info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-width: functions.get-em(150px, $main-font-size);
  white-space: nowrap;
  color: variables.$foreground-medium;
  padding-right: functions.get-em(10px, $main-font-size);
}

.progress {
  &:global {
    &.progress {
      flex-grow: 1;
      max-width: functions.get-em(98px, $main-font-size);
      height: functions.get-em(16px, $main-font-size);
      font-size: 1em;
      border: 2px solid variables.$element-1;
      border-radius: functions.get-em(variables.$border-radius-xl, $main-font-size);
      background-color: variables.$element-1;

      .progress-bar {
        background-color: variables.$positive-500;
      }
    }
  }
}
