@use "src/scss/variables";
@use "src/scss/functions";
.wrap {
  background: white;

  .campaign {
    padding: 18px 32px;

  }

  .menu {
    display: flex;

    .menuItem {
      padding: functions.get-rem(18px) functions.get-rem(32px);
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      color: variables.$foreground-low;
      border-bottom: 2px solid white;

      &.active {
        color: variables.$primary-1;
        border-bottom: 2px solid variables.$primary-1;
      }

      .menuCircle {
        background: variables.$element-3;
        padding: 2px 6px;
        border-radius: 16px;
      }
    }
  }
}