@use "scss/variables";
@use "scss/functions";

.wrap {
  margin-top: functions.get-rem(40px);
  display: block;

  .slidePanel {
    background: variables.$element-2;
    padding: 24px;
    border-radius: 5px;
  }
  .slidePanelHeader {
    background: variables.$element-2!important;
    font-family: "Inter", sans-serif!Important;
    padding: 0!important;
    padding-bottom: 30px!important;
    border-bottom: 1px solid variables.$stroke-medium;
    margin-bottom: functions.get-rem(24px);
  }

  .step {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: functions.get-rem(16px);
    font-weight: 500;
    color: variables.$foreground-medium;
    background: variables.$additional-1-550;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

  }

  .empty {
    border: 1px solid variables.$stroke-medium;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}