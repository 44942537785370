@use "scss/variables";
@use "scss/functions";

.update-modal {
  :global {
    .modal-dialog {
      .modal-content {
        text-align: center;
        max-width: functions.get-rem(420px);
      }
    }
  }

  .content {
    border-radius: 8px;
    background-color: variables.$surface-1;
    padding: functions.get-rem(56px) functions.get-rem(72px);
  }
}
