@use "scss/variables";
@use "scss/functions";

.row {
  display: flex;
  align-items: center;
  height: functions.get-rem(80px);
  padding: 0 functions.get-rem(24px);
  background-color: variables.$surface-1;

  .date {
    flex-basis: 5%;
    flex-shrink: 0;
  }

  .btn {
    padding: calc(0.225rem + 2px) calc(1rem + 2px);
    font-size: 14px;
  }

  .reminderSent {
    padding-right: 18px;
    text-align: right;
  }
}

.menu-button {
  color: variables.$foreground-high !important;
}

.actions {
  margin-left: auto;
}
