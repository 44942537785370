@use "scss/variables";

.circle {
  border-radius: 50%;
  width: 64px;
  height: 64px;

  &Yellow {
    @extend .circle;
    background-color: variables.$warning-050;

    svg {
      path {
        stroke: variables.$warning-700;
      }
    }
  }

  &Blue {
    @extend .circle;
    background-color: variables.$information-050;

    svg {
      path {
        stroke: variables.$information-700;
      }
    }
  }

  &Green {
    @extend .circle;
    background-color: variables.$positive-050;

    svg {
      path {
        stroke: variables.$positive-700;
      }
    }
  }
}
