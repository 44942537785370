@use "scss/variables";
@use "scss/functions";

.container {
  border-radius: 8px;
  background-color: variables.$element-3;
  padding: functions.get-rem(24px);
}

.head-container {
  border-radius: 8px;
  padding: functions.get-rem(24px);
  background-color: variables.$element-2;
}

.playerWrapper {
}

.video {
  height: 100%;
  width: 100%;
  margin-left: functions.get-rem(24px);
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 8px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

.step-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: functions.get-rem(32px);
  height: functions.get-rem(32px);
  color: variables.$foreground-medium;
  background-color: variables.$element-3;
}

.link {
  color: variables.$primary-1;
}

.banner {
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  padding: functions.get-rem(12px) functions.get-rem(24px);
  background-color: variables.$information-050;

  &.success {
    background-color: variables.$positive-050;
  }
}

.separator {
  height: 1px;
  width: 100%;
  background-color: variables.$stroke-high;
}
