@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.headerHide {
  display: none;
}
.header {
  width: 100%;
  z-index: 100;
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: functions.get-rem(90px);
  color: variables.$foreground-high;
  padding: 0 map-get(bootstrap.$spacers, 3);

  &.wizard {
    color: variables.$element-1;
  }

  .burger-menu {
    display: none;
  }

  @include media-breakpoint-down(md) {
    height: functions.get-rem(96px);
    background-color: variables.$element-1;
    padding: functions.get-rem(32px) functions.get-rem(40px);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.15);

    .title,
    .header-public,
    .privacy-button,
    .nav-items {
      display: none;
    }

    .burger-menu {
      display: block;
      position: relative;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: functions.get-rem(32px) functions.get-rem(22px);
  }
}

.header-left,
.header-right {
  display: flex;
  align-items: center;

  .profile-image {
    display: none;
    border-radius: 50%;
    width: functions.get-rem(32px);
    height: functions.get-rem(32px);

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}

.unlisted-icon {
  color: inherit;

  [fill] {
    fill: currentColor;
  }

  [stroke] {
    stroke: currentColor;
  }

  &.dark-theme {
    path {
      fill: variables.$foreground-high;
    }
  }
}

.unlisted-link {
  color: unset;

  &:hover {
    color: unset;
  }
}

.header-public {
  display: flex;
  align-items: center;
}

.title {
  font-size: functions.get-rem(20px);
}

.back-btn {
  color: variables.$foreground-high !important;

  svg {
    margin-right: functions.get-rem(12px) !important;
  }
}

.navigation-link {
  position: relative;
  transition: all 0.4s;
  text-decoration: none;
  padding: 6px 0 !important;
  color: variables.$foreground-medium !important;

  &::before {
    content: "";
    width: 0;
    height: 2px;
    bottom: -2px;
    position: absolute;
    transition: all 0.4s;
    background-color: variables.$foreground-high;
  }

  &:hover {
    color: variables.$foreground-high;

    &::before {
      width: 100%;
    }
  }

  &.active {
    color: variables.$foreground-high !important;
    border-bottom: 2px solid variables.$foreground-high;

    &:hover {
      &::before {
        width: 0;
      }
    }
  }

  &.dark-theme {
    color: variables.$element-1;
  }
}

.banner {
  height: functions.get-rem(128px);
}
.bannerReceiver {
  height: functions.get-rem(64px);
}
.finishSignUp {
  right: 0;
  width: 100%;
  position: absolute;
  background: variables.$information-050;
  padding: functions.get-rem(24px);
  align-items: center;
  justify-content: space-between;

  .icon {
    font-size: 24px;
    color: variables.$information-700;
    display: flex;
  }

  .text {
    max-width: 948px;
  }

  a {
    white-space: nowrap !important;
  }

  &.receiver {
    height: functions.get-rem(64px);
  }

  :global {
    .btn-primary {
      background: variables.$information-700;
      border-color: variables.$information-700;
      &:hover {
        background: variables.$information-900 !important;
      }
    }

    .btn-light {
      color: variables.$information-700 !important;
      background: variables.$information-050;
      &:hover {
        background: transparent !important;
      }
    }
  }
}
