@use "scss/variables";
@use "scss/functions";

.chart {
  display: flex;
  margin-top: auto;
  align-items: center;

  .progress {
    border-radius: 16px;
    height: functions.get-rem(20px);
    width: functions.get-rem(100px);
    border: 2px solid variables.$surface-1;

    :global {
      .progress-bar {
        background-color: variables.$primary-1;
      }
    }
  }
}
