@use "scss/variables";
@use "scss/functions";

.wrap {
  display: flex;
  gap: functions.get-rem(24px);
  margin-top: functions.get-rem(24px);

  .block {
    background: white;
    padding: 24px 24px 16px 24px;
    flex: 1;

    .note {
      font-size: functions.get-rem(14px);
      line-height: 1.3;
      font-weight: 500;
      color: variables.$foreground-low;
    }

    .title {
      margin-top: functions.get-rem(16px);
      font-size: functions.get-rem(24px);
      line-height: functions.get-rem(24px);
      margin-bottom: 32px;
    }

    .statistic {
      color: variables.$foreground-medium;
      padding-right: 8px;
      padding-left: 8px;
      :global {
        .ui-m {
          font-weight: 500;
        }
        .col {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    .icon {
      margin-left: 4px;
      font-size: 16px;
      cursor: pointer;
    }

    .label {
      border-radius: 8px;
      margin-left: 16px;
    }
    :global{
      .row {
        padding: 12px 0;
        border-bottom: 1px solid #ECE8EB;
      }
      .row:last-child {
        border-bottom: none;
      }
    }
  }
}
.content {
  color: variables.$foreground-low!important;
  max-width:160px;

  :global {
    .tooltip-inner{
      border: 1px solid variables.$stroke-high!important;
      font-size:12px!important;
      text-align: left;
      font-weight: 400!important;
    }
    .tooltip-arrow:before{
      border-top-color: variables.$stroke-high!important;
    }
  }
}