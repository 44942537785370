@use "scss/variables";
@use "scss/functions";

.card {
  position: relative;
  width: 330px;
  border-radius: functions.get-rem(variables.$border-radius-lg);
  background-color: variables.$surface-1;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.05);

  hr {
    border-color: variables.$surface-1;
    opacity: 1;
  }

  &:hover {
    .action-button {
      opacity: 1;
    }
  }
}

.action-button {
  position: absolute;
  top: 0;
  right: 0;
  color: inherit;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.share-class-description {
  display: -webkit-box;
  max-width: functions.get-rem(280px);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more {
  cursor: pointer;
  text-decoration: underline;
  margin-top: functions.get-rem(4px);
}

.description-modal {
  :global {
    .modal-dialog {
      max-width: functions.get-rem(690px) !important;
    }
  }

  .content {
    height: 80vh;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background-color: variables.$surface-1;
    padding: functions.get-rem(40px);

    button {
      margin-top: auto;
      width: max-content;
    }
  }
}
