@use "scss/functions";
@use "scss/variables";

.signature {
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  transition: all 0.5s;
  width: functions.get-rem(320px);
  height: functions.get-rem(136px);
  background-color: variables.$element-disabled;
  border: 2px solid variables.$primary-1;

  &.valid {
    border-color: variables.$positive-500;
    background-color: variables.$positive-050;
  }
}

.controls {
  top: -2px;
  left: -2px;
  display: flex;
  overflow: hidden;
  position: absolute;
  border-radius: 4px;
  flex-direction: column;
  border-collapse: collapse;
  width: functions.get-rem(40px);
  border: 2px solid variables.$primary-1;

  &.valid {
    border-color: variables.$positive-500;
  }

  .button:first-child {
    border-bottom: 2px solid variables.$primary-1;

    &.valid {
      border-bottom: 2px solid variables.$positive-500;
    }
  }

  .button {
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: all 0.5s;
    justify-content: center;
    height: functions.get-rem(66px);

    svg {
      path {
        transition: all 0.5s;
        stroke: variables.$foreground-medium;
      }
    }

    &:hover {
      background-color: variables.$primary-1;

      svg {
        path {
          stroke: variables.$foreground-high;
        }
      }
    }

    &.valid {
      &:hover {
        background-color: variables.$positive-050;
      }
    }
  }

  .active {
    background-color: variables.$primary-1;

    svg {
      path {
        stroke: variables.$foreground-high;
      }
    }

    &.valid {
      background-color: variables.$positive-050;
    }
  }
}

.canvas {
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .remove {
    cursor: pointer;
    width: max-content;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.2);
    }
  }

  .done-btn {
    padding-left: 20px;
    padding-right: 20px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
}
