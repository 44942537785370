@use "scss/variables";
@use "scss/functions";

.collapsible {
  overflow: hidden;
  border-radius: 4px;
}

.head {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: functions.get-rem(80px);
  background-color: variables.$surface-1;
  padding: 0 functions.get-rem(24px);

  .chevron {
    transition: 0.2s all;
    transform: rotate(90deg);
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
    color: variables.$foreground-low;

    &.open {
      transform: rotate(-90deg);
    }
  }
}

.count {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: functions.get-rem(22px);
  height: functions.get-rem(22px);
  color: variables.$foreground-medium;
  background-color: variables.$additional-1-200;
}

.request {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: functions.get-rem(80px);
  padding: 0 functions.get-rem(24px);
  background-color: variables.$surface-1;

  .description {
    font-size: functions.get-rem(12px);
    line-height: functions.get-rem(16px);
    color: variables.$foreground-medium;
  }

  &:hover {
    background-color: variables.$additional-3-400;
  }
}

.content {
  padding: functions.get-rem(8px) 0;
  background-color: variables.$surface-1;
}
