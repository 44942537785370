@use "scss/variables";

.fileContainer {
  border: 1px solid variables.$stroke-medium;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: variables.$additional-3-200;
  }

  &:active {
    background-color: variables.$additional-3-550;
  }
}
