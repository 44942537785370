@use "scss/functions";
@use "scss/variables";

.container {
  text-align: center;
  border-radius: 8px;
  padding: functions.get-rem(40px);
  background-color: variables.$element-1;
}

.modal-description {
  font-size: functions.get-rem(16px) !important;
}
