@use "scss/variables";

.wrap {
  display: flex;
  gap: 12px;
  font-size: 20px;
  color: variables.$foreground-low;

}
.circle {
  border-radius: 50%;
  padding: 2px 6px;
  background-color: variables.$element-3;
}