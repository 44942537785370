@use 'scss/variables';
@use 'scss/functions';

.title {
  margin-bottom: functions.get-em(10px, variables.$head-xxxs);

  &:last-child {
    margin-bottom: 0;
  }
}
