@use "src/scss/variables";

.wrap {
  color: variables.$foreground-low;
  font-size: 24px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;

  &.active {
    color: variables.$information-700;
  }

  svg {
    cursor: pointer;
  }
}

.wrapContainer {
  display: flex;
  align-items: center;

  &.input {
    align-items: start;
    >div:nth-child(1) {
      flex: 1;
    }
    .wrap {
      margin-top: 36px;
    }
  }
  &.switcher {
    align-items: start;
    >div:nth-child(1) {
      flex: 1;
    }
    .wrap {
      margin-top: 0;
    }
  }
}
