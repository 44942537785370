@use "scss/variables";
@use "scss/functions";

.container {
  border-radius: 8px;
  min-height: functions.get-rem(52px);
  background-color: variables.$element-2;
  position: sticky;
  top: 10px;
  z-index: 2;
}

.dropdown {
  :global {
    .dropdown-menu {
      padding: functions.get-rem(32px) functions.get-rem(24px);
      border: none;
      box-shadow: 0 4px 16px 0 #00000026;

      width: functions.get-rem(320px);

      a {
        height: functions.get-rem(40px) !important;
      }
    }

    .dropdown-item {
      padding: 0 4px !important;
    }
  }

  &.date {
    :global {
      .dropdown-menu {
        width: functions.get-rem(625px);
        height: functions.get-rem(560px);

        .overflow-auto {
          max-height: 100% !important;
          overflow: hidden !important;
        }

        a {
          height: functions.get-rem(124px) !important;
          margin-bottom: 40px;

          &:active,
          &:focus,
          &:hover {
            color: variables.$foreground-high;
            outline: none !important;
            background-color: variables.$surface-1 !important;
          }
        }
      }
    }
  }

  &.statuses {
    :global {
      .dropdown-menu {
        height: functions.get-rem(440px);

        .overflow-auto {
          max-height: 100% !important;
          overflow: hidden !important;
        }
      }
    }
  }
}

#toggle {
  height: functions.get-rem(36px);
  outline: none !important;
  border-radius: 8px;
  padding-right: 12px;
  border: 1px solid variables.$stroke-medium !important;
  color: variables.$foreground-medium;

  &.active,
  &:active,
  &:focus {
    border: 1px solid variables.$primary-1 !important;
  }
}

.open {
  transform: rotate(180deg);
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: functions.get-rem(20px);
  height: functions.get-rem(20px);
  border-radius: 50%;
  margin-right: 6px;
  color: variables.$surface-1;
  background-color: variables.$primary-1;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: variables.$stroke-medium;
}
