@use "scss/variables";

.blueBackground {
  background-color: variables.$information-050;
  border: 1px solid variables.$information-300;
}

.container {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%) !important;
  z-index: 1000;
}
