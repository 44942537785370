@use "scss/variables";
@use "scss/functions";

.table {
  margin-bottom: 0!important;
  &.withHover {
    tr {
      &:hover {
        td {
          background: variables.$additional-3-400 !important;
          cursor: pointer;
        }
      }
    }
  }

  td {
    color: variables.$foreground-high !important;
    font-size: functions.get-rem(14px);
    line-height: 1.3;
  }

  thead {
    span {
      font-weight: 400;
      user-select: none;
      color: variables.$foreground-medium;
      white-space: break-spaces;
    }

    .sorting {
      font-size: 14px;
      margin-left: 4px;
    }

    tr {
      th {
        padding: 0 16px;
        height: 40px;
        vertical-align: middle;
        border-top: 1px solid variables.$stroke-medium;

        &:first-child {
          padding: 0 24px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0 16px;
        height: 64px;
        vertical-align: middle;

        &:first-child {
          padding: 0 24px;
        }
      }
    }
  }

  :global {
    .right {
      justify-content: right;
      text-align: right;
    }
  }
}
.table-container {
  width: 100%;
  overflow-x: auto;
  font-variant-numeric: tabular-nums !important;

  &::-webkit-scrollbar {
    height: 5px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background: variables.$surface-1;
    border-radius: 5px;

  }

  &::-webkit-scrollbar-thumb {
    background: #d8c3ef;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: variables.$primary-1;
  }
}

.scrollable-table {
  border-collapse: collapse;
  width: inherit;
}

.scrollable-table th, .scrollable-table td {
  white-space: nowrap;
}

.scrollable-table th:first-child, .scrollable-table td:first-child,
.scrollable-table th:last-child, .scrollable-table td:last-child {
  position: sticky;
  z-index: 1;
  padding: 0;
}

.scrollable-table th:first-child, .scrollable-table td:first-child {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background-color: variables.$stroke-medium;
  }
}

.scrollable-table th:last-child, .scrollable-table td:last-child {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background-color: variables.$stroke-medium;
  }
}


.scrollable-table th:first-child, .scrollable-table td:first-child {
  left: 0;
}

.scrollable-table th:last-child, .scrollable-table td:last-child {
  right: 0;
}
