@use "scss/variables";
@use "scss/functions";

.toggle-button {
  z-index: 3 !important;

  button {
    font-size: 14px;
    height: functions.get-rem(32px);
    color: variables.$foreground-medium !important;
    background-color: variables.$surface-1 !important;

    &:active {
      background-color: variables.$background-2 !important;
    }
  }

  :global {
    .dropdown-menu {
      min-width: 150px !important;
      max-width: 150px !important;
      width: 150px !important;
    }
  }
}

.export-btn,
.context-button {
  color: variables.$foreground-medium !important;
}

.context-button {
  &:focus,
  &:active {
    border: none !important;
    outline: none !important;
  }
}
.switch {
  input:checked {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3e%3crect width=%2724%27 height=%2724%27 rx=%2712%27 fill=%27%23fff%27 /%3e%3c/svg%3e") !important;
  }
}

.date-picker-container {
  border-radius: 8px;
  width: max-content;
  height: functions.get-rem(40px);
  display: flex;
  align-items: center;
  background-color: variables.$element-2;
  color: variables.$foreground-low;

  input {
    background-color: variables.$element-2;
    border: none;
  }

  :global {
    .react-datepicker-wrapper {
      width: 190px !important;
    }
  }

  &.today {
    :global {
      .react-datepicker-wrapper {
        width: 100px !important;
      }
    }
  }
}
