@use "scss/variables";
@use "scss/functions";

.overview-details {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 16px;

  .item {
    position: relative;
    border-radius: 4px;
    background-color: variables.$surface-1;
    flex: 1 0 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    align-self: stretch;
  }

  .value {
    display: flex;
    align-items: center;
    color: variables.$primary-1;

    div {
      font-size: 20px;
      margin-left: 4px;
      color: variables.$primary-1 !important;
    }
  }
}