@use "scss/variables";

.table {
  border: 1px solid variables.$stroke-medium;
  border-radius: 8px;
  border-collapse: separate;

  td {
    vertical-align: middle;
    border: none !important;
  }
}

.avatar {
  padding: 0;
  width: 36px !important;
  min-width: 36px !important;
  height: 36px !important;
}
