@use "scss/variables";
@use "scss/functions";


.sharePriceBlocks {
  .block {
    display: flex;
    padding: 24px 24px 0 24px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 4px;
    background: #FFF;
  }
  .row {
    border-bottom: 1px solid variables.$stroke-medium;
    &:last-child {
      border-bottom: none;
    }
  }
}
.blockBorder {
    border-bottom: 1px solid variables.$foreground-low;
}