.wrap {
  .spinner {
    animation:spin 1s linear infinite;
    font-size: 168px;
    color: #982979;
    width: 168px;
  }
  @keyframes spin { 100% { transform:rotate(360deg); } }

  .container {
    margin: 0 auto;
    margin-top: 128px;
    width: 168px;
  }
}