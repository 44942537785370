@use "scss/variables";
@use "scss/functions";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: variables.$foreground-high;
}

.logo {
  width: functions.get-rem(28px);
  height: functions.get-rem(28px);

  [fill] {
    fill: currentColor;
  }

  [stroke] {
    stroke: currentColor;
  }
}

.close-button {
  padding: 0 !important;
  width: functions.get-rem(48px);
  height: functions.get-rem(48px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  border: unset;
  background-color: transparent;
  -webkit-appearance: none;
}
