@use "scss/functions";
@use "scss/variables";

.container {
  width: 100%;
  border-radius: 8px;
  background-color: variables.$surface-1;
  padding: functions.get-rem(72px) functions.get-rem(64px);
}

.product-info {
  border-radius: 8px;
  background-color: variables.$element-2;
  padding: functions.get-rem(24px) functions.get-rem(40px);
}

.link {
  text-decoration: underline;
  color: variables.$primary-1;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: variables.$stroke-medium;
}

.feature {
  display: flex;
  align-items: center;
  color: variables.$foreground-medium;

  svg {
    flex-shrink: 0;
    font-size: 24px;
    margin-right: 8px;
    color: variables.$positive-500 !important;
  }
}
