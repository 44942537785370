@use "scss/variables";
@use "scss/functions";

.wrapper {
  //max-width: 628px!important;
  //padding: 24px 24px 48px 40px!important;

  :global {
    .offcanvas-body {
      padding-right: 12px !important;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .modalTitle {
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 40px;
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
  }

  .pointContainer {
    .point {
      display: flex;
      padding-bottom: 40px;
      position: relative;

      .circle {
        display: flex;
        flex-shrink: 0;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        width: functions.get-rem(48px);
        height: functions.get-rem(48px);
        background: variables.$positive-050;

        &.inProgress {
          background: variables.$warning-050;
        }

        &.decline {
          background: variables.$critical-050;
        }
      }

      &:after {
        content: "";
        background-color: variables.$stroke-medium;
        position: absolute;
        width: 1px;
        height: calc(100% - 48px - 12px);
        bottom: -10px;
        left: 25px;
        display: block;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}
