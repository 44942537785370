@use '../../../../scss/variables';
@use '../../../../scss/functions';

$main-font-size: 14px;

.card {
  $padding-x: functions.get-em(24px, $main-font-size);
  $padding-y: functions.get-em(24px, $main-font-size);

  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: functions.get-rem($main-font-size);
  background-color: variables.$element-1;

  &:global {
    &.dropdown-toggle,
    &.dropdown-item {
      white-space: normal;
      color: variables.$foreground-high;
      padding: $padding-y $padding-x;
      cursor: pointer;
    }

    &.dropdown-toggle {
      border-radius: functions.get-em(variables.$border-radius, $main-font-size);
      box-shadow:
        0 functions.get-em(2px, $main-font-size) functions.get-em(2px, $main-font-size) rgba(variables.$foreground-high, .15),
        0 0 functions.get-em(4px, $main-font-size) rgba(variables.$foreground-high, .1),
        0 functions.get-em(2px, $main-font-size) functions.get-em(8px, $main-font-size) rgba(variables.$foreground-high, .15);
      padding-right: 0;

      &::after {
        flex-shrink: 0;
        width: functions.get-em(88px, $main-font-size);
        height: auto;
        border-left: 1px solid variables.$foreground-high;
        background-image: url('arrow-down.svg');
        background-size: functions.get-em(24px, $main-font-size);
        background-position: center;
        padding: 0;
        margin-left: functions.get-em(40px, $main-font-size);
      }
    }

    &.dropdown-item {
      &:not(:last-child) {
        border-bottom: 1px solid variables.$foreground-medium;
      }
    }
  }
}

.title {
  display: block;
  font-size: functions.get-em(18px, $main-font-size);
  line-height: variables.$line-height-base;
  font-weight: variables.$font-weight-bold;

  span {
    font-weight: variables.$font-weight-regular;
    color: variables.$foreground-medium;
  }
}

.description {
  line-height: variables.$line-height-lg;
  margin-top: functions.get-em(8px, $main-font-size);

  &:empty {
    display: none;
  }
}
