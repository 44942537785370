@use "scss/variables";
@use "scss/functions";

@use "../PoolItem.module";

.header {
  display: flex;
  height: functions.get-rem(80px);
  vertical-align: middle;
  align-items: center;
  padding: 0 32px;
  cursor: pointer;

  :global {
    .accordion-button {
      justify-content: space-between;
      border: none;
      border-radius: 0 !important;
      background-color: transparent;
      padding: 0 functions.get-em(30px, PoolItem.$pool-item-font-size) 0
        functions.get-em(32px, PoolItem.$pool-item-font-size);

      &:not(.collapsed) {
        color: #000;
        background-color: transparent;
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }

      &:not(.collapsed) {
        &::after {
          transform: rotate(-90deg);
        }
      }

      &::after {
        display: none;
      }
    }
  }

  .draft {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(36px);
    height: functions.get-rem(36px);
    background-color: variables.$warning-050;

    svg {
      path {
        opacity: 1;
        stroke: variables.$warning-700;
      }
    }
  }
}

.progress-chart {
  margin-left: auto;
  width: functions.get-rem(240px);

  &.hidden {
    opacity: 0;
  }
}

.action-btn {
  padding: 0 !important;
  width: functions.get-rem(32px) !important;
  height: functions.get-rem(32px) !important;

  svg {
    opacity: 1;
    width: functions.get-rem(24px) !important;
    height: functions.get-rem(24px) !important;
  }
}

.shrinkText {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
