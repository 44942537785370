@use "src/scss/variables";
@use "src/scss/functions";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: functions.get-rem(8px);

  .title {
    display: flex;
    align-items: center;
  }
}
