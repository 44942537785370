@use "scss/variables";
@use "scss/functions";

.container {
  border-radius: 8px;
  color: variables.$foreground-medium;
  background-color: variables.$element-2;
}

.info-section {
  width: 45%;

  .content {
    border-radius: 8px;
    padding: functions.get-rem(24px);
    border: 1px solid variables.$stroke-medium;

    div {
      div {
        &:last-child {
          color: variables.$foreground-low;
        }
      }
    }
  }
}

.icon {
  width: functions.get-rem(40px);
  height: functions.get-rem(40px);

  path {
    stroke: variables.$stroke-high;
  }
}

.document-item {
  border-radius: 8px;
  height: functions.get-rem(56px);
  background-color: variables.$surface-1;

  svg {
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
  }
}

.documents {
  border-radius: 8px;
  border: 1px solid variables.$stroke-medium;
}

.single-document {
  cursor: pointer;
  flex-basis: 30%;

  &:hover {
    text-decoration: underline;
  }
}
