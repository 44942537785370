@use "scss/variables";

.width {
  max-width: 1280px !important;
}

.circleSize {
  height: 32px;
  min-width: 32px;
}

.imageContainer {
  height: 360px;
}
