@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.fields-wrap {
  background-color: variables.$element-3;
  border-radius: functions.get-rem(variables.$border-radius);

  :global {
    .paragraph-m {
      color: variables.$foreground-high;
    }
  }
}

.vestingType {
  background-color: variables.$surface-1;
  margin-top: functions.get-rem(16px);
  border-radius: 4px;
  border: 1px solid variables.$stroke-high;
  padding: 20px;
  padding-right: 54px;
  &.selected {
    border: 1px solid variables.$primary-1;
  }
  .vestingTypeRadio {

    display: flex;
    gap: 10px;
    input {
      flex-shrink: 0
    }
  }
}
