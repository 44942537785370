@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

$background-color: variables.$surface-1;
$border-radius: functions.get-rem(variables.$border-radius);

.content {
  position: relative;
  z-index: 3;
  border-radius: $border-radius;
  background-color: $background-color;
  padding: functions.get-rem(80px) functions.get-rem(88px);
}
