@use "scss/variables";
@use "scss/functions";

.container {
  margin: functions.get-rem(72px) auto functions.get-rem(168px) auto;
  color: variables.$foreground-high;
  max-width: functions.get-rem(1064px);
  background-color: variables.$surface-1;
  padding: functions.get-rem(102px) functions.get-rem(146px);

  .info {
    cursor: pointer;
    display: inline;
    position: relative;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    color: variables.$information-500;
  }
}

.head {
  cursor: pointer;
  background-color: variables.$surface-1;
}

.payment-container {
  position: relative;
  border-radius: 8px;
  background-color: variables.$surface-1;
  padding: functions.get-rem(24px) functions.get-rem(24px) functions.get-rem(24px) functions.get-rem(72px);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.15);

  .title {
    display: flex;
    align-items: center;
  }

  .point {
    position: absolute;
    left: functions.get-rem(24px);
  }

  .subtitle {
    color: variables.$foreground-medium;
  }
}
