@use "scss/variables";
@use "scss/functions";

.modal {
  background-color: rgba(255, 255, 255, 0);
  :global {
    .modal-content {
      border: none;
      display: flex;
      pointer-events: none;
      background-color: transparent;
    }
  }
}

.container {
  padding: 0;
  position: relative;
  border-radius: 8px;
  align-self: center;
  pointer-events: initial;
  width: functions.get-rem(476px);
  background-color: variables.$surface-1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 6px 12px rgba(0, 0, 0, 0.15);
}

.close-icon {
  top: 8px;
  right: 8px;
  cursor: pointer;
  position: absolute;
  width: functions.get-rem(24px);
  height: functions.get-rem(24px);

  path {
    fill: variables.$foreground-medium;
  }
}

.delete-btn {
  border-color: variables.$critical-500 !important;
  background-color: variables.$critical-500 !important;
  color: variables.$element-1;

  &:hover,
  &:focus {
    border-color: variables.$critical-500 !important;
    background-color: darken(variables.$critical-500, 10) !important;
  }
}
