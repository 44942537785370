@use "scss/variables";
@use "scss/functions";

.wrap {

  .container {
    width: 1072px;
    margin: 0 auto;
    position: relative;

    .donut {
      position: absolute;
      background-image: url("./Congratulations.png");
      background-repeat: no-repeat;
      height: calc(100vh - 108px);
      width: 100%;
      pointer-events: none;
    }

    .success {
      margin: 0 auto;
      background: white;
      width: 540px;
      padding: 40px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;



      .circle {
        display: flex;
        width: 112px;
        height: 112px;
        padding: 22.4px;
        align-items: center;
        gap: 22.4px;
        aspect-ratio: 1/1;
        border-radius: 268px;
        background: variables.$positive-050 ;
        color: variables.$positive-900;
      }
    }
  }
}