@use "scss/variables";
@use "scss/functions";
@use "scss/bootstrap";

.datepicker-container {
  width: 100%;

  input.is-invalid + div {
    svg {
      display: none;
    }
  }
  &.withLabel {
    :global {
      .react-datepicker {
        &__close-icon {
          padding: 24px 8px 0 0;
        }
      }
    }
  }

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker {
      width: 100%;
      border-color: variables.$stroke-high;

      &__triangle {
        display: none !important;
      }

      &-popper {
        z-index: 5;
      }

      &-popper[data-placement^="bottom"] {
        padding-top: map-get(bootstrap.$spacers, half);
      }

      &-popper[data-placement^="top"] {
        padding-bottom: map-get(bootstrap.$spacers, half);
      }

      &__header {
        border-bottom: none;
        background-color: variables.$surface-1 !important;
      }

      &__day {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }

      &__day-name {
        opacity: 70%;
        font-size: 14px;
        font-family: "Inter", sans-serif;
        color: variables.$foreground-low !important;
      }

      &__day:hover {
        border-radius: 50%;
        color: variables.$element-1;
        background-color: variables.$primary-1;
      }

      &__day--today {
        font-weight: 400;
        border-radius: 50%;
        background-color: variables.$element-3;
      }

      &__day--selected {
        border-radius: 50%;
        color: variables.$element-1;
        background-color: variables.$primary-1;
      }

      &__day--outside-month {
        color: variables.$foreground-low !important;
      }

      &__day--disabled {
        color: variables.$foreground-disabled;
        text-decoration: line-through;
      }

      &__day--keyboard-selected,
      &__month-text--keyboard-selected,
      &__quarter-text--keyboard-selected,
      &__year-text--keyboard-selected {
        border-radius: 50%;
        color: variables.$element-1;
        background-color: variables.$primary-1;
      }

      &__close-icon {
        //padding: 24px 8px 0 0;
        ~ .flex-column {
          display: none !important;
        }
        &::after {
          height: 20px;
          width: 20px;
          font-size: 24px;
          background-color: transparent;
          color: variables.$foreground-high;
        }
      }

      &__time-list-item:hover,
      &__time-list-item--selected {
        color: variables.$element-1;
        background-color: variables.$primary-1 !important;
      }

      &__month {
        margin-top: 0 !important;
      }
    }
  }
}

.datePikerHeader {
  padding-bottom: 8px;
  padding-top: 8px;
  .dateTitle {
    font-size: functions.get-rem(14px);
    font-weight: 500;
    color: variables.$foreground-high;
  }
  button {
    span {
        font-size: functions.get-rem(16px);
        color: variables.$foreground-medium;
    }
    &:focus {
      outline: none!important;
    }
  }
}

