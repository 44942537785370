@use "scss/variables";

.shareholders-table {
  table {
    border-collapse: separate;
    border-spacing: 0 4px !important;
  }

  &-head {
    height: 48px;
    vertical-align: middle;
    border: none !important;
    color: variables.$foreground-medium;

    th {
      border: none !important;
      font-weight: 500 !important;
      background-color: variables.$secondary-1 !important;
    }

    th:first-child {
      border-radius: 8px 0 0 8px;
    }

    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  &-body {
    border: none !important;
    tr {
      height: 56px;
      cursor: pointer;

      td {
        padding: 0;
        vertical-align: middle;
        border: none !important;
        background-color: variables.$secondary-1 !important;
      }

      td:first-child {
        border-radius: 8px 0 0 8px !important;
      }

      td:last-child {
        border-radius: 0 8px 8px 0 !important;
      }
    }

    &-icon {
      transition: all 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
