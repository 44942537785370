@use "scss/variables";
@use "scss/functions";

.cap-table {
  border-radius: 4px;
  padding: functions.get-rem(24px);
  background-color: variables.$surface-1;

  &.isFinalize {
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgba(185, 128, 51, 1)' stroke-width='4' stroke-dasharray='12%2c 28' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .finalize {
    display: flex;
    border-radius: 4px;
    justify-content: space-between;
    background-color: #fff3e8;
    padding: functions.get-rem(40px) functions.get-rem(78px);

    .actions {
      display: flex;
      flex-shrink: 0;
      text-align: right;
      flex-direction: column;

      button {
        height: functions.get-rem(32px);
      }
    }
  }
}

.charts {
  margin-bottom: functions.get-rem(36px);
}

.group-by {
  margin-bottom: functions.get-rem(36px);
}

.toggle-actions {
  background-color: variables.$background-2;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(125px);
  }

  &.no-subcription {
    :first-child {
      font-weight: 500;
      color: variables.$primary-1 !important;
      background-color: variables.$surface-1 !important;
    }

    :last-child {
      color: variables.$foreground-disabled !important;
      background-color: initial !important;
    }
  }
}

/*.table-container {
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 5px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background: variables.$surface-1;
    border-radius: 5px;

  }

  &::-webkit-scrollbar-thumb {
    background: #d8c3ef;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: variables.$primary-1;
  }
}

.scrollable-table {
  border-collapse: collapse;
  width: inherit;
}

.scrollable-table th, .scrollable-table td {
  white-space: nowrap;
}

.scrollable-table th:first-child, .scrollable-table td:first-child{
  position: sticky;
  //background-color: #f5f5f5;
  z-index: 1;
}

.scrollable-table th:first-child, .scrollable-table td:first-child {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background-color: variables.$stroke-medium;
  }
}*/

.scrollable-table th:first-child,
.scrollable-table td:first-child {
  left: 0;
}

.dialog-modal {
  max-width: functions.get-rem(800px) !important;
}

.close-btn {
  top: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
}
