@use "scss/functions";
@use "scss/variables";

.actions {
  display: flex;
  align-items: center;
  padding: functions.get-rem(10px) 0;

  .dropdown {
    position: inherit;

    :global {
      .dropdown-menu {
        max-width: 100px;
      }

      .dropdown-item {
        white-space: break-spaces;
      }
    }

    span {
      cursor: pointer;
    }

    .iconButton {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: functions.get-rem(24px);
      height: functions.get-rem(24px);
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      color: variables.$foreground-high;
      font-size: 24px;

      &:hover {
        background: variables.$element-1;
      }

      &:focus {
        background: variables.$element-1;
      }

      &:active {
        background: variables.$element-1;
      }
    }
  }
}
