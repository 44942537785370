@use "scss/variables";
@use "scss/functions";

.portfolio {
  margin-top: functions.get-rem(24px) ;
  background: white;
  //width: 1000px;

  .subtitle {
    color: variables.$foreground-low;
    margin-top: 4px;
  }

  table {
    margin-bottom: 0 !important;
  }

  .invite {
    height: 64px;
    display: flex;
    background: variables.$additional-1-100;
    align-items: center;
  }

  .info {
    background: variables.$information-050;
    padding: functions.get-rem(24px);
    display: flex;

    &__icon {
      font-size: 24px;
      display: flex;
      color: variables.$information-700;
      margin-right: 1rem;
    }

    &__close {
      font-size: 16px;
      display: flex;
      color: variables.$foreground-medium;
      margin-left: auto;

      svg {
        cursor: pointer;
      }
    }
  }

  .value {
    display: flex;
    align-items: center;

    .tooltip {
      font-size: 16px;
      display: inline-flex;
      margin-right: 4px;
      cursor: pointer;
        color: variables.$foreground-low;
    }
  }
}

.content {
  color: variables.$foreground-medium !important;

  :global {
    .tooltip-inner {
      border: 1px solid variables.$stroke-high !important;
    }

    .tooltip-arrow:before {
      border-top-color: variables.$stroke-high !important;
    }
  }
}

.noRecord {
  display: flex;
  color: variables.$foreground-low;
  height: 232px;
  align-items: center;
  justify-content: center;
}

.noData {
  display: flex;
  align-items: center;
  color: variables.$foreground-low;

  &__icon {
    font-size: 16px;
    display: flex;
    cursor: pointer;
  }

  &__text {
    margin-left: functions.get-rem(4px);
    font-size: 14px;
    line-height: 1.3;
  }
}

.withData {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .profit {
    color: variables.$positive-700;
  }

  .loss {
    color: variables.$critical-500;
  }

  &__percentage {
    font-size: 14px;
  }

  &__icon {
    font-size: 20px;
    display: flex;
  }

  &__text {
    font-size: 14px;
    line-height: 1.3;
  }
}
