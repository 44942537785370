@use "scss/variables";
@use "scss/functions";

.table {
  border-collapse: collapse;

  thead {
    background-color: variables.$element-1;
  }

  th {
    padding: 8px 32px !important;
    font-weight: normal !important;
  }

  td {
    height: 64px !important;
    padding: 8px 32px !important;
    vertical-align: middle;
  }

  thead,
  tbody,
  th {
    border: none !important;
  }

  th {
    border-bottom: 1px solid variables.$stroke-medium !important;
  }

  tbody {
    tr:hover {
      background-color: variables.$element-1;
    }
  }
}

.row {
  cursor: pointer;

  td {
    border-bottom: 1px solid variables.$stroke-medium !important;
  }

  &:hover {
    td {
      background-color: variables.$additional-3-200 !important;
    }
  }

  &:last-child {
    td {
      border-bottom: none !important;
    }
  }
}

.tableContainer {
  background-color: variables.$surface-1;
  border: 1px solid variables.$stroke-medium;
  border-radius: 8px;
  padding-top: 8px;
}
