@use "src/scss/variables";
@use "src/scss/functions";

.wrap {
  padding: functions.get-rem(24px) 0;
  border-radius: functions.get-rem(8px);
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
  flex: 1 0 0;
  background: variables.$element-2;

  .icon {
    font-size: functions.get-rem(40px);
    color: variables.$foreground-medium;
    display: flex;
  }

  .title {
    font-size: functions.get-rem(20px);
    color: variables.$foreground-medium;
    font-weight: 700;
    line-height: 1.3;
  }

  .subtitle {
    font-size: functions.get-rem(14px);
    color: variables.$foreground-medium;
    font-weight: 400;
    line-height: 1.3;
  }
  &.success {
    .title {
        color: variables.$positive-700;
    }
    .subtitle {
        color: variables.$positive-700;
    }
  }
}