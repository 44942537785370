@use "scss/variables";
@use "scss/functions";
.pagination {
  display: flex;

  div {
    display: flex;
    margin-left: auto;
    p {
      color: variables.$foreground-medium;
    }

    svg {
      cursor: pointer;

      path {
        fill: variables.$foreground-medium;
      }
      &.disabled {
        cursor: not-allowed;

        path {
          fill: variables.$element-1;
        }
      }
    }
  }
}
