@use "scss/variables";
@use "scss/functions";

.terms {
  padding: 0 !important;
  color: variables.$primary-1 !important;
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
}
.privacy {
  color: variables.$foreground-medium;
  max-width: 580px;
  font-weight: 400;
  padding-left: 8px;
  line-height: 24px;
}
.wrap {
  background: variables.$surface-1;
  padding: functions.get-rem(64px);
  border-radius: 8px;
  .subTitle {
    color: variables.$foreground-medium;
    font-weight: 400;
  }
}
