.capTableItem {
  flex: 1;

  svg {
    path {
      stroke-width: 2.5px !important;
    }
  }
}

.chart {
  width: 82px;
}

.chartInfo {
  p {
    margin-bottom: 0;
  }
}

#icon {
  path {
    stroke-width: 1 !important;
  }
}
