@use "scss/variables";
.wrap {
  padding: 24px 12px;
  background-color: variables.$element-2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: variables.$positive-700;
  align-items: center;
}