@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";
@use "sass:math";

.gray-plate {
  border-radius: 8px;
  padding: functions.get-em(24px) functions.get-em(24px);
  background-color: variables.$element-1;
}

.white-plate {
  padding: functions.get-em(28px) functions.get-em(20px);
  background-color: variables.$element-1;
}

.dropdown-hint {
  color: variables.$foreground-high;
  opacity: 0.8;
}

.end-date {
  color: variables.$foreground-medium;
  font-style: italic;
}

.documents-paragraph {
  font-style: variables.$font-family-base;
  font-weight: variables.$font-weight-medium;
  font-size: variables.$paragraph-m;
  padding-right: 8px;
  margin-bottom: 0;
}

.documents-descriptions {
  color: variables.$foreground-medium;
}

.holding-company {
  input {
    margin-right: 8px;
  }
}

.vesting-field {
  input {
    max-width: 191px !important;
  }
}

/*.progress-section {
  div:last-child {
    div {
      background-color: variables.$information-500 !important;
    }
  }
}*/
