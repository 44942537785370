@use "scss/variables";
@use "scss/functions";

.collapsible-head {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  height: functions.get-rem(80px);
  background-color: variables.$surface-1;
  padding: 0 functions.get-rem(24px);

  :global {
    .card-header {
      div {
        display: flex;
        border-radius: 4px;
        align-items: center;
        height: functions.get-rem(80px);
      }
    }
  }
}
