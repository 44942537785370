@use "scss/variables";
@use "scss/functions";

.toggle {
  background: #FFFFFF;
  padding: functions.get-rem(18px) functions.get-rem(48px) functions.get-rem(18px) functions.get-rem(32px);
  cursor: pointer;
  position: relative;
  .icon {
    position: absolute;
    top: calc(50% - 12px);
    right: 24px;
  }
}
.collapse {
  background: #FFFFFF;
}