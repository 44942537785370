@use "scss/variables";
@use "scss/functions";

.wizard {
  max-width: functions.get-rem(800px);
}

.container {
  padding: functions.get-rem(40px) functions.get-rem(58px);
}

.pop-up {
  position: absolute;
  border-top-right-radius: 8px;
  top: functions.get-rem(40px);
  width: functions.get-rem(225px);
  border-bottom-right-radius: 8px;
  right: functions.get-rem(-225px);
  background-color: variables.$surface-1;
  padding: functions.get-rem(28px) functions.get-rem(24px);

  &-open {
    top: 50%;
    right: 8px;
    width: 8px;
    height: 100px;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    transform: translateY(-50%);
    background-color: variables.$surface-1;
  }
}

.video {
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: functions.get-rem(107px);
  background-color: variables.$foreground-medium;

  div {
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(44px);
    height: functions.get-rem(44px);
    background-color: variables.$surface-1;

    svg {
      transform: rotate(-90deg) scaleY(1.4);

      path {
        fill: variables.$foreground-medium;
      }
    }
  }
}

.block-wrapper {
  div {
    div {
      div {
        input {
          width: 100% !important;
        }
      }
    }
  }
}

.one-off-optins {
  input {
    width: 235px !important;
  }
  .dropdown {
    width: 150px !important;
  }
}
