@use "scss/functions";
@use "scss/variables";

.container {
  padding: functions.get-rem(24px);
  background-color: variables.$surface-1;
}

.content {
  text-align: center;
  border-radius: 4px;
  background-color: variables.$surface-1;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.contact-form {
  border-radius: 8px;
  background-color: variables.$element-2;
}

.contacts-counter {
  font-size: 12px;
  margin-left: 6px;
  padding: 2px 6px;
  border-radius: 8px;
  background-color: variables.$element-1;
}
