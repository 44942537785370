@use "scss/variables";
@use "scss/functions";

.dropdown {
  position: relative;

  input {
    border: 1px solid variables.$stroke-high !important;
    caret-color: transparent;
    cursor: pointer;
  }
  &.isSearchable {
    input {
      cursor: text;
      caret-color: #5b0042;
    }
  }

  &.disabled {
    * {
      cursor: not-allowed !important;
    }
  }
  :global {
    input.is-invalid {
      //box-shadow: 0 0 0 1px variables.$warning-500 !important;
      border: 2px solid variables.$critical-700 !important;
    }
    /*input.is-invalid + div {
      svg {
        display: none;
      }
    }*/
  }
}

.arrow-icon {
  transition: all 0.3s ease;

  &_rotate {
    transition: all 0.3s ease;
    transform: rotate(180deg);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.dropdown-button-container {
  margin-top: 4px !important;
  min-width: functions.get-rem(300px) !important;
  max-width: functions.get-rem(350px) !important;
  border: 1px solid variables.$primary-1 !important;
}

.dropdown-button-item {
  &.selected {
    font-weight: 700;
    color: variables.$primary-1;
  }
}

.dropdown-button-menu {
  max-height: functions.get-rem(260px);
  overflow: auto !important;

  a {
    border-radius: 0;

    &:hover {
      background-color: variables.$element-3;
    }
  }
}

.menu {
  z-index: 999 !important;
  margin-top: 4px !important;
  transform-origin: top center;
  //animation: growDown 300ms ease-in-out forwards;

  :global {
    .dropdown-item {
      white-space: pre-wrap!important;
      min-height: functions.get-rem(40px);

      &.active {
        color: variables.$primary-1 !important;
        background-color: variables.$surface-1 !important;
      }

      &:focus {
        background-color: variables.$element-3 !important;
      }

      &:hover {
        background-color: variables.$element-3 !important;
      }
    }
  }

  &_align-right {
    z-index: 999 !important;
    @extend .menu;
    position: absolute;
    right: 0;
  }
}
.menuScroll {
  max-height: 260px;

  &::-webkit-scrollbar {
    width: 8px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background: variables.$surface-1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8c3ef;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: variables.$primary-1;
  }
}
