@use "../../../../scss/variables";
@use "../../../../scss/functions";

.container {
  overflow: hidden;
  border-radius: 8px;

  &.open {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &.active {
    box-shadow: none;
    border: 2px solid variables.$positive-500;
  }

  .content {
    margin-top: 6px;
    padding: 0 functions.get-rem(24px) functions.get-rem(32px) functions.get-rem(72px);
  }
}

.head {
  cursor: pointer;
  position: relative;
  background-color: variables.$surface-1;
  padding: functions.get-rem(24px) functions.get-rem(24px) functions.get-rem(19px) functions.get-rem(72px);

  &.open {
    background-color: variables.$surface-1;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .point {
    position: absolute;
    left: functions.get-rem(24px);
  }

  .subtitle {
    color: variables.$foreground-medium;
  }
}
