@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

@import './fonts/canela-text/fonts';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

@import 'bootstrap';

@import '~bootstrap/scss/bootstrap';

@import 'custom';

@import 'icons';

@import 'typography';
