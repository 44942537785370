@use "src/scss/variables";
@use "src/scss/functions";

.overview-head {
  padding: 0 functions.get-rem(150px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}

.no-data-block {
  display: flex;
  align-items: center;
  height: functions.get-rem(225px);
  background-color: variables.$element-1;
}

.block-icon {
  width: 90px;
  margin: 0 functions.get-rem(70px) 0 functions.get-rem(60px);
  svg {
    width: 100%;
    height: 100%;
  }
}

.info-block {
  display: flex;
  flex-direction: column;
}

.header {
  $font-size: 24px;
  font-size: functions.get-rem($font-size);
  font-weight: variables.$font-weight-medium;
  color: variables.$foreground-high;
  padding: 0 functions.get-em(24px, $font-size)
    functions.get-em(22px, $font-size) 0;
}

.body {
  max-width: 430px;
  $font-size: 16px;
  font-weight: variables.$font-weight-regular;
  font-size: functions.get-rem($font-size);
  color: variables.$foreground-high;
}

.links {
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 3px;
}

.plan-head {
  padding: 0 functions.get-rem(150px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}

.plan-block {
  display: flex;
  flex-direction: column;
  max-width: 345px;
}

.plan-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: functions.get-rem(175px);
  background-color: variables.$element-1;
  padding: 0 functions.get-rem(60px);
}

.plan-header {
  $font-size: 24px;
  font-size: functions.get-rem($font-size);
  font-weight: variables.$font-weight-bold;
  color: variables.$foreground-high;
  padding: 0 functions.get-em(24px, $font-size)
    functions.get-em(12px, $font-size) 0;
}

.plan-body {
  max-width: 470px;
  $font-size: 20px;
  font-weight: variables.$font-weight-medium;
  font-size: functions.get-rem($font-size);
  color: variables.$foreground-high;
}
