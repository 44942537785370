@use "scss/variables";

.info-alert {
  padding: 16px;
  border-radius: 8px;

  &-type-info {
    background-color: variables.$information-050;
    & > svg {
      fill: #3ba3dc;
    }
  }
  &-type-success {
    background-color: #eefff6;
    color: #141414;
    & > svg {
      fill: #141414;
    }
  }
  &-type-warning {
    background-color: variables.$warning-050;

    & > svg {
      fill: variables.$warning-500;
    }
  }

  &-type-neutral {
    background-color: variables.$element-2;
  }

  &-type-critical {
    background-color: variables.$critical-050;

    & > svg {
      fill: variables.$critical-900;
    }
  }
}
