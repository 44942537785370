@use "scss/variables";
@use "scss/functions";

.container {
  :global {
    .modal-content {
      background-color: variables.$surface-1;
      padding: functions.get-rem(56px) functions.get-rem(72px);
    }
  }
}
