@use "scss/variables";
@use "scss/functions";

.wrapper {
  :global {
    .offcanvas-header {
      padding: functions.get-rem(48px) functions.get-rem(56px) 0 0;

      .btn-close {
        opacity: 1;
        transform: scale(1.2);
        width: functions.get-rem(28px);
        height: functions.get-rem(28px);
      }
    }
  }

  .off-canvas {
    //padding: 0 functions.get-rem(70px);

    .chart-legend {
      display: flex;
      color: variables.$foreground-medium;

      .circle {
        border-radius: 50%;
        width: functions.get-rem(12px);
        height: functions.get-rem(12px);
      }

      div {
        p {
          opacity: 0.8;
        }
      }
    }

    .hl {
      width: 100%;
      height: 1px;
      background-color: variables.$surface-1;
    }

    .info {
      padding: functions.get-rem(24px);
      background-color: variables.$element-2;

      .row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
