@use "scss/variables";
@use "scss/bootstrap";
@use "scss/functions";

.tag {
  font-weight: 400;
  border-radius: variables.$border-radius-lg;
}

.s {
  font-size: variables.$ui-s;
  padding: functions.get-rem(2px) functions.get-rem(12px);
}

.m {
  font-size: variables.$ui-s;
  padding: functions.get-rem(4px) functions.get-rem(12px);
}

.access {
  color: variables.$foreground-medium;
  background-color: variables.$additional-3-550;
}

.description {
  color: variables.$foreground-high;
  background-color: variables.$additional-4-500;
}

.declined {
  background-color: variables.$critical-050;
  color: variables.$critical-900;
}

.information {
  background-color: variables.$information-050;
  color: variables.$information-900;
}

.draft {
  background-color: variables.$warning-050;
  color: variables.$warning-900;
}

.expired {
  background-color: variables.$element-2;
  color: variables.$foreground-medium;
}

.complete {
  background-color: variables.$positive-050;
  color: variables.$positive-900;
}

.closed {
  background-color: variables.$element-3;
  color: variables.$foreground-medium;
}

//.gray {
//  background-color: variables.$
//}

.icon {
  > svg {
    color: inherit;

    [fill] {
      fill: currentColor;
    }

    [stroke] {
      stroke: currentColor;
    }
  }
}
