@use "../../../../../../../../../scss/bootstrap";
@use "../../../../../../../../../scss/variables";
@use "../../../../../../../../../scss/functions";

.item {
  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  flex-grow: 1;
  background-color: variables.$surface-1;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid variables.$element-3;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: variables.$element-2;
}

.header-item {
  display: flex;
  align-items: flex-end;

  > svg {
    $size: map-get(bootstrap.$spacers, 3);

    flex-shrink: 0;
    display: block;
    width: $size;
    height: $size;
    margin-right: map-get(bootstrap.$spacers, 1);
  }
}
