@use "scss/variables";
@use "scss/functions";

.content {
  position: relative;
  margin-left: 24px;
  .container {
    background-color: variables.$surface-1;
    padding: functions.get-rem(32px) functions.get-rem(40px);
    max-width: 920px;

    .welcome {
      font-size: functions.get-rem(20px);
      line-height: functions.get-rem(36px);
      font-weight: 700;
      margin-bottom: 14px;
    }

    .description {
      line-height: functions.get-rem(22px);
      font-size: functions.get-rem(14px);
      justify-content: space-between;

      p {
        max-width: functions.get-rem(370px);
      }
    }

    .congrats {
      margin-top: 1rem;
      margin-left: 90px;
      .congratsDescription {
        max-width: 565px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: variables.$foreground-medium;
      }
    }
  }
}
.sidebar {
  width: 286px;
  .content {
    background: variables.$surface-1;
    min-height: 326px;
  }
  .footer {
    background: rgba(208, 208, 209, 0.35);
    padding: 14px 24px 14px 32px;
    font-size: functions.get-rem(14px);
    line-height: functions.get-rem(18px);
    display: flex;
      span {
        white-space: nowrap;
      }
      button {
        padding: 0;
      }
  }
}
.guide {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 6px;
  div:first-child {
    position: absolute;
    top: -3px;
    left: -3px;
  }
}
