@use 'scss/variables';

.account {
  background-color: white;
  padding: variables.$page-content-padding;

  &-link {
    color: black;
  }

  &-general {
    display: flex;

    &-content {
      width: 100%;
      margin-left: 20px;
    }

    &-dot {
      width: 6px;
      height: 6px;
      margin-left: 5px;
      border-radius: 50%;
    }

    &-hl {
      width: 1px;
      height: 100%;
      background-color: #d0d0d1;
    }
  }

  &-vl {
    width: 100%;
    height: 1px;
    background-color: #d0d0d1;
  }

  &-summary {
    flex: 1;
    display: flex;
    justify-content: space-between;

    &-count {
      width: 20px;
      height: 20px;
      display: flex;
      margin-left: 8px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #ececed;
    }
  }
}
