@use "scss/variables";
@use "scss/functions";

:global {
  .is-invalid {
    border: 2px solid variables.$critical-500 !important;
  }
}

.borders {
  svg {
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);

    circle {
      stroke: variables.$foreground-medium;
    }
  }

  border: 1px solid variables.$stroke-high;
  border-radius: 4px;

  .info {
    overflow: hidden;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;
    text-overflow: ellipsis;
  }

  .isGreen {
    :global {
      .form-check-input:checked {
        background-color: variables.$positive-500;
        border-color: variables.$positive-500;
      }
    }

    label {
      color: variables.$positive-500 !important;

      a {
        color: variables.$positive-500 !important;
      }
    }
  }

  label {
    color: variables.$foreground-medium;

    a {
      color: variables.$foreground-medium;
    }
  }
}

.documents-single {
  display: flex;
  align-items: center;
  height: functions.get-rem(40px);
  color: variables.$primary-1;

  a {
    color: variables.$primary-1;
  }

  .icon {
    path {
      stroke-width: 2px;
      stroke: variables.$primary-1;
    }
  }
}
