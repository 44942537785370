@use "scss/variables";
@use "scss/functions";

.wrap {
  display: flex;
  justify-content: space-between;

  .verticalLine {
    width: 1px;
    height: 100%;
    background-color: variables.$stroke-medium;
    //margin-right: 16px;
  }

  .circle {
    display: flex;
    align-items: center;
    color: variables.$foreground-medium;
    &:before {
      content: '';
      width: 8px;
      height: 8px;
      display: inline-block;
      background-color: variables.$positive-500;
      margin-right: 8px;
      border-radius: 50%;
    }
    &.dashed {
      &:before {
        border: 1px dashed variables.$additional-7-600;
        background-color: transparent;
      }
    }
  }
  .progress {

    height: 8px;
    background-color: variables.$additional-1-200!important;
    :global {
      .bg-success {
        background-color: variables.$positive-500 !important;
      }
    }
  }
}

.chart {
  width: 140px;
  height: 140px;
  position: relative;

  .container {
    position: absolute;
    width: 140px;
    height: 140px;
    top: -10px;
    left: 10px;
  }

  svg {
    margin-left: -8px;

    path {
      &:last-child {
        stroke-width: 1px;
        stroke-dasharray: 4;
        transform: scale(0.995);
        stroke: variables.$additional-7-500;
      }
    }
  }
}