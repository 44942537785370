@use "scss/functions";
@use "scss/variables";

.wrap {
  background: variables.$surface-1;
  width: functions.get-rem(900px);
  padding: functions.get-rem(16px);
  border-radius: functions.get-rem(8px);
  margin: 0 auto;
}
