@use "scss/variables";
@use "scss/functions";

.container {
  background-color: variables.$surface-1;
}

.circle {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: functions.get-rem(96px);
  height: functions.get-rem(96px);
  border-radius: 50%;
  background-color: variables.$positive-050;
  color: variables.$positive-900;
}
