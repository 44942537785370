@use "scss/variables";
@use "scss/functions";

.user-card-info {
  border-radius: 8px;
  padding: functions.get-rem(24px);
  margin-top: functions.get-rem(-16px);
  background-color: variables.$element-2;
}

.card-content {
  display: flex;
  margin: functions.get-rem(24px) 0;

  &:first-child {
    margin-top: functions.get-rem(40px);
  }

  :first-child {
    flex-basis: 25%;
  }

  .edit-button {
    margin-top: 2px;
    margin-left: 6px;
    padding: 0 !important;
    text-decoration: underline;
  }
}

.image {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: functions.get-rem(40px);
  height: functions.get-rem(40px);
  margin-right: functions.get-rem(12px);
  background-color: variables.$secondary-1;
  color: variables.$foreground-medium;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.tag {
  height: 100% !important;
  margin-left: auto !important;
}
