@use "../../../../scss/functions";
@use "../../../../scss/variables";

.dialog-container {
  position: absolute !important;
  min-width: functions.get-rem(390px);
  right: functions.get-rem(20px) !important;
}

.controls {
  width: 100%;
}

.modal-header {
  display: flex;
  border-bottom: none !important;

  button {
    top: 10px;
    right: 10px;
    position: absolute;
    transform: scale(0.8);
  }
}

.modal-body {
  border-top: none !important;
}
