@use "scss/variables";

.sellerBackground {
  background-color: variables.$element-2
}

.topDivider {
  border-top: 1px solid variables.$stroke-medium;
}

.iconCircleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.shareClassIcon {
  @extend .iconCircleContainer;
  background-color: variables.$element-1;
}

.warningIcon {
  @extend .iconCircleContainer;
  background-color: variables.$warning-050;
}
