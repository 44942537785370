@use "scss/variables";
@use "scss/functions";

.head {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  height: functions.get-rem(5px);
  background-color: variables.$surface-1;

  .button {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    border-radius: 8px;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: functions.get-rem(38px);
    background-color: variables.$surface-1;
  }
}

.container {
  svg {
    padding-top: 6px;
    width: functions.get-rem(32px);
    height: functions.get-rem(32px);

    circle {
      stroke: variables.$foreground-medium;
    }

    path {
      fill: variables.$foreground-medium;
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  row-gap: functions.get-rem(16px);
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: functions.get-rem(32px) 0;
  background-color: variables.$surface-1;

  .single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 32%;
    padding: functions.get-rem(8px) functions.get-rem(16px);
    background-color: variables.$element-disabled;
  }
}

.question {
  width: functions.get-rem(24px);
  height: functions.get-rem(24px);

  path {
    fill: none !important;
    stroke: variables.$foreground-medium;
  }
}
