@use "src/scss/variables";
@use "src/scss/functions";

.wrap {
  padding: functions.get-rem(24px);
  border-radius: 8px;
  background: variables.$element-2;



  .infoSection {
    background: variables.$element-1;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 24px;
  }
}
.link {
  color: variables.$primary-1!important;
  text-decoration: underline!important;
  &.error {
    color: variables.$critical-500!important;
  }
}