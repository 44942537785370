@use "scss/variables";
@use "scss/functions";

@mixin th-td {
  th,
  td {
    @content;
  }
}

.modal {
  :global {
    .modal-dialog {
      width: 70%;
      min-width: 1067px !important;
      max-width: 1480px !important;

      .modal-content {
        padding: 0;
      }
    }
  }
}

.top-container {
  height: auto;
  display: flex;
  position: relative;
  border-radius: 7px;
  align-items: center;
  min-height: functions.get-rem(360px);
  background-color: variables.$element-2;

  .section {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;

    &:first-child {
      align-items: center;
    }

    &.bordered {
      border-left: 1px solid variables.$stroke-medium;
    }
  }
}

.details-container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid variables.$stroke-medium;
}

.head {
  padding-top: functions.get-rem(12px);
  padding-bottom: functions.get-rem(12px);
  border-bottom: 1px solid variables.$stroke-medium;
}

.pledges {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: functions.get-rem(16px);
  background-color: variables.$information-050;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 functions.get-rem(1px) !important;

  th:first-child,
  td:first-child {
    min-width: 40%;
  }

  th:last-child,
  td:last-child {
    min-width: 30%;
  }

  th,
  td {
    border: none !important;
  }

  th {
    border: 1px solid variables.$stroke-medium !important;
    font-size: 12px;
    font-weight: 500;
    border-right: none !important;
    border-left: none !important;

    &.head-item {
      &.no-border {
        border: none !important;
      }
    }

  }

  tr {
    vertical-align: middle;

    th {
      cursor: pointer;
      height: functions.get-rem(40px);
      color: variables.$foreground-medium;
      padding: 0 functions.get-rem(24px) !important;
      border-bottom: none !important;

      &:first-child {
        border-top-left-radius: 4px;
        border-left: 1px solid variables.$stroke-medium !important;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-right: 1px solid variables.$stroke-medium !important;
      }
    }

    td {
      padding: functions.get-rem(16px) functions.get-rem(24px);
      //cursor: pointer;
      text-align: left;

      border-bottom: 1px solid variables.$stroke-medium !important;

      &:first-child {
        border-left: 1px solid variables.$stroke-medium !important;
        border-top-left-radius: 0 !important;
      }

      &:last-child {
        border-right: 1px solid variables.$stroke-medium !important;
        border-top-right-radius: 0 !important;
      }
    }

    &:first-child {
      td {
        border-top: 1px solid variables.$stroke-medium !important;
      }
    }
  }

  tbody tr td {
    font-size: 14px;
    color: variables.$foreground-medium;
  }

  tbody > tr {
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }

    &.no-border {
      border: none !important;
    }
  }
}

.borderBottom {
  border-radius: 8px;
  border-bottom: 1px solid variables.$stroke-medium;
}

.tableBorder {
  position: relative;
  font-variant-numeric: tabular-nums !important;

  td {
    height: 64px;
  }

  &.no-border {
    &:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }
}

.sort {
  display: flex;
  margin-left: 4px;
  width: functions.get-rem(24px);
  height: functions.get-rem(18px);

  svg {
    color: variables.$stroke-high;
    width: functions.get-rem(15px) !important;
    height: functions.get-rem(15px) !important;
  }
}

.avatar {
  padding: 0;
  font-size: 12px !important;
  width: functions.get-rem(24px) !important;
  min-width: functions.get-rem(24px) !important;
  height: functions.get-rem(24px) !important;
}

.info-section {
  flex: 1;
  text-align: left;
  padding: functions.get-rem(24px);
  border-radius: 8px;
  height: functions.get-rem(104px);
  border: 1px solid variables.$stroke-medium;
}

.close-btn {
  top: 24px;
  right: 24px;
  cursor: pointer;
  position: absolute;
}

.image {
  width: functions.get-rem(96px);
  height: functions.get-rem(96px);
  font-size: 32px !important;
}

.pledged-container {
  padding-top: 32px;
  border-top: 1px solid variables.$stroke-medium;
  background-color: variables.$element-2;
}

.email-overlay {
  :global {
    .tooltip-inner {
      max-width: 180px !important;
    }
  }
}

.tabs-head {
  display: flex;
  position: relative;
  align-items: center;
  height: functions.get-rem(56px);
  background-color: variables.$element-2;
  border-bottom: 1px solid variables.$stroke-medium;
}

.tab-item {
  height: 100%;
  display: inline-flex;
  cursor: pointer;
  padding: 0 32px;
  align-items: center;
  color: variables.$foreground-low;
  position: relative;

  svg {
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: transparent;
    transition: transform 0.4s ease, width 0.4s ease;
  }

  &.active {
    color: variables.$primary-1;
  }

  &.active::after {
    width: 100%;
    background-color: variables.$primary-1;
  }
}

.badge {
  width: functions.get-rem(20px);
  height: functions.get-rem(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 8px;
  background-color: variables.$element-3;
  color: variables.$foreground-low;

  &.active {
    background-color: variables.$secondary-1;
    color: variables.$primary-1;
  }
}

.long-table {
  text-align: start !important;
  display: block !important;
  white-space: nowrap !important;
}

.invite-section {
  span {
    display: block;
  }
}

.invite-tag {
  display: block;
  width: max-content;
}

.ownershipTable {
  .expanded-row {
    position: relative;
    background-color: variables.$element-2 !important;
    padding: 24px 24px 0 24px;

    .closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .counter {
      margin-left: 8px;
      padding: 2px 6px;
      min-width: 20px;
      text-align: center;
      background-color: variables.$element-3;
      border-radius: 10px;
      color: variables.$foreground-low;
    }
  }

}

.viewDocument {
  &:hover {
    cursor: pointer;
    color: variables.$primary-1;
    text-decoration: underline;
  }
}

