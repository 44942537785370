@use "scss/variables";
@use "scss/functions";

.wrap {
  background: variables.$information-050;
  padding: functions.get-rem(24px);
  display: flex;
  justify-content: space-between;
  margin-bottom: functions.get-rem(24px);

  a {
    color: variables.$information-900;
    text-decoration: underline;
  }

  .infoIcon {
    font-size: 20px;
    color: variables.$information-700;
    margin-right: functions.get-rem(24px);
  }

  .btnDontShowAgain {
    color: variables.$information-900!important;
    min-width: 220px;
  }
}