@use "scss/variables";

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: variables.$element-3;
}

.backgroundElement {
  background-color: variables.$element-2;
}

.backgroundElement3 {
  background-color: variables.$element-3;
}

.backgroundInfo {
  background-color: variables.$information-050;
}

.conflict-banner-button {
  button {
    background-color: variables.$warning-900 !important;
  }
}

.counter {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  margin-left: 8px;
  font-weight: 400 !important;
  align-items: center;
  justify-content: center;
  background-color: variables.$element-3;
}

.row {
  height: 64px;

  &.disabled {
    color: variables.$foreground-disabled !important;
  }
}

.highlight-item {
  color: variables.$warning-900;
  background-color: variables.$warning-050;
}

.avatar-card {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variables.$background-2;
  color: variables.$foreground-high;

  &.altinn {
    color: variables.$information-700;
    background-color: variables.$information-050;
  }
}

.tooltip {
  :global {
    .tooltip-inner {
      width: 230px !important;
      max-width: 230px !important;
    }
  }
}

.helper-icon {
  svg {
    font-size: 16px;
  }
}

.no-matches-found {
  display: flex;
  align-items: center;
  margin-top: 16px;

  color: variables.$warning-900;

  svg {
    color: variables.$warning-700 !important;
  }
}
