@use "scss/variables";
@use "scss/functions";

.portfolio {
  margin-top: functions.get-rem(24px);
  background: white;

  .title {
    padding-top: 18px;
    margin-left: 24px;
    margin-bottom: 18px;
  }
  .subtitle {
    color: variables.$foreground-low;
    margin-top: 4px;
  }
  .info {
    background: variables.$information-050;
    padding: functions.get-rem(24px);
    display: flex;
    &__icon {
      font-size: 24px;
      display: flex;
      color: variables.$information-700;
      margin-right: 1rem;
    }
    &__close {
      font-size: 16px;
      display: flex;
      color: variables.$foreground-medium;
      margin-left: auto;
      svg {
        cursor: pointer;
      }
    }
  }
}
.invited {
  font-size: functions.get-rem(14px);
  font-weight: 400;
  color: variables.$information-700;
  span {
    font-size: functions.get-rem(16px);
    margin-right: 4px;
  }
}
