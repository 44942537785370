@use "scss/functions";
@use "scss/variables";

.head {
  background-color: variables.$surface-1;
  border-bottom: 1px solid variables.$surface-1;
}

.footer {
  display: flex;
  height: functions.get-rem(150px);
  background-color: variables.$surface-1;

  .section {
    display: flex;
    padding: 26px;
    flex-basis: 100%;
    flex-direction: column;
  }

  .middle-section {
    margin: 24px 0;
    background-color: variables.$surface-1;
    border-radius: 8px;
  }
}
