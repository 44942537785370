@use "scss/variables";
@use "scss/functions";

.search-input {
  min-width: functions.get-rem(340px);

  ::placeholder {
    font-style: normal !important;
  }

  svg {
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
    color: variables.$foreground-low;
  }

  input {
    text-indent: 10px;
    border: 1px solid transparent;
    padding-right: functions.get-rem(28px);
    height: functions.get-rem(40px) !important;
    background-color: variables.$element-2 !important;

    &:hover {
      border-color: transparent !important;
    }

    &:focus {
      border: 1px solid variables.$primary-1 !important;
    }
  }
}

.input-remove-icon {
  cursor: pointer;
  position: absolute;
  right: 8px !important;
  width: functions.get-rem(20px);
  height: functions.get-rem(20px);
  color: variables.$foreground-low;
}
