@use "scss/variables";
@use "scss/bootstrap";

.access-table {
  border: 1px solid variables.$surface-1;
  border-radius: 4px;
  padding: map-get(bootstrap.$spacers, 4);

  th, td {
    padding: 32px 0 !important;
  }

  tr:last-child {
    td {
      border: none !important;
    }
  }
}
