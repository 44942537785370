@use 'scss/variables';
@use 'scss/functions';

$main-font-size: 14px;

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: functions.get-rem($main-font-size);
  color: variables.$foreground-medium;

  &.big {
    font-size: functions.get-rem(16px);
  }
}

.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: functions.get-em(8px, $main-font-size);

  :global {
    .badge {
      $font-size: 14px;
      $padding-y: functions.get-em(3px, $font-size);

      font-size: functions.get-em($font-size, $main-font-size);
      font-weight: variables.$font-weight-regular;
      border-radius: functions.get-em($font-size, $main-font-size) * variables.$line-height-base + $padding-y;
      padding: $padding-y functions.get-em(8px, $font-size);
      margin-left: functions.get-em(2px, $font-size);
    }
  }
}

.icon {
  $width: functions.get-em(24px, $main-font-size);

  width: $width;
  height: $width;
  color: variables.$foreground-low;

  &.enabled {
    color: variables.$positive-500;
  }

  [fill] {
    fill: currentColor;
  }

  [stroke] {
    stroke: currentColor;
  }
}
