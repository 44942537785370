@use 'scss/variables';
@use 'scss/functions';

.widget {
  width: functions.get-rem(325px);
  background-color: variables.$surface-1;
  margin-bottom: functions.get-rem(28px);

  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  $font-size: 18px;

  font-size: functions.get-rem($font-size);
  font-weight: variables.$font-weight-bold;
  color: variables.$foreground-high;
  border-bottom: 2px solid variables.$surface-1;
  padding:
    functions.get-em(22px, $font-size)
    functions.get-em(24px, $font-size)
    functions.get-em(22px, $font-size)
    functions.get-em(30px, $font-size);
}
