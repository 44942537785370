@use "scss/variables";
@use "scss/functions";

.overview {
  overflow: hidden;
  background-color: variables.$surface-1;
}

.charts {
  padding: 0 functions.get-rem(34px) 0 functions.get-rem(28px);
}

.statistic {
  display: flex;
  background-color: variables.$element-2;
  border-top: 1px solid variables.$surface-1;
  padding: functions.get-rem(10px) functions.get-rem(34px) functions.get-rem(10px) functions.get-rem(28px);
}

.purchase-available {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: functions.get-rem(218px);
  padding: functions.get-rem(16px) functions.get-rem(24px);
  background-color: variables.$positive-500;

  &.expired {
    background-color: variables.$element-3;
    color: variables.$foreground-disabled;
  }
}

#exercise-button {
  background-color: variables.$positive-700;
  margin-top: functions.get-rem(14px) !important;

  &:hover {
    background-color: darken(variables.$positive-700, 10%) !important;
  }
}

.circle {
  border-radius: 50%;
  width: functions.get-rem(12px);
  height: functions.get-rem(12px);
  background-color: variables.$positive-050;

  &.dotted {
    background-color: variables.$surface-1;
    border: 1px dotted variables.$positive-900;
  }

  &.danger {
    background-color: variables.$critical-050;
    border-color: variables.$critical-700;
  }

  &.low {
    border-color: variables.$foreground-low;
  }
}
