@use "scss/variables";

.border {
  border-radius: 8px;
  border: 1px solid variables.$stroke-medium;
  background-color: variables.$surface-1;
}

.progressBar {
  border-radius: 8px !important;
  width: 100%;
  height: 8px !important;
  background-color: variables.$positive-050 !important;

  div[role="progressbar"] {
    height: 100%;
    border-radius: 8px;
    background-color: variables.$positive-500;
    max-width: 100%;
  }
}

.iconsBorder {
  border-radius: 30px;
  border: 1px solid variables.$stroke-medium;
}
