@use "scss/variables";
@use "scss/functions";

.neutral-plate {
  border-radius: 8px;
  background-color: variables.$element-1;
  padding: functions.get-em(20px) functions.get-em(20px);
}

.share-details-title {
  font-style: variables.$font-family-base;
  font-weight: variables.$font-weight-bold;
  font-size: variables.$paragraph-m;
}

.vesting-years {
  max-width: 160px;
}

.company-relationship {
  margin-top: 2px;
}

.stockOptionInfo {
  display: inline-block;
  position: relative;

  .info {
    position: absolute;
    right: 0;
    top: -5px;
    font-size: functions.get-rem(21px);
    cursor: pointer;
  }
}

.tooltipInfo {
  text-align: left;

  .title {
    font-style: variables.$font-family-base;
    font-weight: variables.$font-weight-medium;
    font-size: variables.$paragraph-m;
    margin-bottom: 0;
  }
}

