@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.one-off-plans {
  .dropdown-divider {
    display: none;
  }

  &-header {
    height: functions.get-rem(88px);
    padding: 0 map-get(bootstrap.$spacers, 3) 0 map-get(bootstrap.$spacers, 4);
    background-color: variables.$element-1;
    border: 1px solid variables.$foreground-medium;
  }
  &-content {
    padding: 20px;
    background-color: variables.$element-1;
  }
}
