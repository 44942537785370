@use "scss/functions";
@use "scss/variables";

.modalInfo {
  
  :global {
    .modal-header {
      justify-content: space-evenly;
      padding: 0 0 1.5rem 0;
      border-bottom: 0;
      .modal-title {
        font-size: functions.get-rem(28px);
        line-height: 32px;
        max-width: 342px;
      }
    }
    .modal-content {
      padding: functions.get-rem(40px);
      text-align: center;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.15);
    }
    .modal-body {
      font-size: 1rem;
      line-height: functions.get-rem(24px);
      font-weight: 400;
      padding: 0;
    }
    .modal-footer {
      margin-top: functions.get-rem(40px);
      border-top: 0;
      justify-content: center;
    }
  }
}
