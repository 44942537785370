@use "scss/variables";
@use "scss/functions";

.wrap {
  margin: 0 functions.get-rem(32px) functions.get-rem(24px) functions.get-rem(32px);
  //border: 1px solid variables.$stroke-medium;
  //border-radius: 8px;
  display: block;

  .header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: functions.get-rem(12px) functions.get-rem(16px) functions.get-rem(12px)  functions.get-rem(16px) ;
    background-color: variables.$element-2;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 1px solid variables.$stroke-medium;
    border-right: 1px solid variables.$stroke-medium;
    border-top: 1px solid variables.$stroke-medium;
    .step {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: functions.get-rem(16px);
      font-weight: 500;
      color: variables.$foreground-medium;
      background: variables.$additional-1-550;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }

    .description {
      margin-top: 4px;
    }

    .item {
      border-left: 1px solid variables.$stroke-medium;
      padding: 0 functions.get-rem(24px);
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .empty {
    border: 1px solid variables.$stroke-medium;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}