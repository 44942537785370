@use "scss/functions";
@use "scss/variables";

.toggle {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  position: relative;
  padding: 0 !important;

  svg {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.delete-modal {
  background-color: rgba(255, 255, 255, 0);
  :global {
    .modal-content {
      border: none;
      display: flex;
      pointer-events: none;
      background-color: transparent;
    }
  }

  .delete-btn {
    border-color: #d93535 !important;
    background-color: #d93535 !important;
    color: variables.$element-1;

    &:hover,
    &:focus {
      border-color: #d93535 !important;
      background-color: darken(#d93535, 10) !important;
    }
  }
}

.delete-content {
  position: relative;
  border-radius: 8px;
  align-self: center;
  pointer-events: initial;
  width: functions.get-rem(475px);
  background-color: variables.$element-1;
  box-shadow: 0px 4px 16px 0px #00000026;
  box-shadow: 0px 2px 8px 0px #0000001a;
  padding: functions.get-rem(40px) functions.get-rem(50px);
}
