@use "scss/functions";
@use "scss/variables";

.collapsible {
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  padding: functions.get-rem(24px) functions.get-rem(32px);
  background-color: variables.$surface-1;

  .icon {
    position: absolute;
    right: functions.get-rem(32px);
  }
}

.head {
  top: 1px;
  z-index: 10;
  position: sticky;
  height: functions.get-rem(40px);

  tr {
    background-color: variables.$surface-1;

    th {
      width: 170px;
      cursor: pointer;
      font-weight: 500;
      vertical-align: middle;
      height: functions.get-rem(40px);

      &:first-child {
        padding-left: functions.get-rem(32px);
      }
    }
  }
}

.sort {
  display: flex;
  margin-left: 4px;
  width: functions.get-rem(24px);
  height: functions.get-rem(18px);
  color: variables.$foreground-high;

  svg {
    transition: all 0.2s;
    transform: rotate(0);
    width: functions.get-rem(15px) !important;
    height: functions.get-rem(15px) !important;
  }

  &.rotated {
    svg {
      transform: rotate(180deg);
    }
  }
}

.body {
  * {
    box-sizing: border-box !important;
  }

  tr {
    position: relative;
    transition: all 0.5s;
    height: functions.get-rem(64px) !important;

    td {
      width: 170px;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle !important;
      height: functions.get-rem(64px) !important;

      div {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.truncate {
        max-width: 1px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:first-child {
        padding-left: functions.get-rem(32px);
      }
    }
  }
}

.footer {
  tr {
    position: relative;

    th {
      border: none !important;
      height: functions.get-rem(64px);
      background-color: variables.$element-2;
    }
  }
}

.table-row {
  height: functions.get-rem(64px);

  &.hovered {
    background-color: variables.$secondary-1 !important;
  }

  &.selected {
    background-color: variables.$information-050;
  }
}

.empty-container {
  padding: functions.get-rem(16px);
  background-color: variables.$surface-1;
}

.empty {
  color: variables.$foreground-medium;
  padding: functions.get-rem(16px);
  background-color: variables.$surface-1;
}

.status-tag {
  display: flex;
  border-radius: 8px;
  width: max-content;
  align-items: center;
  justify-content: center;
  height: functions.get-rem(20px);
  padding: 0 functions.get-rem(12px);
  float: right;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 0 !important;
  justify-content: center;
  color: variables.$primary-1 !important;
  width: functions.get-rem(32px) !important;
  height: functions.get-rem(32px) !important;
  border-color: variables.$foreground-high !important;

  svg {
    width: functions.get-rem(24px) !important;
    height: functions.get-rem(24px) !important;
  }

  &.dark-icon {
    svg {
      color: variables.$foreground-high !important;
    }
  }
}
.representedBy {
  color: variables.$foreground-low;
}

.tooltip {
  :global {
    .tooltip-inner {
      width: functions.get-rem(230px) !important;
      max-width: functions.get-rem(230px) !important;
    }
  }
}
