@use "scss/functions";

.wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  height: 120px;
  z-index: 10;
  padding-top: functions.get-rem(28px);
  padding-left: functions.get-rem(48px);
  padding-right: functions.get-rem(48px);
}

.body {
  flex-grow: 1;
  display: flex;
  position: relative;
  //min-height: functions.get-rem(750px);
}
