@use "scss/variables";
@use "scss/functions";

.title-section {
  border-radius: 4px;
  height: functions.get-rem(13px);
  width: functions.get-rem(130px);
  background-color: variables.$additional-13-100;
}

.subtitle-section {
  border-radius: 4px;
  height: functions.get-rem(18px);
  width: functions.get-rem(180px);
  background-color: variables.$additional-13-100;
}

.chart-container {
  display: flex;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: functions.get-rem(210px);
  background-color: variables.$element-2;
}

.action-first {
  border-radius: 8px;
  height: functions.get-rem(40px);
  width: functions.get-rem(280px);
  background-color: variables.$additional-13-100;
}

.action-last {
  border-radius: 8px;
  height: functions.get-rem(23px);
  width: functions.get-rem(118px);
  background-color: variables.$additional-13-100;
}

.head {
  display: flex;
  border-radius: 4px;
  align-items: center;

  height: functions.get-rem(40px);
  background-color: variables.$additional-13-100;

  .head-row {
    border-radius: 8px;
    height: functions.get-rem(20px);
    background-color: variables.$surface-1;
  }
}

.body-row {
  display: flex;
  align-items: center;
  height: functions.get-rem(48px);

  .inner {
    border-radius: 8px;
    height: functions.get-rem(20px);
    background-color: variables.$additional-13-100;

    &.no-bg {
      background-color: transparent;
    }

    .sub-circle {
      border-radius: 50%;
      flex-shrink: 0;
      width: functions.get-rem(24px);
      height: functions.get-rem(24px);
      background-color: variables.$additional-13-100;
    }
  }
}

.footer {
  display: flex;
  border-radius: 4px;
  align-items: center;
  height: functions.get-rem(40px);
  background-color: variables.$additional-13-100;

  .inner {
    border-radius: 8px;
    height: functions.get-rem(20px);
    background-color: variables.$surface-1;

    &.no-bg {
      background-color: transparent;
    }

    .sub-rect {
      width: 40%;
      border-radius: 8px;
      height: functions.get-rem(20px);
      background-color: variables.$surface-1;
    }
  }
}
