.placeholderWave {
  width: 100%;
  background: linear-gradient(90deg, rgba(200, 200, 200, 0.3) 25%, rgba(200, 200, 200, 0.6) 50%, rgba(200, 200, 200, 0.3) 75%);
  background-size: 200% 100%;
  animation: wave 1.5s infinite;
  border-radius: 6px;
  filter: blur(6px);
}

@keyframes wave {
  0% {
    background-position: 200% 0;
}
  100% {
    background-position: -200% 0;
}
}
