@use "../../../../scss/variables";
@use "../../../../scss/functions";

.head {
  cursor: pointer;
  background-color: variables.$element-1;

  &.opened {
    background-color: variables.$element-1;
  }
}

.container {
  position: relative;
  border-radius: 8px;
  background-color: variables.$element-1;
  padding: functions.get-rem(24px) functions.get-rem(40px) functions.get-rem(24px) functions.get-rem(72px);

  &.opened {
    background-color: variables.$element-1;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  .title {
    display: flex;
    align-items: center;
  }

  .point {
    position: absolute;
    left: functions.get-rem(24px);
  }

  .subtitle {
    color: variables.$foreground-medium;
  }

  &.selected {
    border: 2px solid variables.$positive-500;
  }
}

.text-field {
  width: functions.get-rem(270px);

  ::placeholder {
    font-style: italic;
  }
}

.companies {
  border-radius: 2px;
  //border: 2px solid variables.$foreground-medium;

  .single {
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    transition: all 0.2s;
    height: functions.get-rem(56px);
    padding: 0 functions.get-rem(16px);
    color: variables.$foreground-high;
    background-color: variables.$element-1;

    &:hover {
      background-color: variables.$element-1;
    }

    &.selected {
      border: 2px solid variables.$positive-500;
      background-color: variables.$positive-050 !important;
    }

    .company-check {
      margin-left: auto;
    }
  }
}

.new-company {
  border-radius: 4px;
  position: relative;
  background-color: variables.$element-1;
  padding: 0;

  &.active {
    padding: functions.get-rem(24px) functions.get-rem(24px) functions.get-rem(32px) functions.get-rem(24px);
  }

  .icon {
    top: 35px;
    right: -10px;
    position: absolute;
  }

  &.selected {
    border: 2px solid variables.$positive-500;
    background-color: variables.$positive-050 !important;
  }
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: functions.get-rem(24px);
  right: functions.get-rem(24px);
}
