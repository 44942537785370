@use "scss/variables";
@use "scss/functions";

.header {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 17px 21px 18px 32px;
  height: functions.get-rem(82px);
  background-color: variables.$surface-1;

  .icon {
    transition: 0.2s all;
    transform: rotate(90deg);
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
    color: variables.$foreground-low;

    &.open {
      transform: rotate(-90deg);
    }
  }
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 0 !important;
  justify-content: center;
  color: variables.$primary-1 !important;
  width: functions.get-rem(32px) !important;
  height: functions.get-rem(32px) !important;
  border-color: variables.$foreground-high !important;

  svg {
    width: functions.get-rem(24px) !important;
    height: functions.get-rem(24px) !important;
  }
}

#filter-btn {
  border: 1px solid variables.$primary-1 !important;
}

.table {
  margin-bottom: 0 !important;

  tr {
    td {
      border: none !important;
    }
  }
}

.body {
  * {
    box-sizing: border-box;
  }
  tr {
    position: relative;
    transition: all 0.5s;
    height: functions.get-rem(64px) !important;

    td {
      height: functions.get-rem(64px) !important;
      vertical-align: middle !important;
      padding: 0 functions.get-rem(16px) 0 functions.get-rem(40px) !important;
    }
  }
}

.empty {
  padding: 16px 32px;
  margin: 20px 0;
  background-color: variables.$surface-1;

  p {
    color: variables.$foreground-medium;
  }
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
}

.actions-section {
  svg {
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s;
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
  }

  .small-icon {
    width: functions.get-rem(16px);
    height: functions.get-rem(16px);
  }
}

.animated-actions {
  top: 2px;
  right: 0;
  display: flex;
  position: absolute;
  height: functions.get-rem(47px);
  width: functions.get-rem(200px);
  padding-right: functions.get-rem(28px);

  background: linear-gradient(270deg, variables.$primary-1 66.29%, rgba(variables.$primary-1, 0) 94.57%);

  div {
    display: flex;
    margin-left: auto;
    align-items: center;

    div {
      :first-child {
        margin-left: 24px;
      }

      svg {
        cursor: pointer !important;
      }
    }
  }
}

.table-row {
  font-weight: 700;
  position: relative;
  cursor: pointer;
  height: functions.get-rem(50px);

  td {
    background-color: transparent;

    &.nested {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: functions.get-rem(84px) !important;
      background-color: transparent;

      svg {
        width: functions.get-rem(24px);
        height: functions.get-rem(24px);
        color: variables.$foreground-low;
      }

      &.inner-nested-first {
        padding-left: functions.get-rem(112px) !important;
      }

      &.inner-nested-second {
        padding-left: functions.get-rem(140px) !important;
      }
    }

    div {
      align-items: center;
    }

    .upload-btn {
      right: -120px;
      padding: 0 8px;
      position: absolute;
      width: functions.get-rem(96px);
      height: functions.get-rem(32px);

      &:hover {
        background-color: variables.$additional-7-200 !important;
      }

      svg {
        color: variables.$primary-1 !important;
      }
    }

    &:hover {
      background-color: variables.$additional-3-400 !important;
    }
  }
}

.toggle-element {
  top: 15%;
  right: 14px;
  position: absolute;
}

.documents {
  &-content {
    background-color: variables.$surface-1;
  }

  &-rounded-container {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    height: functions.get-rem(20px);
    width: functions.get-rem(20px);
    color: variables.$foreground-low;
    background-color: variables.$background-1;
  }
}

.slide-panel {
  :global {
    .slide-panel-content-setion {
      display: flex;
      min-height: 100%;
      flex-direction: column;
    }
  }
}

.slide-actions {
  margin-top: auto;
}

.menu-button {
  padding: 0 !important;
  width: functions.get-rem(40px);
  height: functions.get-rem(40px);

  svg {
    width: functions.get-rem(24px) !important;
    height: functions.get-rem(24px) !important;
    color: variables.$foreground-high !important;
  }
}

.highligted {
  font-weight: bold;
  background-color: transparent;
  color: variables.$foreground-high !important;
}

.unhighlited {
  font-weight: 400;
}

.arrow {
  width: functions.get-rem(14px) !important;
  height: functions.get-rem(10px) !important;
}

.missing-documents {
  color: variables.$warning-700;
}

.empty-label {
  td {
    div {
      color: variables.$foreground-medium !important;
    }
  }
}
