@use "scss/variables";
@use "scss/functions";

.fileUploader {
  .label {
    .acceptTypes {
      line-height: 1.3;
      font-style: italic;
      color: variables.$foreground-low;
      font-size: functions.get-rem(14px);
    }
  }
  .error {
    margin-top: functions.get-rem(12px) !important;
  }
}
