@use "scss/variables";
@use "scss/functions";

.wrap {
  .close {
    cursor: pointer;
  }
  .container {
    background: white;
    border-radius: 8px;
    padding: functions.get-rem(64px);
    width: 814px;
    margin: 0 auto;
    margin-bottom: 62px;
  }
}