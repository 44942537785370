@use "scss/variables";
@use "scss/functions";

.container {
  border-radius: 4px;
  background-color: variables.$information-050;

  svg {
    flex-shrink: 0;
  }

  button {
    min-width: 110px !important;
    color: variables.$surface-1 !important;
    background-color: variables.$information-700 !important;
  }
}
