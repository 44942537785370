@use "scss/variables";
@use "scss/functions";

.container {
  margin: functions.get-rem(72px) auto functions.get-rem(168px) auto;
  color: variables.$foreground-high;
  max-width: functions.get-rem(1064px);
  background-color: variables.$surface-1;
  padding: functions.get-rem(102px) functions.get-rem(146px);

  .info {
    cursor: pointer;
    display: inline;
    position: relative;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    color: variables.$information-500;
  }
}

.close-btn {
  top: 8px;
  right: 8px;
  cursor: pointer;
  position: absolute;
  width: functions.get-rem(24px);
  height: functions.get-rem(24px);
}
