@use "scss/variables";

.rounded {
  border-radius: 50%;
}

.imgSmall {
  width: 24px;
  height: 24px;
}
.imgMedium {
  width: 40px;
  height: 40px;
}
.imgLarge {
  width: 56px;
  height: 56px;
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;

  background-color: variables.$secondary-1;
  color: variables.$foreground-medium;
}

.initialsSmall {
  @extend .initials;

  width: 24px ;
  min-width: 24px;
  height: 24px;
}

.initialsMedium {
  @extend .initials;

  width: 40px ;
  min-width: 40px ;
  height: 40px;
}

.initialsCompany {
  @extend .initials;

  background-color: variables.$information-050;
  color: variables.$information-900;
}

.initialsCompanySmall {
  @extend .initialsSmall;

  background-color: variables.$information-050;
  color: variables.$information-900;
}

.initialsCompanyMedium {
  @extend .initialsMedium;

  background-color: variables.$information-050;
  color: variables.$information-900;
}

.noname {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
