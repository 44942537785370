@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.pools-wrap {
  margin-bottom: 24px;
}

.actions-toggle {
  color: variables.$primary-1 !important;

  &:hover {
    color: variables.$primary-1 !important;
    background-color: variables.$secondary-1 !important;
  }

  &:active,
  &:focus {
    outline: 2px solid variables.$information-700 !important;
  }
}

.dropdown {
  font-family: variables.$font-family-base;

  button {
    display: flex;

    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 1px #131313 !important;
      border-color: #131313 !important;
    }
  }

  &:global.show .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  & :global {
    & .dropdown-menu {
      width: 100%;
      background-color: variables.$foreground-high;
      padding: 0;
      top: -4px !important;
      border-radius: 0 0 variables.$border-radius-xxl variables.$border-radius-xxl;
      border: 1px solid variables.$foreground-high;
    }

    & .dropdown-item {
      color: variables.$element-1;
      padding: functions.get-em(10px, bootstrap.$input-btn-font-size)
        functions.get-em(16px, bootstrap.$input-btn-font-size);
      font-size: bootstrap.$input-btn-font-size;

      &:last-child {
        border-radius: 0 0 variables.$border-radius-xxl variables.$border-radius-xxl;
      }

      &:active,
      &:hover,
      &:focus {
        color: variables.$foreground-high;
      }
    }
  }
}

.empty {
  background-color: variables.$surface-1;
  padding: functions.get-rem(32px) functions.get-rem(64px);
}

.removal-modal {
  div {
    div {
      align-items: center;
    }
  }

  background-color: rgba(255, 255, 255, 0);
  :global {
    .modal-content {
      border: none;
      display: flex;
      pointer-events: none;
      background-color: transparent;
    }
  }

  .content {
    border-radius: 4px;
    pointer-events: initial;
    width: functions.get-rem(476px);
    padding: functions.get-rem(40px);
    background-color: variables.$surface-1;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
}
