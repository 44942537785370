@use "scss/variables";
@use "scss/functions";

.overview {
  overflow: hidden;
  border-radius: 4px;
  background-color: variables.$surface-1;
}

.charts {
  padding: 0 functions.get-rem(34px) 0 functions.get-rem(28px);
}

.statistic {
  display: flex;
  background-color: variables.$element-2;
  border-top: 1px solid variables.$surface-1;
  padding: functions.get-rem(10px) functions.get-rem(34px) functions.get-rem(10px) functions.get-rem(28px);
}

.share-price {
  border-radius: 8px;
  width: functions.get-rem(218px);
  padding-top: functions.get-rem(28px);
  padding-bottom: functions.get-rem(20px);
  margin-top: functions.get-rem(16px);
  background-color: variables.$element-disabled;

  &.empty {
    background-color: variables.$element-disabled;
    color: variables.$foreground-disabled !important;
  }
}

.circle {
  border-radius: 50%;
  width: functions.get-rem(12px);
  height: functions.get-rem(12px);
  background-color: variables.$positive-500;

  &.dotted {
    background-color: variables.$surface-1;
    border: 1px dotted variables.$positive-900;
  }

  &.danger {
    background-color: variables.$critical-050;
    border-color: variables.$critical-700;
  }
}
