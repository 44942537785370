.container {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 24px;
  width: 340px;
  background-color: white;
  .email {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
  }
}

.tag {
  position: absolute;
  top: 0;
  rotate: -30deg;
}

.admin-tag {
  @extend .tag;
  left: -15px;
}

.invited-tag {
  @extend .tag;
  left: -35px;
}
