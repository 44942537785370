@use "scss/functions";
@use "scss/variables";

.container {
  width: 100%;
  border-radius: 8px;
  background-color: variables.$surface-1;
  min-height: functions.get-rem(495px);
  padding: functions.get-rem(16px);

  &.bottom {
    min-height: auto;
    padding: functions.get-rem(72px) functions.get-rem(64px);
  }
}

.main-info {
  height: 100%;
  display: flex;
  margin-right: functions.get-rem(64px);
  flex-direction: column;
  align-items: flex-start;
  padding: functions.get-rem(56px) 0 functions.get-rem(16px) functions.get-rem(48px);
}

.img-section {
  flex-basis: 40%;
  border-radius: 6px;
  background-color: variables.$additional-11-200;

  img {
    width: functions.get-rem(240px);
  }

  svg {
    flex-shrink: 0;
  }
}
