@use "scss/variables";

.checkContainer {
  background-color: variables.$surface-1;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid variables.$stroke-high;
  padding: 24px;

  &.selected {
    border: 1px solid variables.$primary-1;
  }

  .typeRadio {
    display: flex;
    gap: 12px;

    input {
      flex-shrink: 0
    }
  }
}
