@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.additional-footer {
  position: relative;
  padding: functions.get-rem(54px) functions.get-rem(48px) 0 functions.get-rem(48px);

  @include media-breakpoint-down(lg) {
    padding: functions.get-rem(36px) functions.get-rem(20px) 0 functions.get-rem(20px);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 90%;
    background-color: variables.$element-1;
  }

  &-logo-button-container {
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      justify-content: start;
      flex-direction: column;
      & > button {
        width: fit-content;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  color: variables.$foreground-high;
  padding: functions.get-rem(48px) functions.get-rem(48px) functions.get-rem(32px);
  margin-top: auto;

  @include media-breakpoint-down(lg) {
    padding: functions.get-rem(48px) functions.get-rem(20px) functions.get-rem(32px);
    font-size: functions.get-rem(14px);
  }

  &.wizard {
    color: variables.$element-1;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  :global {
    .nav-link {
      margin-top: 0;
      color: inherit;
      margin-bottom: 0;
      padding: functions.get-rem(16px) 0 functions.get-rem(16px) functions.get-rem(32px);
      text-decoration: underline;

      @include media-breakpoint-down(lg) {
        font-size: functions.get-rem(14px);
        padding: 8px;
      }
    }
  }
}
