@use "scss/variables";

.overviewContainer {
  border-radius: 8px;
  background-color: variables.$element-2;
}

.top-section {
  flex-basis: 45%;
}
.sharesContainer {
  border-radius: 8px;
  border: 1px solid variables.$stroke-medium;
}

.f {
  color: variables.$information-700;
}

.colorPositive700 {
  color: variables.$positive-700;
}

.documents {
  border-radius: 8px;
  border: 1px solid variables.$stroke-medium;
}

.single-document {
  cursor: pointer;
  flex-basis: 30%;

  &:hover {
    text-decoration: underline;
  }
}
