@use "scss/variables";

.documents {
  &-header {
    height: 82px;
    cursor: pointer;
    padding: 17px 21px 18px 32px;
    background-color: variables.$surface-1;
  }

  &-table {
    &-status {
      padding: 4px 8px;
      border-radius: 4px;
      display: inline-flex;
    }

    &-body {
      tr {
        td {
          vertical-align: middle !important;
        }
      }
    }
  }
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: variables.$information-700;
}

.context-button {
  color: variables.$foreground-medium !important;

  &:focus,
  &:active {
    border: none !important;
    outline: none !important;
  }
}
