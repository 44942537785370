@use "scss/variables";
@use "scss/functions";

.wrap {
  margin: 0 functions.get-rem(24px);
  min-width: 600px;
  width: 100%;

  .wrapper {
    display: flex;


    .sideBar {
      margin-left: functions.get-rem(24px);
      width: 336px;
      margin-top: 1.5rem;

      .welcome {
        top: 0;
        position: sticky;
        background: white;
        padding: functions.get-rem(24px);
      }
    }

    .mailTo {
      margin-top: functions.get-rem(24px);
      margin-left: functions.get-rem(24px);
      margin-right: functions.get-rem(24px);
      color: variables.$foreground-low;

      a {
        color: variables.$foreground-low!important;
        text-decoration: underline;
      }
    }
  }
  .newTag {
    background: variables.$additional-3-550 !important;
    color: variables.$foreground-medium;
  }

}