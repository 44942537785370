@use "scss/functions";
@use "scss/variables";

#btn {
  border-radius: 8px !important;
  background-color: transparent !important;
  color: variables.$foreground-medium !important;
  border: 1px solid variables.$stroke-medium !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  font-size: 16px;

  &:hover {
    color: variables.$positive-900 !important;
    border: 1px solid variables.$positive-300 !important;
  }

  &.selected {
    background-color: variables.$positive-050 !important;
    color: variables.$positive-900 !important;
    border: 1px solid variables.$positive-300 !important;

    &:hover {
      border: 1px solid variables.$positive-300 !important;
    }
  }

  &:focus,
  &:active {
    outline: none !important;
  }
}

#reset {
  color: variables.$foreground-medium !important;
  text-decoration: underline !important;

  &:hover {
    color: variables.$positive-500 !important;
    background-color: transparent !important;
  }

  &:focus,
  &:active {
    background-color: transparent !important;
    outline: none !important;
  }
}
