@use "scss/variables";

.wrap {
  border-radius: 8px;
  background-color: variables.$additional-1-100;
  display: flex;
  align-items: self-start;
  justify-content: center;
  align-self: stretch;
  flex-direction: column;
  height: 112px;
  flex: 1 0 0;
}