@use "scss/variables";
@use "scss/functions";

.chart-container {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid variables.$element-3;

  .header {
    display: flex;
    padding: functions.get-rem(16px);
    background-color: variables.$element-2;

    .option {
      flex-basis: 100%;
      text-align: center;
      color: variables.$foreground-medium;
      :last-child {
        color: variables.$foreground-high;
      }
    }
  }

  .chart {
    border: 2px solid variables.$surface-1;
  }
}

.link {
  color: variables.$foreground-high;
}

.documents-paragraph {
  font-style: variables.$font-family-base;
  font-weight: variables.$font-weight-medium;
  font-size: variables.$paragraph-m;
  padding-right: 8px;
  margin-bottom: 0;
}

.documents-descriptions {
  color: variables.$foreground-medium;
}

.radioDocumentation {
  :global {
    .py-1 {
      padding-left: 0;
      .form-check-input {
        margin-right: 0.365rem;
      }
    }
  }
}

.milestone {
  flex: 48%;
  max-width: 48%;
  min-height: functions.get-rem(216px);
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  padding: functions.get-rem(24px);
  background-color: variables.$element-2;
}

.info-container {
  border-bottom: 1px solid variables.$stroke-high;
}
