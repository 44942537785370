@use "scss/variables";

.sizeOfBundle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: variables.$element-3;
}
