@use "scss/functions";
@use "scss/variables";

.container {
  border-radius: 20px;
  padding: functions.get-rem(40px);
  max-width: functions.get-rem(984px);
  background-color: variables.$surface-1;
}

.pricing-container {
  position: relative;
  border-radius: 20px;
  padding: functions.get-rem(40px);
  background-color: variables.$surface-1;
}

.actions {
  button {
    width: functions.get-rem(190px);
  }
}

.image {
  margin-top: auto !important;
  width: functions.get-rem(235px) !important;
  height: functions.get-rem(235px) !important;
}

.subscription-manager {
  border-radius: 8px;
  display: flex;
  height: functions.get-rem(88px);
  justify-content: space-between;
  align-items: center;
  background-color: variables.$element-2;
}

.link {
  text-decoration: underline;
  color: variables.$foreground-high;
}
