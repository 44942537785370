@use "scss/variables";
@use "scss/functions";

.wrap {
  .block {
    border-radius: 8px;
    background: variables.$surface-1;
    padding: 24px 24px 8px 24px;
    flex: 1 1;
    .item {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      &.withUnderline {
        border-bottom: 1px solid variables.$stroke-medium;
      }
    }
  }
}