@use "scss/variables";
@use "scss/functions";

.row {
  display: flex;
  justify-content: space-between;

  div {
    flex-basis: 47%;

    label {
      div {
        &:first-child {
          flex-basis: 90%;
        }
      }
    }
  }
}

.holding {
  border-radius: 4px;
  background-color: variables.$surface-1;

  .checkbox {
    label {
      &:hover {
        background-color: variables.$surface-1 !important;
      }
    }
  }
}

.input {
  width: functions.get-rem(287px);
}

.divider {
  height: 1px;
  width: 100%;
  background-color: variables.$surface-1;
}

.button {
  padding-left: functions.get-rem(24px) !important;
  padding-right: functions.get-rem(24px) !important;
}

.reason-input {
  input {
    &::placeholder {
      font-size: 16px;
      font-style: italic !important;
    }
  }
}

.info {
  position: absolute;
  right: functions.get-rem(24px);
  bottom: functions.get-rem(24px);
}

.company-relationship {
  width: functions.get-rem(287px);
}
