@use "scss/variables";
@use "scss/mixins";

.main-wrap {
  min-height: 100vh;
  background-color: variables.$background-1;
  transition: background-color 0.5s ease-out;

  &.wizard {
    background-color: variables.$background-2;
  }

  &.public {
    background-color: variables.$background-1;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 100vh;

  @include mixins.view-container;
}
