@use "scss/variables";

.tableWrapper {
  max-width: 800px;
  overflow-x: auto;
}

.table {
  border: 1px solid variables.$stroke-medium;
  border-radius: 8px !important;
  border-collapse: separate;
  border-spacing: 0;

  tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  th {
    padding: 16px !important;
    padding-left: 24px !important;
    background-color: variables.$element-2 !important;
  }




  td {
    padding: 16px !important;
    padding-left: 24px !important;
    background-color: variables.$element-2 !important;
    vertical-align: middle;
  }
}
