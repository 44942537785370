@use "scss/functions";
@use "scss/variables";

.wrap {
  max-width: 984px;

  .topBanner {
    padding: functions.get-rem(16px) functions.get-rem(16px) functions.get-rem(16px) functions.get-rem(40px);
    border-radius: 20px;
    background-color: variables.$surface-1;
    display: flex;
    gap: 40px;
    margin-bottom: functions.get-rem(24px);
    h3 {
      margin-top: functions.get-rem(16px);
    }
  }

  .bottomBanner {
    padding: functions.get-rem(16px) functions.get-rem(40px) functions.get-rem(16px) functions.get-rem(16px);
    border-radius: 20px;
    background-color: variables.$surface-1;
    display: flex;
    gap: 40px;
  }
}