@use "scss/functions";
@use "scss/variables";

$header-height: functions.get-rem(104px);
$content-width: functions.get-rem(780px);

.slide-panel-container {
  border: none !important;
  width: $content-width !important;
}

#container {
  width: $content-width !important;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: calc($header-height + functions.get-rem(12px)) functions.get-rem(72px) 0 functions.get-rem(72px);
}

.header {
  top: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-shrink: 0;
  position: fixed;
  align-items: center;
  width: $content-width;
  justify-content: space-between;
  height: $header-height !important;
  background-color: variables.$surface-1;
  padding: 0 functions.get-rem(64px) 0 functions.get-rem(72px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
}

.section {
  .section-head {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$surface-1;

    .chevron {
      width: functions.get-rem(24px);
      height: functions.get-rem(24px);
    }
  }

  .divider {
    height: 1px;
    background-color: variables.$element-1;
  }
}

.header-icon {
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  right: functions.get-rem(64px);
  width: functions.get-rem(32px);
  height: functions.get-rem(32px);
  background-color: variables.$additional-1-200;

  svg {
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-top: functions.get-rem(56px);
  padding-bottom: functions.get-rem(56px);

  button {
    margin: 0;
  }
}

.tag {
  height: functions.get-rem(26px);
}
.body {
  position: relative;
  flex: 1 1;
  .spinner {
    padding-top: 40px;
    margin: 0 auto;
    width: 40px;
  }
}

.button {
  height: functions.get-rem(40px);
}

.delete-btn {
  color: variables.$critical-500 !important;

  svg {
    margin-top: -2px;
  }
}
