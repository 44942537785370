@use "../../../../scss/variables";
@use "../../../../scss/functions";

.fileItem {
  border-radius: functions.get-rem(16px) !important;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  background: variables.$element-2;
  align-items: center;
  margin-top: functions.get-rem(8px);
  padding: 16px functions.get-rem(32px);

  span {
    font-size: functions.get-rem(16px);
    line-height: 1.3;
    color: variables.$foreground-high;
    text-decoration: underline;
  }

  .deleteBlock {
    min-width: 26px;
    .deleteIcon {
      cursor: pointer;
    }
  }
}

.percentage {
  width: 32px;
  text-align: center;
}

.progress {
  &:global {
    &.progress {
      $border-radius: functions.get-rem(variables.$border-radius-xl);

      flex-grow: 1;
      height: 8px;
      border-radius: $border-radius;
      background-color: variables.$element-1;

      .progress-bar {
        border-radius: $border-radius;
        background-color: variables.$foreground-medium;
      }
    }
  }
}

.file-link {
  color: inherit;
}

.cancel-button {
  &:global {
    &.btn {
      color: variables.$critical-500;
    }
  }
}

.icon-error {
  color: variables.$critical-500 !important;
}
