@use "scss/variables";
@use "scss/functions";

@mixin th-td {
  th,
  td {
    @content;
  }
}

.table-container {
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid variables.$stroke-medium;
}

.table {
  border-collapse: separate;
  border-spacing: 0 functions.get-rem(4px) !important;

  th {
    color: variables.$foreground-high;
  }

  thead,
  tbody,
  th,
  td {
    border: none !important;
  }

  td {
    border-top: 1px solid variables.$stroke-medium !important;
  }

  tr {
    vertical-align: middle;
  }
}

.container {
  width: 100%;
  border-radius: 4px;
  background-color: variables.$surface-1;

  table {
    border-spacing: 0 4px;
    border-collapse: separate;
  }
}
