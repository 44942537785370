@use "scss/variables";

.border {
  border: 1px solid variables.$stroke-medium;
}

.borderGreen {
  border: 1px solid variables.$positive-300;
}

.size {
  width: 24%;
  height: 300px;
}
