@use "scss/variables";
@use "scss/functions";

.wrap {
  display: flex;
  gap: 24px;

  .block {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid variables.$stroke-medium;
    background: variables.$surface-1;
    padding: functions.get-rem(24px);
    display: flex;
    gap: 24px;
    justify-content: space-between;
    flex: 1 1;

    .radio {
      min-width: 24px;
      display: flex;
      justify-content: center;
    }
    &.isChecked {
      border: 1px solid variables.$primary-1;
    }
  }


}