@use "scss/variables";
@use "scss/functions";

.wrap {
  margin-left: functions.get-rem(22px);
  margin-top: 2rem;

  .header {
    width: 984px;
  }

  .wrapper {
    display: flex;

    .container {
      width: 984px;
    }

    .sideBar {
      margin-left: functions.get-rem(24px);
      width: 336px;
      margin-top: 1.5rem;

      .welcome {
        top: 0;
        position: sticky;
        background: white;
        padding: functions.get-rem(24px);
      }
    }
  }

  //media query max width 1560
  @media (max-width: 1620px) {
    .header {
      width: 886px;
    }
    .wrapper {
      .container {
        transform-origin: left top;
        transform: scale(0.9);
      }
    }
    .sideBar {
      margin-left: -80px !important;

      .welcome {
        transform-origin: left top;
        transform: scale(0.9);
      }
    }
  }

  @media (max-width: 1490px) {
    .header {
      width: 786px;
    }
    .wrapper {
      .container {
        transform-origin: left top;
        transform: scale(0.8);
      }
    }
    .sideBar {
      margin-left: -180px !important;

      .welcome {
        transform-origin: left top;
        transform: scale(0.8);
      }
    }
  }
}
