@use "scss/functions";
@use "scss/variables";

.header {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
  height: functions.get-rem(67px);
  padding: 0 functions.get-rem(24px);
  background-color: variables.$surface-1;
}

.container {
  border-radius: 4px;
  padding: functions.get-rem(16px);
  background-color: variables.$surface-1;
}

.document-item {
  display: flex;
  align-items: center;
  transition: all 0.3s;
  justify-content: space-between;
  height: functions.get-rem(60px);
  background-color: variables.$surface-1;
  padding: 0 functions.get-rem(12px) 0 functions.get-rem(24px);

  &:hover {
    background-color: variables.$additional-1-200;
  }
}

.actions {
  svg {
    cursor: pointer;
    margin-left: 10px;
  }
}

.empty {
  display: flex;
  justify-content: space-between;
  padding: functions.get-rem(32px);
  background-color: variables.$surface-1;
}

.empty-items {
  div {
    display: flex;
    align-items: center;

    svg {
      path {
        stroke: variables.$surface-1;
      }
    }

    .empty-section {
      border-radius: 5px;
      height: functions.get-rem(16px);
      width: functions.get-rem(160px);
      background-color: variables.$surface-1;
    }
  }
}
