/*
  This font software is the property of Commercial Type.

  You may not modify the font software, use it on another website, or install it on a computer.

  License information is available at http://commercialtype.com/eula
  For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

  Copyright (C) 2021 Schwartzco Inc.
  License: 2110-UKMBCB
*/

@font-face {
  font-family: "Canela Text";
  src: url("Canela-Light-Web.woff") format("woff2"), url("CanelaText-RegularNo2-Web.woff2") format("woff2"),
    url("CanelaText-RegularNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

;@import "sass-embedded-legacy-load-done:0";