@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.container {
  display: flex;
  justify-content: space-between;

  .single {
    display: flex;
    flex-basis: 32%;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$surface-1;
    height: functions.get-rem(128px);

    .circle {
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      width: functions.get-rem(64px);
      height: functions.get-rem(64px);

      &.info {
        color: variables.$information-900;
        background-color: variables.$information-050;
      }

      &.danger {
        color: variables.$warning-900;
        background-color: variables.$warning-050;
      }

      &.success {
        color: variables.$positive-900;
        background-color: variables.$positive-050;
      }
    }
  }
}
