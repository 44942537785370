@use 'scss/functions';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.page-content {
  flex-grow: 1;
  margin: 0 functions.get-rem(40px);

  @include media-breakpoint-up(lg) {
    margin: 0 functions.get-rem(24px);
  }
}
