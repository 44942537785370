@use "scss/variables";
@use "scss/functions";

.wrap {
  display: flex;
  justify-content: space-between;
  border: 1px solid variables.$surface-1;
  border-radius: functions.get-rem(variables.$border-radius);
}

.section {
  display: flex;
  flex-basis: 32%;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height:  functions.get-rem(210px);
  background-color: variables.$element-2;

  &.diluted {
    flex-basis: 24%;
  }
}

.pie-chart-wrap {
  position: relative;
  width: functions.get-rem(110px);
}

.pie-chart-content {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .empty {
    :first-child {
      border-radius: 8px;
      width: functions.get-rem(100px);
      height: functions.get-rem(30px);
      background-color: variables.$surface-1;
    }

    :last-child {
      border-radius: 8px;
      width: functions.get-rem(100px);
      height: functions.get-rem(20px);
      background-color: variables.$surface-1;
    }
  }

  :global {
    .ui-l {
      margin-bottom: functions.get-em(5px, variables.$ui-l);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ui-bold-s {
      color: variables.$foreground-medium;
      margin-bottom: functions.get-em(4px, variables.$ui-s);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ui-xs {
      color: variables.$foreground-medium;
      margin-bottom: functions.get-em(4px, variables.$ui-xs);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.chart-tooltip {
  display: flex;
  border-radius: 4px;
  transition: all 0.5s;
  flex-direction: column;
  padding: functions.get-rem(12px);
  background-color: variables.$surface-1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);

  p {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .circle {
    flex-shrink: 0;
    border-radius: 50%;
    width: functions.get-rem(12px);
    height: functions.get-rem(12px);
  }
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
