@use 'scss/variables';
@use 'scss/functions';

.description {
  line-height: variables.$line-height-lg;
  margin-bottom: functions.get-rem(12px);

  &:last-child {
    margin-bottom: 0;
  }
}
