@use "scss/variables";

.whiteBackground {
  background-color: variables.$surface-1 !important;
}

.activeBorder {
  outline: 1.5px solid variables.$primary-1;
}

.hoverBorder {
  &:hover {
    outline: 1.5px solid variables.$primary-1;
  }

  & > *:hover {
    outline: none;
  }
}


