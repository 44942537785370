@use "scss/variables";
@use "scss/functions";
.poolRequired {
  font-size: functions.get-rem(14px);
  line-height: 1.3;
  font-style: italic;
  color: variables.$foreground-medium;
}

.poolItem {
  width: 50%;
  svg {
    path {
      stroke-width: 1px !important;
    }
  }
}
.pool {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.poolCard {
  display: inline-block;
  margin-right: 1rem;
}
.blurSection {
  top: 20px;
  right: 1px;
  height: calc(100% - 40px);
  position: absolute;
  width: 34px;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
