@use "scss/functions";
@use "scss/variables";

.container {
  :global {
    .dropdown-menu {
      border: none;
      box-shadow: 0 2px 6px 0 #00000026;
    }
  }

  height: 24px;
  width: 120px;

  input {
    padding: 0;
    height: 24px;
    border: none !important;
    background: transparent;

    &:focus,
    &:active {
      background-color: transparent !important;
    }
  }
}
