@use "scss/variables";
@use "scss/functions";

.money-transfer-widget {
  &:global {
    &.container-fluid {
      padding: 0;
      background-color: variables.$surface-1;

      .error {
        bottom: -15px;
      }
    }
  }
}

.image-column {
  margin-left: 56px;
}

.transaction-data {
  border-radius: 4px;
  row-gap: functions.get-rem(48px);
  background-color: variables.$element-3;
}

.transaction-data-element {
  width: 30%;

  &:last-child {
    flex-grow: 1;
  }

  .title {
    color: variables.$foreground-medium;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  .value {
    font-size: 14px;
    opacity: 0.7;
  }

  .copyButton {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-appearance: button;
    width: 20px;
    height: 20px;
    padding: 0;
    border: unset;
    background-color: variables.$element-1;
    border-radius: 4px;
    margin-left: 8px;
  }
}
