@use "scss/variables";
@use "scss/functions";

.container {
  cursor: grab;
  display: flex;
  position: relative;
  border-radius: 16px;
  align-items: center;
  transition: all 0.3s;
  justify-content: space-between;
  height: functions.get-rem(64px);
  padding-left: functions.get-rem(36px);
  background-color: variables.$element-3;
  border: 1px dashed variables.$stroke-high;
}

.error {
  left: 0;
  bottom: -30px;
  position: absolute;
  color: variables.$warning-500;
}
