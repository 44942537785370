@use "scss/variables";
@use "scss/functions";

.row {
  flex: 1;
  display: flex;
  border-radius: 4px;
  background-color: variables.$surface-1;
}

.purchaseColumn {
  position: relative;
  align-items: center;
}

.profit-container {
  flex-shrink: 0;
  border-radius: 4px;
  width: functions.get-rem(268px);
  border: 1px solid variables.$positive-050;
}

.profit-head {
  border-radius: 4px;
  height: functions.get-rem(96px);
  color: variables.$positive-900;
  background-color: variables.$positive-050;

  &.negative {
    background-color: variables.$critical-050;
  }
}
