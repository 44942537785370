@use "scss/variables";

.background {
  background-color: variables.$background-1 !important;
}

.backgroundElement {
  background-color: variables.$element-2;
}

.border {
  border: 1px solid variables.$stroke-medium;
}

.wizard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: variables.$background-1;
  overflow-y: scroll;
}

.table-wrapper {
  width: 1200px;
  margin: 0 auto;
  border: 1px solid variables.$stroke-medium;
}
