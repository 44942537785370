@use "scss/variables";
@use "scss/bootstrap";

.toggle-button {
  display: inline-flex;
  padding: map-get(bootstrap.$spacers, quarter);
  gap: map-get(bootstrap.$spacers, quarter);
  border-radius: variables.$border-radius-lg;
  background-color: variables.$element-3;
  svg {
    font-size: 24px;
  }
  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.option {
  color: variables.$foreground-medium;
  border-radius: variables.$border-radius-lg;
  padding: 10px map-get(bootstrap.$spacers, 2);
  transition: 0.6s ease all;

  &_active {
    @extend .option;
    color: variables.$primary-1;
    background-color: variables.$surface-1;

    &.disabled {
      color: variables.$foreground-medium;
      background-color: variables.$element-disabled;
    }
  }
}
