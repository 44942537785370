@use "src/scss/variables";
@use "src/scss/functions";

.wrap {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.5s ease, max-height 0.5s ease;
  overflow: hidden;
  .wrapInner {
    padding: functions.get-rem(12px) functions.get-rem(16px);
    border-radius: 4px;
    border: 1px solid variables.$information-300;
    background: variables.$information-050;
    font-size: 14px;
    line-height: 1.3;
    .link {
      color: variables.$information-700;
      text-decoration: underline;
      display: block;
    }
    a {
      color: variables.$information-700;
      text-decoration: underline;
    }
  }
  &.fadeIn {
    opacity: 1;
    max-height: 650px;
  }
  &.fadeOut {
    opacity: 0;
    max-height: 0;
  }
  &.marginRight {
    margin-right: functions.get-rem(40px);
  }
}
