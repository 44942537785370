@use "scss/variables";
@use "scss/functions";
.card {
  background: variables.$element-3;
  padding: functions.get-rem(12px) functions.get-rem(16px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  isolation: isolate;
  border-radius: 4px;
  position: relative;
  .title {
    font-size: functions.get-rem(14px);
    line-height: functions.get-rem(16px);
    padding-right: 22px;
    text-overflow: ellipsis;
    max-width: 220px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  .description {
    font-size: functions.get-rem(12px);
    line-height: functions.get-rem(16px);
    color: variables.$foreground-high;
  }
  .editIcon{
    color: #141414;
    opacity: 0.5;
    cursor: pointer;
    right: 4px;
    top: 6px;
    position: absolute;
  }
}
