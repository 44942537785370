@use "scss/variables";
@use "scss/functions";

.content {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: functions.get-rem(24px);
  background-color: variables.$surface-1;
}
