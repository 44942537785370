@use "scss/variables";
@use "scss/functions";

.modal {
  min-width: 640px;
}

.shares-not-match {
  position: relative;
  background-color: variables.$warning-050;

  svg {
    flex-shrink: 0;
  }

  .close-btn {
    cursor: pointer;
    top: 16px;
    right: 16px;
    position: absolute;
  }
}
