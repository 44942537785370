@use "scss/variables";
@use "scss/functions";

.tooltip {
  max-width: 224px;
  text-align: left;
}
.category {
  color: variables.$foreground-medium;
  text-align: left;
  margin-bottom: functions.get-rem(16px);
}
.wrap {
  padding: functions.get-rem(16px);
  border: 1px solid variables.$stroke-medium;
  text-align: left;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: #F7F1F5;
  }
  &.disabled {
    cursor: not-allowed;
    //opacity: 0.5;
    background: #F6F4F3;
    color: variables.$foreground-disabled;
    .arrow {
      color: variables.$foreground-disabled;
    }
  }
  .icon {
    font-size: functions.get-rem(32px);
  }
  .description {
    color: variables.$foreground-low;
  }
  .arrow {
    color: variables.$primary-1;
  }
}