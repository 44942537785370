@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.wrap {
  padding: functions.get-rem(40px);
  max-width: functions.get-rem(968px);
  background-color: variables.$surface-1;

  .calculationMethod {
    background-color: variables.$surface-1;
    margin-top: functions.get-rem(16px);
    border-radius: 4px;
    border: 1px solid variables.$stroke-high;
    padding: 20px;
    padding-right: 54px;

    &.selected {
      border: 1px solid variables.$primary-1;
    }

    .typeRadio {

      display: flex;
      gap: 10px;

      input {
        flex-shrink: 0
      }
    }
  }
}