@use "scss/variables";

.Toastify {
  &__toast {
    &-icon {
      display: none !important;
    }
    &-body {
      text-align: left !important;
    }
    & > button {
      color: variables.$foreground-high;
      opacity: 1 !important;
      transition: 0.2s;
      &:hover {
        color: #d9d8d8;
      }
    }
  }
  &__close {
    &-button {
      margin-top: 10px;
    }
  }
}

.error-toast {
  color: variables.$foreground-high !important;
  background-color: variables.$critical-050 !important;
}

.warning-toast {
  color: variables.$foreground-high !important;
  background-color: variables.$warning-050 !important;
}

.success-toast {
  color: variables.$foreground-high !important;
  background-color: variables.$positive-050 !important;
}

.regular-toast {
  color: variables.$foreground-high !important;
  background-color: variables.$critical-050 !important;
}

.info-toast {
  color: variables.$foreground-high !important;
  background-color: variables.$information-050 !important;
}

.toast-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
}
