@use "scss/variables";
@use "scss/functions";

.table {
  margin-bottom: 0 !important;
  border-collapse: separate;
  border-spacing: 0;

  /*tbody td {
    border-bottom: 1px solid red;
  }*/

  /*  tbody >tr:nth-child(even) td {
      background-color: #f6f4f3!important;
    }*/


  &.bordered {
    &:not(:has(tfoot)) tbody tr:last-child {

      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    thead tr:first-child {
      :first-child {
        border-top-left-radius: 8px;
      }

      :last-child {
        border-top-right-radius: 8px;
      }
    }

    tfoot tr:last-child {
      :first-child {
        border-bottom-left-radius: 8px;
      }

      :last-child {
        border-bottom-right-radius: 8px;
      }
    }

    th:first-child, td:first-child {
      border-left: 1px solid variables.$stroke-medium;
    }

    th:last-child, td:last-child {
      border-right: 1px solid variables.$stroke-medium;
    }

    th {
      border-top: 1px solid variables.$stroke-medium;
    }

  }


  &.fundraiseRound {
    th:first-child, td:first-child {
      border-left: 1px solid variables.$stroke-medium;
    }

    th:last-child, td:last-child {
      border-right: 1px solid variables.$stroke-medium;
    }

    th {
      border-top: 1px solid variables.$stroke-medium;
    }

    &:not(:has(tfoot)) tbody tr:last-child {

      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    tfoot {
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }

    thead tr:first-child {
      :first-child {
        border-top-left-radius: 0;
      }

      :last-child {
        border-top-right-radius: 0;
      }
    }
  }


  &.capTable {
    th:first-child, td:first-child {
      border-left: 1px solid variables.$stroke-medium;
    }

    th:last-child, td:last-child {
      border-right: 1px solid variables.$stroke-medium;
    }

    th {
      border-top: 1px solid variables.$stroke-medium;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    tfoot {
      tr td {
        background-color: variables.$element-1;
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      td {
        border-bottom: 1px solid variables.$foreground-on-primary;
      }
    }
  }


  ////// default styles
  td {
    color: variables.$foreground-high;
    font-size: functions.get-rem(14px);
    line-height: 1.3;
  }

  thead {
    tr {
      th {
        span {
          font-weight: 400;
          user-select: none;
          font-size: 12px;
          color: variables.$foreground-medium;
          white-space: break-spaces;
        }

        .sorting {
          margin-left: 4px;
        }

        padding: 0 16px;
        height: 40px;
        vertical-align: middle;

      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0 16px;
        height: 64px;
        vertical-align: middle;
      }
    }
  }

  tfoot {
    tr {
      td {
        padding: 12px 16px;
      }
    }
  }


  :global {
    .right {
      justify-content: right;
      text-align: right;
    }

    .center {
      justify-content: center;
      text-align: center;
    }
  }

  .importantCell {
    background-color: variables.$information-050;
  }

  &.withHover {
    tr {
      &:hover {
        td {
          background: variables.$additional-3-400 !important;
          cursor: pointer;
        }
      }
    }
  }

  /////// default styles end

}

.table-wrapper {
  width: 100%;
  position: relative;

  .leftShadow {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 12px;
    content: "";
    z-index: 1;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 100%);
    opacity: 0;
  }

  .rightShadow {
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 12px;
    content: "";
    z-index: 1;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 100%);
    opacity: 0;
  }
}

/// scrollable table
.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;

  thead {
    tr {
      th {
        span {
          white-space: nowrap!important;
        }
      }
    }
  }


  &::-webkit-scrollbar {
    height: 5px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background: variables.$surface-1;
    border-radius: 5px;

  }

  &::-webkit-scrollbar-thumb {
    background: variables.$additional-1-550;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: variables.$additional-1-550;
  }
}


/*.scrollable-table {
  border-collapse: collapse;
  width: inherit;
}

.scrollable-table th, .scrollable-table td {
  white-space: nowrap;
}

.scrollable-table th:first-child, .scrollable-table td:first-child,
.scrollable-table th:last-child, .scrollable-table td:last-child {
  position: sticky;
  z-index: 1;
  padding: 0;
}

.scrollable-table th:first-child, .scrollable-table td:first-child {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background-color: variables.$stroke-medium;
  }
}

.scrollable-table th:last-child, .scrollable-table td:last-child {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background-color: variables.$stroke-medium;
  }
}


.scrollable-table th:first-child, .scrollable-table td:first-child {
  left: 0;
}

.scrollable-table th:last-child, .scrollable-table td:last-child {
  right: 0;
}

}*/
