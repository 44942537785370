@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";

.container {
  border-radius: 4px;
  padding: 24px 32px;
  background-color: variables.$surface-1;
}

.tooltip {
  padding: 0 16px;
  display: flex;
  border-radius: 4px;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  height: functions.get-rem(58px);
  width: functions.get-rem(110px);
  transform: translate(-50%, -170%);
  background-color: variables.$surface-1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);

  p {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .triangle {
    bottom: -20%;
    left: 38%;
    position: absolute;
  }
}

.legend {
  display: flex;

  div {
    display: flex;
    align-items: center;

    p {
      color: variables.$foreground-medium;
    }
  }

  div:first-child {
    span {
      height: 2px;
      width: functions.get-rem(20px);
      background-color: variables.$positive-500;
    }
  }

  div:last-child {
    span {
      width: functions.get-rem(20px);
      border-top: 2px dashed variables.$primary-1;
    }
  }

  &.is-losted {
    div:nth-child(2) {
      span {
        border-top: 4px dashed variables.$critical-700;
      }
    }
  }
}

.empty-image-container {
  width: 100%;
  position: relative;
  height: functions.get-rem(140px);

  img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
