@use "scss/variables";
@use "scss/functions";

.draftTable {
  background-color: variables.$secondary-1;
  border-radius: functions.get-rem(8px);
  padding: functions.get-rem(16px);
}

.wrap {
  .subtitle {
    font-size: functions.get-rem(20px);
    line-height: 1.3;
    font-weight: 400;
    font-family: variables.$font-family-headlines;
  }

  :global {
    .head-xxxs {
      margin-bottom: functions.get-em(22px, variables.$head-xxxs);
    }
  }
}

.name-form-group {
  margin-bottom: functions.get-rem(40px);
}

.got-this {
  text-decoration: underline;
  color: inherit;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: variables.$stroke-high;
}
