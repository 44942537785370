@use "scss/variables";
@use "scss/functions";

.plan-allocation-message {
  display: flex;
  align-items: flex-start;
  line-height: variables.$line-height-lg;
  border-radius: functions.get-rem(variables.$border-radius);

  &.success {
    background-color: variables.$positive-050;

    svg {
      color: variables.$positive-500;
    }
  }

  &.error {
    background-color: variables.$warning-050;

    svg {
      color: variables.$warning-500;
    }
  }

  svg {
    flex-shrink: 0;
    color: inherit;
  }
}
