@use "scss/variables";
@use "scss/functions";

.neutral-plate {
  border-radius: 8px;
  padding: functions.get-em(20px) functions.get-em(20px);
  background-color: variables.$element-1;
}

.share-details-title {
  font-style: variables.$font-family-base;
  font-weight: variables.$font-weight-bold;
  font-size: variables.$paragraph-m;
}

.program-details-blocks {
  width: 50%;
}

.vesting-years {
  max-width: 160px;
}

.company-relationship {
  margin-top: 2px;
}
