@use 'scss/variables';

.mega-dropdown {
  &-container {
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.1),
      0px 2px 8px rgba(0, 0, 0, 0.15);

    &-error {
      box-shadow: 0px 2px 2px rgba(255, 121, 121, 0.15),
        0px 0px 4px rgba(255, 121, 121, 0.15),
        0px 2px 8px rgba(255, 121, 121, 0.15);
    }
  }

  &-header {
    padding: 0 !important;
    border: none !important;
    border-radius: 10px !important;
    background-color: white !important;
  }

  &-arrow {
    transition: all 0.25s;

    &-active {
      transform: rotate(90deg);
    }
  }

  &-submit-btn {
    height: 44px;
    margin-left: 24px;
    width: 134px !important;
    box-shadow: none !important;
    border: 2px solid black !important;
    background-color: transparent !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &-collapse-container {
    padding: 0 36px 44px 34px;
  }

  &-horizontal-line {
    left: 0;
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: variables.$base-typography-color;
  }
}
