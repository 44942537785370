@use "scss/variables";
@use "scss/functions";

.off-canvas {
  border: unset;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 2000 !important;

  &:global {
    &.offcanvas {
      top: 50% !important;
      left: 50% !important;
      height: fit-content;
      width: max-content !important;
      max-width: functions.get-rem(400px);
      border-radius: functions.get-rem(variables.$border-radius);

      &.show {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) !important;
      }
    }

    &.offcanvas-end {
      opacity: 0;
      transform: scale(0) !important;
    }
  }
}

.close-button {
  &:global {
    &.btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  svg {
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);

    path {
      fill: variables.$foreground-high;
    }
  }
}
