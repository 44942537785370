@use "scss/variables";
@use "scss/functions";

.wrap {
  .info {
    background: variables.$element-2;
    padding: functions.get-rem(25px);
    border-radius: functions.get-rem(8px);
    .userInfo {
      display: flex;
      justify-content: space-between;
      .userAvatar{
        display: flex;
      }
      .company {
        color: variables.$foreground-low;
      }
    }
    .document {
      color: variables.$primary-1;
      display: flex;
      align-items: center;
      span {
        font-size: functions.get-rem(24px);
        display: flex;
        margin-right: 8px;
      }
      a {
        color: variables.$primary-1;
        text-decoration: underline;
        line-height: 1.3;
      }
    }
  }
  .conversion {
    .infoCards {
      display: flex;
      gap: 19px;
    }
    .remainInvestment {
      color: variables.$foreground-on-secondary;
      margin-top: 36px;
      font-weight: 500;
    }
    .calculate {
      display: flex;
      justify-content: space-between;
    }
  }
}