@use "scss/variables";
@use "scss/functions";

.wrap {
  :global {
    .head-xxxs {
      margin-bottom: functions.get-em(22px, variables.$head-xxxs);
    }
  }
}

.name-form-group {
  margin-bottom: functions.get-rem(40px);
}

.got-this {
  text-decoration: underline;
  color: inherit;
}

.vesting-details {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: functions.get-em(8px);

  .icon {
    path {
      stroke: variables.$foreground-low;
    }

    &:hover {
      path {
        stroke: variables.$information-700;
      }
    }
  }
}

.vesting-detail {
  display: flex;
  border-radius: 4px;
  align-items: center;
  padding: 0 functions.get-em(16px);
  justify-content: space-between;
  width: functions.get-em(207px);
  height: functions.get-em(53px);
  background-color: variables.$additional-1-200;

  svg {
    path {
      width: functions.get-em(24px);
      height: functions.get-em(24px);
      stroke: variables.$foreground-medium;
    }
  }
}

.progress-chart {
  width: 100%;
  margin: 0 auto;
}

.shares-element {
  margin-right: 24px;
  color: variables.$foreground-medium;
}

.used-shares {
  margin-right: functions.get-rem(8px);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: variables.$positive-300;
}

.draft-shares {
  margin-right: functions.get-rem(8px);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: variables.$warning-050;
}

.unused-shares {
  margin-right: functions.get-rem(8px);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border: 1px dashed variables.$positive-900;
}

.shareholders-table,
.createPlanActions {
  width: 100%;
}

.planTable {
  margin-bottom: 0;

  tr {
    td {
      height: 58px;
      padding-top: functions.get-rem(10px);
      padding-bottom: functions.get-rem(10px);
      vertical-align: middle;
      border: 0;

      &:last-child {
        text-align: right;
      }

      &:first-child {
        width: 50%;
      }
    }
  }
}

.avatar {
  padding: 0;
  width: functions.get-rem(28px) !important;
  min-width: functions.get-rem(28px) !important;
  height: functions.get-rem(28px) !important;
  font-size: 12px !important;
}
