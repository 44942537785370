@use "scss/variables";
@use "scss/functions";

@use "../PoolItem.module";

.wrap {
  &:global {
    &.accordion-body {
      padding: functions.get-rem(8px) functions.get-rem(32px) functions.get-rem(40px) functions.get-rem(32px);
    }
  }
}

.pie-chart-wrap {
  display: flex;
  align-items: center;
}

.pie-chart {
  width: functions.get-em(180px, PoolItem.$pool-item-font-size);
  height: functions.get-em(180px, PoolItem.$pool-item-font-size);
  margin-right: functions.get-em(4px, PoolItem.$pool-item-font-size);

  svg {
    margin-left: -8px;
    path {
      &:last-child {
        stroke-width: 1px;
        stroke-dasharray: 4;
        transform: scale(0.995);
        stroke: variables.$additional-7-500;
      }
    }
  }
}

.shares-legend {
  &__item {
    font-size: 14px;
    margin-bottom: functions.get-em(20px, PoolItem.$pool-item-font-size);

    &:last-child {
      margin-bottom: 0;
    }

    &__value {
      display: flex;
      font-size: 24px;
      font-weight: bold;
      align-items: center;
    }

    &__description {
      display: flex;
      align-items: center;
      color: variables.$foreground-medium;

      span {
        display: flex;
        flex-shrink: 0;
        border-radius: 50%;
        width: functions.get-rem(8px);
        height: functions.get-rem(8px);
        margin-right: functions.get-rem(3px);
        border: 1px dashed variables.$primary-1;
      }
    }
  }
}

.progress-bar {
  &:global(.progress) {
    height: functions.get-em(20px, PoolItem.$pool-item-font-size);
    border-radius: functions.get-em(20px, PoolItem.$pool-item-font-size);
    margin-bottom: functions.get-em(12px, PoolItem.$pool-item-font-size);
  }
}

.description {
  line-height: variables.$line-height-lg;
  margin-bottom: functions.get-em(30px, PoolItem.$pool-item-font-size);
}

.content {
  border-radius: functions.get-rem(8px);
  background-color: variables.$element-1;
  padding: functions.get-rem(24px) functions.get-rem(32px) functions.get-rem(32px) functions.get-rem(32px);
}

.separator {
  height: 1px;
  width: 100%;
  background-color: variables.$additional-6-200;
  margin: functions.get-rem(28px) 0 functions.get-rem(40px) 0;
}

.background-surface {
  background-color: variables.$surface-1;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$foreground-low;
  background-color: variables.$element-3;
  border-radius: 16px;
  width: 20px;
  height: 20px;

}
