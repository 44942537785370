@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.context-wrapper {
  position: relative;
  max-width: functions.get-rem(240px);

  .toggle {
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    height: functions.get-rem(48px);
    background-color: variables.$surface-1;
    padding: map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 2);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .text-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: variables.$foreground-high !important;

    .company-name {
      font-weight: 500;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.chevron {
  margin-left: 12px;
  svg {
    path {
      stroke-width: 10px;
      fill: variables.$foreground-medium;
    }
  }
}

.context-menu {
  padding: 15px;
  position: absolute;
  border-radius: 4px;
  transform-origin: 50% -30px;
  width: functions.get-rem(248px);
  right: 0;
  top: map-get(bootstrap.$spacers, 7);
  background-color: variables.$element-1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.1),
    0px 2px 8px rgba(0, 0, 0, 0.15);
}
