@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// TODO: SCSS NON variable USAGE

.context-menu {
  right: 0;
  z-index: 99;
  position: absolute;
  border-radius: 4px;
  transform-origin: 50% -30px;
  width: functions.get-rem(248px);
  top: map-get(bootstrap.$spacers, 7);
  background-color: variables.$surface-1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.15);

  @include media-breakpoint-down(md) {
    left: 0;
    width: 100%;
    border-radius: 0;
    top: functions.get-rem(90px);
    background-color: variables.$element-1;
  }

  .head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid variables.$additional-1-500;
    padding: functions.get-rem(20px) map-get(bootstrap.$spacers, 3);

    .image {
      border-radius: 50%;
      width: functions.get-rem(40px);
      height: functions.get-rem(40px);
    }

    .info {
      margin-left: 12px;
      color: variables.$foreground-high;
    }
  }

  .account-section {
    border-bottom: 1px solid variables.$additional-1-500;
    padding: functions.get-rem(12px) 0;

    .link {
      display: flex;
      font-weight: 500;
      align-items: center;
      transition: all 0.6s;
      text-decoration: none;
      color: variables.$foreground-medium;
      padding: map-get(bootstrap.$spacers, 1) functions.get-rem(18px);

      &:hover {
        background-color: variables.$element-1;
      }

      svg {
        width: functions.get-rem(20px);
        height: functions.get-rem(20px);

        path {
          stroke: variables.$foreground-medium;
        }
      }
    }
  }

  .companies-section {
    font-weight: 500;

    .title {
      color: variables.$foreground-medium;
    }

    .add-button {
      padding: 0;
      height: auto;
      border-radius: 0;
      color: variables.$primary-1;
      border-bottom: 1px solid variables.$primary-1;
    }

    .company {
      cursor: pointer;
      font-weight: 500;
      overflow: hidden;
      position: relative;
      transition: all 0.6s;
      height: functions.get-rem(40px);
      color: variables.$foreground-medium;
      padding: functions.get-rem(10px) map-get(bootstrap.$spacers, 2);

      .settings {
        top: 0;
        right: 0;
        position: absolute;
        height: functions.get-rem(40px);
        display: flex;
        align-items: center;
        padding: 0 map-get(bootstrap.$spacers, 2) 0 map-get(bootstrap.$spacers, 4);
        background: linear-gradient(270deg, variables.$secondary-1 49.18%, rgba(216, 195, 239, 0) 95.87%),
          linear-gradient(270deg, variables.$secondary-1 43.44%, rgba(216, 195, 239, 0) 100%);
      }

      &:hover {
        font-weight: 700;
      }
    }

    .active {
      font-weight: bold !important;
      color: variables.$primary-1;
    }

    .disabled {
      cursor: not-allowed !important;

      &.company {
        &:hover {
          font-weight: 500;
        }
      }
    }
  }

  .collapsible {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    height: functions.get-rem(40px);
    background-color: variables.$element-2;
    padding: 0 map-get(bootstrap.$spacers, 2);
    border-bottom: 1px solid variables.$additional-1-500;

    .title {
      font-size: 14px;
      color: variables.$primary-1;
    }

    svg {
      color: variables.$foreground-medium;
    }

    .companies-counter {
      width: 16px;
      height: 16px;
      display: flex;
      font-weight: 500;
      margin-left: 4px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: variables.$foreground-low;
      background-color: variables.$element-3;
    }
  }

  .logout {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: functions.get-rem(40px);
    background-color: variables.$surface-1;
    border-top: 1px solid variables.$additional-1-500;

    p {
      font-weight: 500;
      color: variables.$foreground-medium;
    }
  }
}

.loader-container {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.136);

  .loader {
    top: 40%;
    left: 45%;
    position: absolute;
  }
}
