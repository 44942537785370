@use "scss/functions";
@use "scss/variables";

.possibilities {
  display: flex;
  justify-content: space-between;
  padding: functions.get-rem(24px) 0;
  border-top: 1px solid variables.$additional-12-100;

  &.with-border-bottom {
    border-bottom: 1px solid variables.$additional-12-100;
  }
}
