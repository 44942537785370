@use "scss/variables";
@use "scss/functions";

@mixin th-td {
  th,
  td {
    @content;
  }
}

.modal {
  :global {
    .modal-dialog {
      min-width: 1067px !important;

      .modal-content {
        padding: 0;
      }
    }
  }
}

.top-container {
  height: auto;
  display: flex;
  position: relative;
  border-radius: 7px;
  align-items: center;
  min-height: functions.get-rem(360px);
  background-color: variables.$element-2;

  .section {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;

    &:first-child {
      align-items: center;
    }

    &.bordered {
      border-left: 1px solid variables.$stroke-medium;
    }
  }
}

.details-container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid variables.$stroke-medium;
}

.head {
  padding-top: functions.get-rem(12px);
  padding-bottom: functions.get-rem(12px);
  border-bottom: 1px solid variables.$stroke-medium;
}

.pledges {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: functions.get-rem(16px);
  background-color: variables.$information-050;
}

.table {
  width: 100%;
  margin-bottom: 0 !important;
  border-collapse: separate;
  border-spacing: 0 functions.get-rem(1px) !important;

  th:first-child, td:first-child {
    min-width: 40%;
  }
  th:last-child, td:last-child {
    min-width: 30%;
  }

  th,
  td {
    border: none !important;
  }

  th {
    border: 1px solid variables.$stroke-medium !important;

    &.head-item {
      &.no-border {
        border: none !important;
      }
    }

    &:first-child {
      border-right: none !important;
      border-bottom-left-radius: 0 !important;
    }

    &:nth-child(2),
    &:nth-child(3) {
      border-right: none !important;
      border-left: none !important;
    }

    &:last-child {
      border-left: none !important;
    }
  }

  tr {
    vertical-align: middle;

    th {
      cursor: pointer;
      height: functions.get-rem(40px);
      color: variables.$foreground-medium;
      padding: 0 functions.get-rem(32px) !important;
      border-bottom: none !important;

      &:first-child {
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
      }
    }

    td {
      padding: functions.get-rem(16px) functions.get-rem(32px);
      cursor: pointer;
      text-align: left;

      border-bottom: 1px solid variables.$stroke-medium !important;

      &:first-child {
        border-left: 1px solid variables.$stroke-medium !important;
        border-top-left-radius: 0 !important;
      }

      &:last-child {
        border-right: 1px solid variables.$stroke-medium !important;
        border-top-right-radius: 0 !important;
      }
    }

    &:first-child {
      td {
        border-top: 1px solid variables.$stroke-medium !important;
      }
    }
  }

  tbody > tr {
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }

    &.no-border {
      border: none !important;
    }
  }
}

.borderBottom {
  border-radius: 8px;
  border-bottom: 1px solid variables.$stroke-medium;
}

.tableBorder {
  position: relative;
  font-variant-numeric: tabular-nums !important;

  &.no-border {
    &:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }
}

.sort {
  display: flex;
  margin-left: 4px;
  width: functions.get-rem(24px);
  height: functions.get-rem(18px);

  svg {
    color: variables.$stroke-high;
    width: functions.get-rem(15px) !important;
    height: functions.get-rem(15px) !important;
  }
}

.avatar {
  padding: 0;
  font-size: 12px !important;
  width: functions.get-rem(24px) !important;
  min-width: functions.get-rem(24px) !important;
  height: functions.get-rem(24px) !important;
}

.info-section {
  flex: 1;
  text-align: left;
  padding: functions.get-rem(24px);
  border-radius: 8px;
  height: functions.get-rem(104px);
  border: 1px solid variables.$stroke-medium;
}

.close-btn {
  top: 24px;
  right: 24px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.image {
  width: functions.get-rem(96px);
  height: functions.get-rem(96px);
  font-size: 32px !important;
}

.pledged-container {
  padding-top: 32px;
  border-top: 1px solid variables.$stroke-medium;
  background-color: variables.$element-2;
}

.email-overlay {
  :global {
    .tooltip-inner {
      max-width: 180px !important;
    }
  }
}

.tabs-head {
  display: flex;
  position: relative;
  align-items: center;
  height: functions.get-rem(56px);
  background-color: variables.$element-2;
  border-bottom: 1px solid variables.$stroke-medium;
  transition: all 1s;

  &::before {
    transition: all 0.4s;

    content: "";
    height: 2px;
    background-color: variables.$primary-1;
    position: absolute;
    bottom: 0;
  }

  &.ownership {
    &::before {
      width: 14.5%;
      left: 0;
    }
  }

  &.instruments {
    &::before {
      width: 22%;
      left: 14.5%;
    }
  }

  &.transactions {
    &::before {
      width: 18.5%;
      left: 36.5%;
    }
  }
}

.tab-item {
  height: 100%;
  display: flex;
  cursor: pointer;
  padding: 0 32px;
  align-items: center;
  color: variables.$foreground-low;

  svg {
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);
  }

  &.active {
    color: variables.$primary-1;
  }

  &:nth-child(2) {
    min-width: functions.get-rem(240px);
  }

  &:nth-child(3) {
    min-width: functions.get-rem(195px);
  }
}

.badge {
  width: functions.get-rem(20px);
  height: functions.get-rem(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 8px;
  background-color: variables.$element-3;
  color: variables.$foreground-low;

  &.active {
    background-color: variables.$secondary-1;
    color: variables.$primary-1;
  }
}

.long-table {
  text-align: start !important;
  width: 1300px !important;
  display: block !important;
  white-space: nowrap !important;
}
