
.corner-modal {
  :global {
    .modal-dialog {
      position: fixed;
      top: 16px;
      right: 16px;
      width: 460px;
      margin: 0;
    }
  }
}
