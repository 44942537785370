@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

@use "~common/layout/WizardLayout/WizardContent/Content/Content.module" as WizardContent;

.wrap {
  background-color: WizardContent.$background-color;
}

.legend-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:global {
    & > .ui-xxs {
      color: variables.$primary-1;
    }
  }
}

.legend {
  display: flex;
  align-items: center;

  svg {
    > line {
      stroke: variables.$primary-1;
      stroke-width: 2px;
      stroke-dasharray: 4;
    }
  }

  :global {
    .ui-xxs {
      color: variables.$foreground-medium;
    }
  }
}

.chart {
  width: 100%;
  height: functions.get-rem(95px);

  svg {
    > g {
      > path,
      > circle {
        pointer-events: none;
      }

      > path {
        &[stroke="#{variables.$primary-1}"] {
          stroke-dasharray: 4;
        }
      }
    }
  }
}

.chart-tooltip {
  color: variables.$positive-500;
}

.y-axis,
.x-axis {
  display: flex;
  justify-content: space-between;
  color: variables.$foreground-medium;
}

$x-axis-padding-y: functions.get-rem(6px);
$x-axis-margin-y: functions.get-rem(10px);

.y-axis {
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: calc(
          #{functions.get-rem(variables.$ui-xs)} * #{variables.$line-height-base} + #{$x-axis-padding-y} + #{$x-axis-margin-y}
  );
  margin-right: functions.get-rem(10px);
}

.x-axis {
  flex-shrink: 0;
  align-items: center;
  border-top: 1px solid variables.$element-1;
  padding-top: $x-axis-padding-y;
  margin-top: $x-axis-margin-y;
}
