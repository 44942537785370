@use "scss/variables";
@use "scss/functions";

.head {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: functions.get-rem(82px);
  background-color: variables.$surface-1;
  padding: 0 functions.get-rem(20px) 0 functions.get-rem(32px);

  .countItems {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);
    background-color: variables.$additional-1-200;
  }

  .controls {
    display: flex;
    align-items: center;

    .chevron {
      width: functions.get-rem(24px);
      height: functions.get-rem(24px);

      path {
        fill: variables.$foreground-low;
      }
    }
  }
}

.content {
  padding: functions.get-rem(8px) 0;
  background-color: variables.$surface-1;

  &.empty {
    display: flex;
    align-items: center;
    color: variables.$foreground-low;
  }
}
