@use "scss/variables";
@use "scss/functions";

.avatarUploader {
  background-color: variables.$secondary-1;
  width: auto;
  min-width: functions.get-rem(250px);
  height: auto;
  margin-bottom: auto;
  .title {
    font-weight: 500 !important;
  }
}

.preview {
  circle,
  path {
    stroke: variables.$primary-1;
  }
}
