@use "scss/variables";
@use "scss/functions";

.wrap {
  margin: 0 auto;
  max-width: 1448px;
}

.shareClassAlign{
  input {
    text-align: right;
  }
}
.transactionNumber {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
