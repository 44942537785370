@use "scss/variables";

.plumColors {
  background-color: variables.$additional-7-100;
  border: 1px solid variables.$additional-7-100;
  color: variables.$primary-1;
  //box-sizing: border-box;

  &:focus,
  &:active {
    border: 1px solid variables.$primary-1;
    outline: none;
  }
  &:active {
    background-color: variables.$additional-7-200;
  }
}
