@use "scss/variables";
@use "scss/functions";

.icon {
  width: functions.get-rem(28px);
  height: functions.get-rem(28px);
}

.container {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  padding: functions.get-rem(40px);
  width: functions.get-rem(540px);
  min-height: functions.get-rem(544px);
  background-color: variables.$surface-1;

  .image {
    width: functions.get-rem(200px);
    height: functions.get-rem(200px);
  }
}
