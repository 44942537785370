@use "scss/variables";
@use "scss/functions";

.fileUploadButton {
  .uploadButton {
    border-radius: functions.get-rem(30px);
    cursor: pointer;
    padding: functions.get-rem(14px) functions.get-rem(24px);
    p {
      color: variables.$foreground-high !important;
    }
  }

  :global {
    .upload-button {
      // TODO: SCSS NON variable USAGE
      border: 2px solid #131313;
      box-sizing: border-box;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        // TODO: SCSS NON variable USAGE
        background-color: #131313;
        color: #fff;
      }
    }

    .unlisted-button {
      font-style: normal;
      padding: 0 5px;
      font-weight: 500 !important;
      font-family: variables.$font-family-base;

      &-primary {
        border-color: transparent !important;
        color: variables.$element-1 !important;
        background-color: variables.$primary-1 !important;

        &:hover {
          border-color: variables.$primary-1 !important;
          background-color: variables.$primary-1 !important;
        }

        &:focus {
          background-color: variables.$primary-1 !important;
          border: 2px solid #ffffff !important;
        }

        &:active {
          background-color: variables.$primary-1 !important;
        }
      }

      &-secondary {
        color: variables.$primary-1 !important;
        background-color: transparent !important;
        border: 1px solid variables.$primary-1 !important;

        svg {
          path {
            stroke: variables.$primary-1 !important;
          }
        }

        &:hover {
          color: variables.$foreground-on-primary !important;
          border: 1px solid variables.$primary-1 !important;
          background-color: variables.$primary-1 !important;

          svg {
            path {
              stroke: variables.$primary-1 !important;
            }
          }
        }

        &:active {
          color: variables.$primary-1 !important;

          &:focus {
            // TODO: SCSS NON variable USAGE
            border-color: #131313 !important;
            box-shadow: unset !important;
          }
        }

        &:focus {
          border-color: variables.$primary-1 !important;
          box-shadow: unset !important;

          &:hover {
            background-color: variables.$primary-1 !important;
          }
        }
      }

      &-tertiary {
        color: variables.$primary-1 !important;
        background-color: transparent !important;
        border: 2px solid transparent !important;

        svg {
          path {
            stroke: variables.$primary-1 !important;
          }
        }

        &:hover {
          color: variables.$primary-1 !important;
          border: 2px solid variables.$primary-1 !important;
          background-color: variables.$primary-1 !important;

          svg {
            path {
              stroke: variables.$primary-1 !important;
            }
          }
        }

        &:focus {
          border-color: transparent !important;

          &:hover {
            background-color: variables.$element-1 !important;
          }
        }

        &:active {
          color: variables.$primary-1 !important;

          svg {
            path {
              stroke: variables.$primary-1 !important;
            }
          }
        }
      }

      // TODO: SCSS NON variable USAGE
      &-disabled {
        opacity: 1 !important;
        color: #6b7176 !important;
        border-color: #eaebeb !important;
        background-color: #eaebeb !important;

        svg {
          path {
            stroke: #6b7176 !important;
          }
        }
      }

      &:hover {
        text-decoration: none !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgb(35, 35, 35) !important;
      }

      &:active {
        box-shadow: none !important;
      }

      &.btn > svg {
        &.button-left-icon {
          margin-left: 0;
          margin-right: 10px;

          path {
            stroke-width: 0;
          }
        }

        &.button-right-icon {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }
}
