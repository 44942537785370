@use "scss/variables";

.splitAccordion {
  border-radius: 8px;
  background-color: variables.$surface-1;
}

.body {
  border-top: 1px solid variables.$stroke-medium;
}
