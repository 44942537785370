@use "scss/functions";
@use "scss/variables";

.company-info {
  border-radius: 4px;
  padding: functions.get-rem(8px);
  background-color: variables.$element-1;

  .checkbox {
    padding-left: 0;

    &:global {
      &.form-check {
        .form-check-input {
          &:focus {
            ~ .form-check-label {
              box-shadow: none !important;
            }
          }

          &:not(:disabled) ~ .form-check-label,
          &:not([disabled]) ~ .form-check-label {
            &:hover {
              background-color: variables.$element-1;
            }
          }
        }
      }
    }
  }
}

.date-picker {
  width: 100% !important;
}

.helper-content {
  display: flex;
  align-items: center;

  svg {
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);
  }
}
