@use "scss/variables";

.dialog {
  width: 640px;

}
.infoBanner {
  background: variables.$element-2;
  padding: 12px 16px;
  border-radius: 4px;
  gap: 12px;
  color: variables.$foreground-high;
  svg {
    color: variables.$foreground-low;
  }
}