@use "scss/variables";
@use "scss/functions";

.collapsible-head {
  position: relative;
  border-radius: 4px;
  background-color: variables.$surface-1;

  :global {
    .card-header {
      div {
        display: flex;
        border-radius: 4px;
        align-items: center;
        height: functions.get-rem(80px);
      }
    }
  }
}

.description {
  position: absolute;
  top: functions.get-rem(52px);
  left: functions.get-rem(24px);
  color: variables.$foreground-low;
}

.table {
  &-head {
    height: functions.get-rem(40px);
    border: none !important;

    tr th {
      font-weight: 400;
      color: variables.$foreground-medium;
      border: none !important;

      &:first-child {
        padding-left: 24px;
      }
    }
  }
}

.table-row {
  td {
    vertical-align: middle;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: functions.get-rem(64px);
    border-color: variables.$additional-1-200 !important;
    border-width: 1px !important;

    &:nth-child(1) {
      width: 40%;
      padding-left: 24px !important;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3),
    &:nth-child(4) {
      width: 15%;
    }
  }

  &:last-child {
    td {
      border: none !important;
    }
  }
}

.truncate {
  max-width: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


