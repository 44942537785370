@mixin canela-text {
  font-family: "Canela Text", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@mixin text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin view-container {
  margin: 0 auto !important;
  max-width: 1680px !important;
}

;@import "sass-embedded-legacy-load-done:5";