@use "scss/functions";
@use "scss/variables";

@use "./Content/Content.module" as Content;

.wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 24px;
  max-width: functions.get-rem(814px);
}

.spinner-wrap {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: Content.$border-radius;
  background-color: rgba(variables.$element-1, 0.7);
}

.step-info {
  z-index: 10;
  position: absolute;
  top: functions.get-rem(86px);
  right: functions.get-rem(88px);
  color: variables.$foreground-medium;
}
