@use "../../../../scss/variables";
@use "../../../../scss/functions";

.head {
  cursor: pointer;
  background-color: variables.$surface-1;

  &.opened {
    background-color: variables.$surface-1;
  }
}

.container {
  position: relative;
  border-radius: 8px;
  background-color: variables.$surface-1;
  padding: functions.get-rem(24px) functions.get-rem(24px) functions.get-rem(24px) functions.get-rem(72px);

  &.opened {
    background-color: variables.$surface-1;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &.checked {
    box-shadow: none;
    border: 2px solid variables.$positive-500;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .point {
    position: absolute;
    left: functions.get-rem(24px);
  }

  .subtitle {
    color: variables.$foreground-medium;
  }

  .checkbox-label {
    a:visited {
      color: variables.$foreground-high;
    }

    & > * {
      color: variables.$primary-1;
    }

    svg {
      path {
        stroke: variables.$primary-1;
      }
    }

    &.checked {
      a:visited,
      & > * {
        color: variables.$positive-900;
      }

      svg {
        path {
          stroke: variables.$positive-900;
        }
      }
    }
  }

  .hint {
    font-style: italic;
    color: variables.$foreground-medium;
  }

}
