@use "scss/variables";
@use "scss/functions";

.details {
  text-decoration: underline;
  font-size: 14px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.details-popover {
  &:global {
    &.popover {
      max-width: none;
    }
  }
}

.info-card {
  width: functions.get-rem(285px);
}

.add-button {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: transparent !important;
  }
}
