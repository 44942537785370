@use "scss/variables";
@use "scss/functions";

.wrap {
  gap:12px;
  padding: 12px 16px;
  background: variables.$warning-050;
  border-radius: 4px;
  svg {
    color: variables.$warning-500;
  }
}