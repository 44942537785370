@use "scss/variables";
@use "scss/functions";

.wrap {
  position: relative;
  height: functions.get-rem(64px) !important;

  &.large {
    height: functions.get-rem(144px) !important;
  }
}

.form-control {
  max-height: functions.get-rem(64px) !important;
  height: functions.get-rem(64px) !important;

  &.large {
    height: functions.get-rem(144px) !important;
    max-height: functions.get-rem(144px) !important;
  }

  &.error {
    &:global {
      &.form-control {
        height: functions.get-rem(64px) !important;
        max-height: functions.get-rem(64px) !important;

        &.large {
          height: functions.get-rem(144px) !important;
          max-height: functions.get-rem(144px) !important;
        }

        &::after {
          border-color: variables.$warning-500 !important;
        }
      }
    }
  }

  &:global {
    &.form-control {
      color: transparent !important;
      border: none !important;
      border-radius: variables.$border-radius-xl;
      background-color: variables.$element-3;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: variables.$element-3;
        border-radius: functions.get-rem(16px);
        height: functions.get-rem(64px) !important;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgb(191, 179, 188)' stroke-width='1' stroke-dasharray='6%2c 4' stroke-dashoffset='56' stroke-linecap='square'/%3e%3c/svg%3e");
      }

      @-moz-document url-prefix() {
        border: 1px dashed variables.$stroke-high !important;

        &::file-selector-button {
          display: none !important;
        }
      }
    }
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding-left: functions.get-rem(32px);

  @-moz-document url-prefix() {
    height: auto;
    margin-top: 0.5%;
  }

  &.large {
    padding-left: 0;
    height: functions.get-rem(144px);
    flex-direction: column;
    justify-content: center;
    border-radius: functions.get-rem(16px);
    background-color: variables.$surface-1 !important;
    border: 1px dashed variables.$stroke-high !important;

    &.dragging {
      border: 1px solid variables.$primary-1 !important;
      background-color: variables.$secondary-1 !important;
    }
  }

  &.dragging {
    border-radius: functions.get-rem(16px);
    border: 1px solid variables.$primary-1;
    background-color: variables.$secondary-1 !important;
  }
}

.icon {
  width: functions.get-em(42px);
  height: functions.get-em(42px);

  path {
    stroke-width: 1;
  }
}

.label {
  span {
    text-decoration: underline;
  }
}
