@use "scss/variables";
@use "scss/functions";

.wizard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: variables.$background-1;
  overflow-y: scroll;
}

.maxWidth {
  max-width: 80%;
}

.wrap {
  margin: 0 auto;
  min-height: 90%;
}

.shareClassAlign{
  input {
    text-align: right;
  }
}
.transactionNumber {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.redPlate {
  background-color: variables.$critical-050;
  color: variables.$critical-900;
};

.darkBackground {
  background-color: variables.$element-1;
}

.lightBackground {
  background-color: variables.$surface-1;
}
