@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";

.valuation {
  width: 100%;
  border-radius: 4px;
  position: relative;
  padding: 27px 0 0 32px;
  background-color: variables.$surface-1;
  border-bottom: 1px solid variables.$surface-1;

  .chart-title {
    top: 65%;
    left: 16px;
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: top left;
    color: variables.$foreground-medium;
  }

  .legend {
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
      color: variables.$primary-1;
      margin-right: map-get(bootstrap.$spacers, 1);
    }
  }
}

.tooltip {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: functions.get-rem(40px);
  width: functions.get-rem(170px);
  transform: translate(-50%, -130%);
  background-color: variables.$surface-1;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);

  p {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .triangle {
    bottom: -38%;
    position: absolute;
  }
}
