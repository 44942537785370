@use "scss/variables";
@use "scss/functions";

.container {
  width: 100%;
  position: relative;
  background-color: variables.$surface-1;
  padding: functions.get-rem(44px) functions.get-rem(63px);
}

.close-btn {
  position: absolute;
  top: functions.get-rem(18px);
  right: functions.get-rem(18px);
}
