@use "scss/variables";
@use "scss/functions";

.tooltip {
  opacity: 1!important;
  :global {
    .tooltip-inner {
      background-color: white;
      color: variables.$foreground-low;
      font-size: functions.get-rem(14px);
      line-height: functions.get-rem(16px);
      border: 1px solid #BFB3BC;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.10);
      border-radius: 4px;
      padding: functions.get-rem(12px) functions.get-rem(16px);
      max-width: 352px;
    }
    /*.tooltip-arrow:before {
      border-top-color: variables.$foreground-low!important;
    }*/
  }
}
