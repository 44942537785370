@use "scss/variables";

.bodyContainer {
  border-radius: 8px;

  .border {
    border-bottom: 1px solid variables.$additional-1-550;
  }

  &.darkBackground {
    background-color: variables.$element-1 !important;
  }
}