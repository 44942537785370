@use "scss/variables";
@use "scss/functions";

.wrap {
  .squeezed {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 4.5em !important;

    div > p {
      max-height: 4.5em !important;
    }
  }

  .read-more {
    cursor: pointer;
    text-decoration: underline;
    margin-top: functions.get-rem(4px);
  }
}

.description-modal {
  :global {
    .modal-dialog {
      max-width: functions.get-rem(690px) !important;
    }
  }

  .content {
    min-height: 60vh;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background-color: variables.$surface-1;

    button {
      margin-top: auto;
      width: max-content;
    }
  }
}
