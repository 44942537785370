@use "scss/variables";
@use "scss/functions";
@use "scss/mixins";

.backdrop {
  opacity: 0 !important;
}

.header {
  top: 0;
  left: 50%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  transform: translateX(-50%);
  justify-content: space-between;
  height: functions.get-rem(90px);

  svg {
    width: functions.get-rem(28px);
    height: functions.get-rem(28px);
  }

  @include mixins.view-container;
}

.container {
  &:global {
    &.offcanvas.offcanvas-end {
      opacity: 0;
      transition: opacity 0.5s;
      transform: translateY(1px);
    }

    &.offcanvas.show:not(.hiding) {
      opacity: 1;
    }
  }

  display: flex;
  width: 100vw !important;
  border: none !important;
  background-color: variables.$background-2 !important;

  .content {
    margin: auto;
    max-width: functions.get-rem(920px);
    margin-top: functions.get-rem(114px);
    padding-bottom: functions.get-rem(40px);
  }
}
