@use "scss/functions";
@use "scss/variables";

.sidebar {
  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  top: functions.get-rem(4px);
  margin-top: functions.get-rem(5px);

  &.sticky {
    position: sticky;
  }

  &:first-child {
    margin-left: functions.get-rem(8px);
  }

  &:last-child {
    margin-top: functions.get-rem(77px);
    margin-right: functions.get-rem(24px);
  }
}

.plan-info-widget {
  width: 100%;
  height: 150px;
  margin-top: auto;

  .buttonGuide {
    font-size: functions.get-rem(12px);
    line-height: functions.get-rem(16px);
    box-shadow: 0px 1.2808988094329834px 3.84269642829895px 0px #00000026,
      0px 0.6404494047164917px 1.2808988094329834px 0px #0000000d;
  }

  .chart {
    width: 70px;
    height: 70px;

    svg {
      filter: drop-shadow(1px 2px 3px rgb(0 0 0 / 0.2));
    }
  }

  .info {
    width: 100%;
  }

  button {
    color: variables.$foreground-high;
  }
}
