@use "scss/variables";
@use "scss/functions";

.portfolio {
  margin-top: functions.get-rem(24px);
  background: white;
  .title {
    padding-top: 18px;
    margin-left: 24px;
    margin-bottom: 18px;
  }
  .subtitle {
    color: variables.$foreground-low;
    margin-top: 4px;
  }
}

.progressBar {
  width: 96px;

  :global {
    .progress {
      height: 12px;
      border-radius: 6px;
      background: variables.$additional-1-200;
      padding: 2px
    }

    .bg-success {
      background: variables.$positive-500 !important;
      border-radius: 5px;
    }
  }
}